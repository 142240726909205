const base64_chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

const key = [
  0x03, 0x1a, 0xc8, 0x96, 0x2f, 0x9b, 0x5e, 0x0c, 0xce, 0xc8, 0xb5, 0xef, 0xb4, 0x21, 0xbe, 0x7c,
  0xb0, 0xc2, 0xb0, 0xc9, 0xa5, 0x5c, 0xcc, 0x33, 0x79, 0x52, 0xe7, 0x83, 0x3c, 0xd2, 0x79, 0xd3,
  0x49, 0xc0, 0xf9, 0x70, 0xe9, 0xf6, 0x34, 0x42, 0x2b, 0x13, 0xfb, 0xd0, 0x26, 0x34, 0xf2, 0xd9,
  0x86, 0xb5, 0x72, 0xc5, 0xa3, 0x74, 0x5d, 0xc5, 0xb2, 0xaa, 0xad, 0x18, 0x2f, 0x3a, 0xba, 0x5e,
];

const keylen = key.length;

function xorEncode(data) {
  const count = data.length;
  let ret = Array.from(data);

  for (let i = 4; i < count; i++) {
    ret[i] = data[i] ^ key[i % keylen] ^ data[i % 4];
  }

  return ret;
}

function base64Encode(bytes_to_encode) {
  let ret = '';
  let i = 0;
  let j = 0;
  const in_len = bytes_to_encode.length;
  const char_array_3 = new Array(3);
  const char_array_4 = new Array(4);

  while (bytes_to_encode.length) {
    char_array_3[i++] = bytes_to_encode.shift();

    if (i === 3) {
      char_array_4[0] = (char_array_3[0] & 0xfc) >> 2;
      char_array_4[1] = ((char_array_3[0] & 0x03) << 4) + ((char_array_3[1] & 0xf0) >> 4);
      char_array_4[2] = ((char_array_3[1] & 0x0f) << 2) + ((char_array_3[2] & 0xc0) >> 6);
      char_array_4[3] = char_array_3[2] & 0x3f;

      ret += base64_chars[char_array_4[0]];
      ret += base64_chars[char_array_4[1]];
      ret += base64_chars[char_array_4[2]];
      ret += base64_chars[char_array_4[3]];

      i = 0;
    }
  }

  if (i) {
    for (j = i; j < 3; j++) char_array_3[j] = 0;

    char_array_4[0] = (char_array_3[0] & 0xfc) >> 2;
    char_array_4[1] = ((char_array_3[0] & 0x03) << 4) + ((char_array_3[1] & 0xf0) >> 4);
    char_array_4[2] = ((char_array_3[1] & 0x0f) << 2) + ((char_array_3[2] & 0xc0) >> 6);

    ret += base64_chars[char_array_4[0]];
    ret += base64_chars[char_array_4[1]];

    if (i === 1) {
      ret += '==';
    } else {
      ret += base64_chars[char_array_4[2]];
      ret += '=';
    }
  }

  return ret;
}

export function generateRAuth(mac, sn, r_time) {
  let random = parseInt(r_time, 10);

  random = (random << 21) ^ (random << 19) ^ random;
  random *= 251;
  random += 19;
  let b0 = (random >> 24) & 0xff;
  let b1 = (random >> 16) & 0xff;
  let b2 = (random >> 8) & 0xff;
  let b3 = random & 0xff;

  let tmp = b0;
  b0 = b0 ^ b1;
  b1 = b1 ^ b2;
  b2 = b2 ^ b3;
  b3 = b3 ^ tmp;

  let full_data =
    String.fromCharCode(b0) +
    String.fromCharCode(b1) +
    String.fromCharCode(b2) +
    String.fromCharCode(b3) +
    mac +
    '|' +
    sn +
    '|' +
    r_time;

  let xorData = xorEncode(full_data.split('').map((c) => c.charCodeAt(0)));

  let ret = base64Encode(xorData);

  return ret;
}
