import { Colors, Utils } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';

export default class TransferButton extends Lightning.Component {
  static _template() {
    return {
      Button: {
        collision: true,
        w: 200,
        h: 200,
        // flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
        Image: {
          x: 65,
          texture: Lightning.Tools.getSvgTexture(Utils.asset('/images/transfer.png'), 110, 110),
        },
        Title: {
          y: 120,
          text: { text: 'Subscription Transfer', fontSize: 25 },
        },
      },
      Border: {
        w: 110,
        h: 110,
        x: 65,
        // texture: Lightning.Tools.getRoundRect(110, 110, 55, 3, Colors('#c70000').get(), false),
      },
    };
  }

  _focus() {
    this.tag('Border').patch({
      texture: Lightning.Tools.getRoundRect(110, 110, 55, 3, Colors('#c70000').get(), false),
    });
  }

  _unfocus() {
    this.tag('Border').patch({
      texture: null,
    });
  }

  _handleHover() {
    this._focus();
    this.fireAncestors('$setFocus', 'TransferButton');
  }

  _handleUnhover() {
    this._unfocus();
  }
}
