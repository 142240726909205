import lng from '@lightningjs/core';
import { Colors, Router, Utils } from '@lightningjs/sdk';
import { Row } from '@lightningjs/ui-components';

// Components
import { deleteMyList, setMyList } from '../../api/myList';

export default class SingleMovie extends lng.Component {
  static _template() {
    return {
      Wrapper: {
        w: 1920,
        h: 1080,
        rect: true,
        BackgroundColor: {
          w: 1920,
          h: 1080,
          rect: true,
          Overlay: {
            h: 1080,
            w: 1920,
            rect: true,
            color: Colors('#000000').alpha(0.8).get(),
          },
        },
        ImagePoster: {
          x: 100,
          y: 170,
          w: 400,
          h: 550,
          texture: {
            type: lng.textures.ImageTexture,
            resizeMode: { type: 'cover', w: this.w, h: this.h },
          },
          shader: {
            type: lng.shaders.RoundedRectangle,
            radius: 10,
          },
        },
        Title: {
          x: 550,
          y: 160,
          text: { fontSize: 65, letterSpacing: 2, fontStyle: 'normal bold' },
        },
        Genre: { x: 550, y: 250, text: { fontSize: 30, fontStyle: 'normal bold' } },
        LanguageWrapper: {
          x: 550,
          y: 280,
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          MovieLanguage: { text: { fontSize: 30, fontStyle: 'normal bold' } },
          Vote: {
            flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
            VoteImage: {
              x: 20,
              y: 30,
              w: 80,
              texture: lng.Tools.getSvgTexture(Utils.asset('/icons/vod_imdb_logo.SVG'), 100, 100),
            },
            VoteRating: {
              x: 20,
              w: 100,
              text: { fontSize: 30, fontStyle: 'normal bold' },
            },
          },
        },
        Description: {
          x: 550,
          y: 370,
          w: 877,
          text: { fontSize: 24, letterSpacing: 1, lineHeight: 30, maxLines: 9 },
        },
        Buttons: {
          type: Row,
          x: 550,
          y: 640,
          collision: true,
          style: {
            itemSpacing: 10,
          },
        },
      },
      MyListFlag: {
        y: 30,
        w: 350,
        h: 80,
        x: 1680,
        visible: false,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        Image: {
          texture: lng.Tools.getSvgTexture(Utils.asset('/images/heart.svg'), 70, 60),
        },
        rotation: Math.PI / 4,
        rect: true,
        color: Colors('red').get(),
      },
    };
  }

  set genres(val) {
    this._genres = val;
  }

  set language(val) {
    this._language = val;
  }
  set myListStatus(val) {
    this._myListStatus = val;
  }

  set movieDetail(val) {
    this._movieDetail = val;
  }

  get _Buttons() {
    return this.tag('Buttons');
  }

  get Description() {
    return this.tag('Description');
  }

  get Title() {
    return this.tag('Title');
  }

  get _Genre() {
    return this.tag('Genre');
  }

  get _MyListFlag() {
    return this.tag('MyListFlag');
  }

  $unselectCurrentIndex() {
    let currentIndex = this._Buttons.selectedIndex;
    this._Buttons.items[currentIndex]._unfocus();
  }

  _getFocused() {
    return this._Buttons;
  }

  $playVOD(flag) {
    Router.navigate('player', {
      data: {
        contentTypeID: 2,
        ...this._movieDetail,
        genres: [...this._genres],
        languages: this._language,
      },
      flag,
    });
  }

  $addToMyList() {
    setMyList(this._movieDetail.content_id);
    this._MyListFlag.visible = true;
    this._myListStatus = true;
  }

  $removeFromMyList() {
    deleteMyList(this._movieDetail.content_id);
    this._MyListFlag.visible = false;
    this._myListStatus = false;
  }

  _checkIfLeftToRight(text) {
    var arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(text);
  }

  _enable() {
    this.tag('BackgroundColor').patch({
      texture: {
        type: lng.textures.ImageTexture,
        src: this._movieDetail.backdrop,
        resizeMode: { type: 'cover', w: 1920, h: 1080 },
      },
    });
    this.Title.patch({
      text: { text: this._movieDetail.title },
    });
    const allGenres = this._genres?.join(' • ');
    this._Genre.text = allGenres;
    let text = this._movieDetail.description;
    const isLeftToRight = this._checkIfLeftToRight(text);

    if (isLeftToRight) {
      this.Description.text = { text: text, textAlign: 'right' };
    } else {
      this.Description.text = { text: text, textAlign: 'left' };
    }
    this.Description.patch({
      text: { text: this._movieDetail.description },
    });
    this.tag('ImagePoster').patch({
      src: this._movieDetail.image_url,
    });
    this.tag('VoteRating').patch({
      text: { text: `${this._movieDetail.imdb_score}` },
    });
    this.tag('MovieLanguage').patch({
      text: { text: `${this._language} ${this._movieDetail.year}` },
    });
    if (this._myListStatus) {
      this._MyListFlag.visible = true;
    } else {
      this._MyListFlag.visible = false;
    }
    //! A piece of code that can be used when creating options inside the player
    // this.tag('SourceModal').patch({
    //   content_id: this._movieDetail.content_id,
    //   stream_id: this._movieDetail.stream_id,
    //   content_type: 2,
    //   visible: false,
    // });
    // this._findLanguage(this._movieDetail.language_id);
  }
}
