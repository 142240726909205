import { Router, Colors, Utils, VideoPlayer } from '@lightningjs/sdk';
import { withAnnouncer } from '@lightningjs/ui-components';
import routes from './routes';
import Sidebar from './components/widgets/Sidebar/Sidebar';
import SplashScreenComponent from './components/SplashScreen/SplashScreenComponent';
import Dialog from './components/widgets/Dialog/Dialog';
import Lightning from '@lightningjs/sdk/src/Lightning';
import { getDevice } from './utils/device';
let wasOffline = false; // Variable to track previous internet status
let isDone = false;
export default class App extends withAnnouncer(Router.App) {
  // static language() {
  //   return 'en';
  // }
  static _template() {
    return {
      Pages: {
        collision: true,
        w: 1920,
        h: 1080,
      },
      Loading: {
        type: SplashScreenComponent,
        rect: true,
        w: 1920,
        h: 1080,
        zIndex: 102,
        color: Colors('#000000').get(),
      },
      Background: {
        rect: true,
        w: 1920,
        h: 1080,
        zIndex: -900,
        color: Colors('#000000').get(),
        shader: {},
      },
      Widgets: {
        Sidebar: {
          type: Sidebar,
        },
        Dialog: {
          visible: false,
          type: Dialog,
          zIndex: 110,
        },
      },
    };
  }
  _init() {
    const device = getDevice();
    if (device == 'tizen') {
      let keys = [
        'MediaPlay',
        'MediaPause',
        'MediaStop',
        'ColorF0Red',
        'ColorF1Green',
        'ColorF2Yellow',
        'ColorF3Blue',
        'ChannelUp',
        'ChannelDown',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'MediaRewind',
        'MediaFastForward',
        'MediaRecord',
        'MediaPlayPause',
        'E-Manual',
      ];
      if (device == 'tizen') {
        window.tizen.tvinputdevice.registerKeyBatch(keys);
      }
    }
  }
  _setup() {
    Router.startRouter(routes, this);
    // Check network status initially
    this.checkNetworkStatus();
    // Check network status periodically (e.g., every 5 seconds)
    this.checkInterval = setInterval(() => {
      this.checkNetworkStatus();
      window.addEventListener('offline', () => console.log('No Internet'));
      window.addEventListener('onLine', () => console.log('Connected Internet'));
    }, 5000); // Adjust the interval as needed
  }
  $punchHoleLiveChannelsPlayer(x, y, w, h) {
    this.tag('Background').shader = {
      type: Lightning.shaders.Hole,
      x,
      y,
      w,
      h,
    };
  }
  $punchHole() {
    this.tag('Background').shader = {
      color: Colors('#1F2227').get(),
      type: Lightning.shaders.Hole,
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
    };
  }
  $unpunchHole() {
    this.tag('Background').shader = {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    };
  }
  $exitDialog() {
    this._handleAppClose();
  }
  $updateSidebar() {
    this.tag('Widgets.Sidebar').tag('Menu.Items')._modifieSidebar();
  }
  $showWidget(visible) {
    this.Widgets.patch({ visible });
  }
  _handleAppClose() {
    const dialog = this.tag('Dialog');
    dialog.open({
      message: 'Are you sure you want to close the app?',
      actions: [
        {
          label: 'No, resume',
          action: () => {
            dialog.close();
          },
        },
        {
          label: 'Yes, exit now',
          action: () => {
            const device = getDevice();
            // Router navigation for "Yes, exit now"
            if (device === 'tizen') {
              window.tizen.application.getCurrentApplication().exit();
            } else if (device === 'web') {
              window.location.href = '';
            } else if (device === 'webos') {
              window.close();
            } else if (device === 'hisense') {
              window.close();
            }
          },
        },
      ],
    });
  }
  _captureKey(event) {
    const loadingComponent = this.tag('Loading');
    const isLoadingVisible = loadingComponent.visible;
    if (
      (event.keyCode === 8 ||
        event.keyCode === 10009 ||
        event.keyCode === 461 ||
        event.keyCode === 13) &&
      isLoadingVisible
    ) {
      isDone = true;
      return true;
    } else {
      if (isDone) {
        setTimeout(() => {
          isDone = false;
        }, 1200);
      } else {
        return false;
      }
    }
  }
  checkNetworkStatus() {
    const networkDialog = this.tag('Dialog');
    if (navigator.onLine) {
      if (wasOffline) {
        VideoPlayer.play();
        networkDialog.close();
      }
      wasOffline = false;
    } else {
      VideoPlayer.pause();
      networkDialog.open({
        message: 'Problem with Network?',
        actions: [
          {
            label: 'Retry',
            action: () => {
              if (navigator.onLine) {
                networkDialog.close();
                wasOffline = true;
                clearInterval(this.checkInterval);
              } else {
                return true;
              }
            },
          },
          {
            label: 'Exit App',
            action: () => {
              const device = getDevice();
              if (device === 'tizen') {
                window.tizen.application.getCurrentApplication().exit();
              } else if (device === 'web') {
                window.location.href = '';
              } else if (device === 'webos') {
                window.close();
              } else if (device === 'hisense') {
                window.close();
              }
            },
          },
        ],
      });
      wasOffline = true;
    }
  }
  get _Background() {
    return this.tag('Background');
  }
  get Widgets() {
    return this.tag('Widgets');
  }
}
