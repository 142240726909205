import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';

export default class LanguageSection extends lng.Component {
  static _template() {
    return {
      collision: true,
      w: 350,
      h: 1040,
      clipping: true,
      texture: lng.Tools.getRoundRect(
        350,
        1040,
        10,
        3,
        Colors('#22252a').get(),
        true,
        Colors('#282b30').get()
      ),
      SectionName: {
        y: 100,
        w: 300,
        mountX: 0.32,
        mountY: 0.8,
        visible: false,
        text: {
          fontSize: 30,
          text: 'LANGUAGES',
          alignSelf: 'end',
        },
        rotation: -Math.PI / 2,
      },

      ColumnWrapper: {
        w: 350,
        h: 1010,
        visible: false,
        clipping: true,
        flex: {
          paddingTop: 20,
        },
        Column: {
          collision: true,
          type: Column,
          scrollIndex: 3,
          // wrapSelected: true,
        },
      },
    };
  }

  $setFocus(index) {
    this._Column.scrollTo(index, 10);
  }

  _initLanguages(languages) {
    this._Column.items = languages;
    this._Column.items[0]._selectButton();
  }

  get _SectionName() {
    return this.tag('SectionName');
  }

  get _ColumnWrapper() {
    return this.tag('ColumnWrapper');
  }

  get _Column() {
    return this.tag('Column');
  }

  _getFocused() {
    return this._Column;
  }

  _focus() {
    this.patch({
      w: 350,
      texture: lng.Tools.getRoundRect(
        350,
        1040,
        10,
        3,
        Colors('#22252a').get(),
        true,
        Colors('#282b30').get()
      ),
    });

    this._SectionName.patch({
      visible: false,
      text: { text: '', alignSelf: 'center' },
    });

    this._ColumnWrapper.visible = true;
  }

  _unfocus(newFocusedComponent) {
    if (newFocusedComponent.constructor.name !== 'SidebarItem') {
      this.patch({
        w: 100,
        texture: lng.Tools.getRoundRect(
          100,
          1040,
          10,
          3,
          Colors('#22252a').get(),
          true,
          Colors('#282b30').get()
        ),
      });

      this._SectionName.patch({
        visible: true,
        text: { text: 'LANGUAGES', alignSelf: 'center' },
        rotation: (-90 * Math.PI) / 180,
      });

      this._ColumnWrapper.visible = false;
    }
  }

  _handleHover() {
    this.fireAncestors('$setState', 'LanguageSection');
  }
}
