import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';
import Button from './Button';

export default class Quality extends lng.Component {
  static _template() {
    return {
      Modal: {
        collision: true,
        w: 1920,
        h: 1080,
        rect: true,
        zIndex: 900,
        color: Colors('#000000').alpha(0.8).get(),
        flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
        Column: {
          Title: {
            text: { text: 'Change Stream', fontSize: 50, fontStyle: 'normal bold' },
          },
          w: 500,
          h: 550,
          flex: { direction: 'column', justifyContent: 'flex-start', alignItems: 'center' },
          type: Column,
          collision: true,
        },
      },
    };
  }

  set data(val) {
    this._data = val;
  }

  $setFocus(index) {
    this.tag('Column').selectedIndex = index;
  }

  _showModal() {
    this.tag('Column').patch({
      items: this._data.streams.live.map((option, index) => ({
        type: Button,
        w: 300,
        h: 60,
        option,
        collision: true,
        index: index,
      })),
    });
    const streamTitle = this.fireAncestors('$getQualityTitle');
    const index = this._data.streams.live.findIndex(
      (stream) => stream.stream_title === streamTitle
    );

    // index sadrži indeks traženog elementa ili -1 ako nije pronađen
    if (index !== -1) {
      console.log('Indeks pronađenog elementa:', index);
      // Ovdje možete koristiti index kako želite
    } else {
      console.log('Element nije pronađen.');
    }
    // this.tag('Column').items[0]._unfocus();
    this.tag('Column').selectedIndex = index;
    this.tag('Column').items[index]._selectButton();
  }

  _getFocused() {
    return this.tag('Column');
  }

  _handleHover() {
    return false;
  }
}
