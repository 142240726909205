import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';
import Button from './Button';
const listOfOptions = [
  {
    id: 1,
    title: 'KU',
  },
  {
    id: 2,
    title: 'SO',
  },

  {
    id: 3,
    title: 'SD',
  },

  {
    id: 4,
    title: 'HD',
  },

  {
    id: 5,
    title: 'FHD',
  },
  {
    id: 6,
    title: '4K',
  },
];
export default class QualityModal extends lng.Component {
  static _template() {
    return {
      Modal: {
        collision: true,
        x: -170,
        y: -200,
        w: 1920,
        h: 1080,
        rect: true,
        zIndex: 200,
        color: Colors('#000000').alpha(0.8).get(),
        flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
        Column: {
          Title: {
            text: { text: 'Change Stream', fontSize: 50, fontStyle: 'normal bold' },
          },
          w: 500,
          h: 550,
          flex: { direction: 'column', justifyContent: 'space-between', alignItems: 'center' },
          type: Column,
          collision: true,
          items: listOfOptions.map((option, index) => ({
            type: Button,
            collision: true,
            zIndex: 200,
            w: 300,
            h: 60,
            option,
            index,
          })),
        },
      },
    };
  }

  _handleHover() {
    return false;
  }

  $setFocus(index) {
    this.tag('Column').selectedIndex = index;
  }

  _getFocused() {
    return this.tag('Column');
  }
}
