import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';

export const defaultChannelColumnOptions = {
  type: Column,
  collision: true,
  // wrapSelected: true,
  lazyUpCount: 24,
  lazyUpCountBuffer: 50,
  // scrollIndex: 3,
};

export default class ChannelListSection extends lng.Component {
  constructor(props) {
    super(props);
    this._channelList;
  }
  static _template() {
    return {
      w: 500,
      h: 1040,
      texture: lng.Tools.getRoundRect(
        500,
        1040,
        10,
        3,
        Colors('#22252a').get(),
        true,
        Colors('#282b30').get()
      ),
      clipping: true,
      collision: true,
      ColumnWrapper: {
        w: 500,
        h: 1010,
        clipping: true,
        flex: {
          paddingTop: 20,
        },
        Column: {
          ...defaultChannelColumnOptions,
        },
      },
    };
  }

  _initChannels(channelList) {
    if (this._Column.items.length > 0) {
      this._ColumnWrapper.patch({
        Column: undefined,
      });
    }
    this._ColumnWrapper.patch({
      Column: {
        ...defaultChannelColumnOptions,
        items: channelList,
      },
    });
    this._selectChannelItem(0);
    this.fireAncestors('$channelListUpdate', channelList);
    this._Column.items[0]._focus();
  }

  _initChannelss(channelList, index) {
    if (this._Column.items.length > 0) {
      this._ColumnWrapper.patch({
        Column: undefined,
      });
    }
    this._ColumnWrapper.patch({
      Column: {
        ...defaultChannelColumnOptions,
        items: channelList,
      },
    });
    this._selectChannelItem(index);
    this._Column.items[0]._focus();

    this.fireAncestors('$playNewChannel', { index: index, flag: false });
    this.fireAncestors('$channelListUpdate', channelList);
  }

  _selectChannelItem(index) {
    this._Column.items[index]?._selectButton();
  }

  // Previous code:
  // _unSelectChannelItem(index) {
  //   this._Column.items[index]._unselectButton();
  // }

  $unselectFocusChannel(index) {
    this._Column.items[index]?._unfocus();
  }

  $setFocus(index) {
    let currentIndex = this._Column.selectedIndex;
    this._Column.items[currentIndex]._unfocus();
  }

  _handleScroll(event) {
    if (event.deltaY > 0) {
      let currentIndex = this._Column.selectedIndex;
      this._Column.scrollTo(currentIndex + 1, 50);
    } else if (event.deltaY < 0) {
      let currentIndex = this._Column.selectedIndex;
      this._Column.scrollTo(currentIndex - 1, 50);
    }
  }

  _unSelectChannelItem(index) {
    const item = this._Column.items[index];
    if (item && typeof item._unselectButton === 'function') {
      item._unselectButton();
    } else {
      console.error(
        'Unable to access _unselectButton() for the given element or element does not exist.'
      );
    }
  }

  get _ColumnWrapper() {
    return this.tag('ColumnWrapper');
  }

  get _Column() {
    return this.tag('Column');
  }

  get channelList() {
    return this._channelList;
  }

  set channels(val) {
    this._channels = val;
  }

  _firstEnable() {
    this._channelList = this._channels;
  }

  _getFocused() {
    return this._Column;
  }

  _handleHover() {
    this.fireAncestors('$setSectionState', 'MenuSectionState');
    this.fireAncestors('$setState', 'ChannelListSection');
  }
}
