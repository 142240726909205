import { Column } from '@lightningjs/ui-components';
import ContentSettingsButton from './ContentSettingsButton';
import { deleteMyList, getMyList } from '../../../../api/myList/index';
import { getMovies } from '../../../../api/movies/index';
import { getSeries } from '../../../../api/series/index';
import { Colors } from '@lightningjs/sdk';
import lng from '@lightningjs/core';

const ListOfContentSettings = [
  {
    id: 1,
    title: 'Clear Movies Watch History',
  },
  {
    id: 2,
    title: 'Clear Series Watch History',
  },
  {
    id: 3,
    title: 'Reset Movies Favourites',
  },

  {
    id: 4,
    title: 'Reset Series Favourites',
  },
  {
    id: 5,
    title: 'Reset Channels Favourites',
  },
  {
    id: 6,
    title: 'Reset All Content Settings',
  },
];
export default class ContentSettings extends lng.Component {
  static _template() {
    return {
      Wrapper: {
        w: 1000,
        h: 700,
        Title: {
          text: { text: 'Content Settings' },
        },
        Buttons: {
          type: Column,
          y: 110,
          collision: true,
          items: ListOfContentSettings.map((list, index) => ({
            type: ContentSettingsButton,
            index: index,
            buttonInfo: list,
            w: 900,
            h: 70,
          })),
        },
      },
      MessageBox: {
        x: -170,
        w: 1920,
        h: 90,
        y: 750,
        flex: { justifyContent: 'center', alignItems: 'center' },
        TitleWrapper: {
          visible: false,
          w: 500,
          h: 90,
          rect: true,
          flex: { justifyContent: 'center', alignItems: 'center' },
          color: Colors('white').get(),
          Title: {
            y: 5,
            color: Colors('black').get(),
          },
          shader: {
            type: lng.shaders.RoundedRectangle,
            radius: 45,
          },
        },
      },
    };
  }

  get _Buttons() {
    return this.tag('Buttons');
  }

  $setFocus(index) {
    this._Buttons.selectedIndex = index;
  }

  $showMessage(title) {
    // Clear any existing timeout
    clearTimeout(this._messageTimeout);

    this.tag('TitleWrapper').patch({
      visible: true,
    });

    this.tag('TitleWrapper.Title').patch({
      text: { text: title, fontSize: 30 },
    });

    // Set a new timeout and store its ID
    this._messageTimeout = setTimeout(() => {
      this.tag('TitleWrapper').patch({
        visible: false,
      });
      this.tag('TitleWrapper.Title').patch({});
    }, 2000);
  }

  async $clearSeriesFavourites() {
    try {
      const { data } = await getMyList();

      if (data.length > 0) {
        const seriesResponse = await getSeries();
        const series = seriesResponse.data.tvseries;

        const filteredSeries = series.filter((serie) => data.includes(serie.content_id.toString()));

        await Promise.all(
          filteredSeries.map(async (serie) => {
            await deleteMyList(serie.content_id);
          })
        );
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async $clearMoviesFavourites() {
    try {
      const { data } = await getMyList();

      if (data.length > 0) {
        const moviesResponse = await getMovies();
        const movies = moviesResponse.data.vod;

        const filteredMovies = movies.filter((movie) => data.includes(movie.content_id.toString()));

        await Promise.all(
          filteredMovies.map(async (movie) => {
            await deleteMyList(movie.content_id);
          })
        );
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  $clearWatchHistory(category) {
    localStorage.removeItem(category);
  }

  _getFocused() {
    return this._Buttons;
  }
}
