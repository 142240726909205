import lng from '@lightningjs/core';
import { Router, Utils, VideoPlayer } from '@lightningjs/sdk';
import { Row } from '@lightningjs/ui-components';
import { getAccess } from '../../api/activation/index';

// let video = 'http://vod.eu1.cdnrdn.com/dev/zeentv/zeentv.mp4/playlist.m3u8';

export default class Home extends lng.Component {
  constructor(props) {
    super(props);
    this._video = Utils.asset('/video/zeentv_bootvideo3_1080.mp4');
  }
  static _template() {
    return {
      w: 1920,
      h: 1080,
      flex: { direction: 'row', justifyContent: 'center' },
      Column: {
        flex: { direction: 'row', justifyContent: 'center' },
        y: 850,
        type: Row,
      },
    };
  }

  _firstActive() {
    VideoPlayer.clear();
    VideoPlayer.consumer(this);
  }

  async _active() {
    this.fireAncestors('$punchHole');
    VideoPlayer.position(0, 0);
    VideoPlayer.size(1920, 1080);
    VideoPlayer.loader();
    VideoPlayer.unloader();
    const playerSettings = {
      debug: true,
    };
    VideoPlayer.open(this._video, playerSettings);
    VideoPlayer.play();

    this.intervalId = setInterval(() => {
      const currentTime = VideoPlayer.currentTime;
      const duration = VideoPlayer.duration;
      if (currentTime >= duration) {
        VideoPlayer.seek(0);
        VideoPlayer.play();
      }
    }, 1000);
  }

  _getFocused() {
    return this.tag('Column');
  }

  // _handleBack() {
  //   // const history = Router.getHistory();
  //   // const hasRegisterHash = history.some((item) => item.hash === 'register');
  //   // if (hasRegisterHash) {
  //   //   this.fireAncestors('$exitDialog');
  //   // } else Router.back();
  //   return true;
  // }

  _handleLeft() {
    Router.focusWidget('sidebar');
  }

  get _Column() {
    return this.tag('Column');
  }

  _deactive() {
    clearInterval(this.intervalId);
  }

  async $videoPlayerEnded() {
    const access = await getAccess();
    if (access) {
      localStorage.setItem('user', JSON.stringify(access.data));
      clearInterval(this.intervalId);
      VideoPlayer.clear();
      Router.navigate('liveTv');
    } else {
      clearInterval(this.intervalId);
      VideoPlayer.clear();
      console.log('Nije registrovan');
      Router.navigate('register');
    }
    return;
  }
}
