import { Colors, Router, VideoPlayer } from '@lightningjs/sdk';
import { Column, Row } from '@lightningjs/ui-components';
import lng from '@lightningjs/core';
import { formats } from '../../utils/keyboardFormat';

// Components
import KidCard from '../Kids/components/KidCard';
import Button from './components/Button';
import VODRow from '../Movies/components/VODRow';
import InputField from './components/Input';
import KeyboardSearch from '../../components/Keyboard/components/keyboardSearch';

class Search extends lng.Component {
  static _template() {
    return {
      x: 150,
      w: 1800,
      h: 1080,
      rect: true,
      color: Colors('#000000').get(),
      InputWrapper: {
        y: 50,
        w: 1920,
        x: -50,
        flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
        Input: {
          type: InputField,
        },
      },

      LanguagesSection: {
        y: 170,
        x: 30,
        h: 120,
        type: Row,
        collision: true,
        style: {
          itemSpacing: 30,
        },
      },

      CategoriesSection: {
        y: 300,
        x: 30,
        h: 120,
        type: Row,
        collision: true,
        style: {
          itemSpacing: 30,
        },
      },
      Wrapper: {
        w: 1920,
        h: 1080,
        flex: { direction: 'row', justifyContent: 'center' },
        x: -120,
        // Keyboard: {
        //   type: KeyboardSearch,
        //   formats: {
        //     lowercase: formats,
        //   },
        //   y: 580,
        //   w: 900,
        //   h: 500,
        //   color: Colors('#000000').alpha(0.98).get(),
        //   rect: true,
        //   zIndex: 99,
        //   visible: false,
        //   centerKeyboard: true,
        //   rowWrap: false,
        //   centerKeys: true,
        // },
        Keyboard: {
          type: KeyboardSearch,

          // w: 1920,
          // h: 1080,
          zIndex: 205,
          visible: false,
          limit: 50,
        },
      },
      VODWrapper: {
        w: 1770,
        y: 450,
        h: 630,
        clipping: true,
        VODSection: {
          x: 20,
          y: 20,
          type: Column,
          collision: true,
          plinko: true,
          lazyUpCount: 2,
          lazyUpCountBuffer: 2,
        },
      },
    };
  }

  set data(val) {
    this._data = val;
  }

  get _Input() {
    return this.tag('Input');
  }

  get _VODWrapper() {
    return this.tag('VODWrapper');
  }

  $getInput() {
    return this._Input.tag('Input');
  }

  $setFocusKeyboard() {
    console.log('set keyboard');
    this._setState('Keyboard');
  }

  get _LanguagesSection() {
    return this.tag('LanguagesSection');
  }

  get _CategoriesSection() {
    return this.tag('CategoriesSection');
  }

  get _VODSection() {
    return this.tag('VODSection');
  }

  get _Keyboard() {
    return this.tag('Keyboard');
  }

  _firstEnable() {
    this._setState('LanguagesSection');
  }

  _enable() {
    this.patch({ x: 120 });
    this.tag('Input').patch({
      texture: lng.Tools.getRoundRect(1300, 100, 5, 3, false),
    });
  }

  _disable() {
    VideoPlayer.loader(null);
    VideoPlayer.unloader(null);
    VideoPlayer.clear();
  }

  $setFocusBtn({ index, flag }) {
    switch (flag) {
      case 'language':
        // this._LanguagesSection.items[0].selectedIndex = index - 1;
        this._LanguagesSection.items[0].scrollTo(index, 1);
        break;
      case 'genre':
        this._CategoriesSection.items[0].scrollTo(index, 1);
        // this._CategoriesSection.items[0].selectedIndex = index;
        break;
    }
  }

  $setState(state) {
    if (state == 'language') {
      this._setState('LanguagesSection');
    } else if (state == 'genre') {
      this._setState('CategoriesSection');
    }
  }

  _handleScroll(event, target) {}

  $scroll(event) {
    let currentIndex = this._VODSection.selectedIndex;
    let nextIndex = currentIndex + 1;
    let previousIndex = currentIndex - 1;

    if (event.deltaY > 0) {
      this._VODSection.scrollTo(nextIndex, 50);
    } else if (event.deltaY < 0) {
      console.log('Rotacija prema gore');
      this._VODSection.scrollTo(previousIndex, 50);
    } else {
      console.log('Nema vertikalne rotacije');
    }
    this._handleScroll(event);
    this._unfocus();
  }

  $setFocus({ rowIndex, index }) {
    this._setState('VODSection');
    console.log(this._VODSection.selectedIndex);
    let modifiedIndex = index % 6;
    // this._Column.selectedIndex = rowIndex;
    this._VODSection.scrollTo(rowIndex, 400);
    this._VODSection.items[rowIndex].selectedIndex = modifiedIndex;
  }

  $unselectCurrentIndex() {
    let currentIndex = this._VODSection.selectedIndex;
    let rowIndex = (this._VODSection.items[currentIndex].selectedIndex = undefined);
    // this._Column.items[currentIndex].items[rowIndex]._unfocus();
  }

  $onSoftKey({ key }) {
    this._Input.tag('Input').listening = true;

    const inputTag = this._Input.tag('Input');
    if (inputTag.listening) {
      if (key === 'Space') {
        inputTag.insert(' ');
      } else if (key === 'Delete') {
        inputTag.backspace();
      } else if (key === 'Clear') {
        inputTag.clear();
      } else if (key === 'backspace') {
        inputTag.backspace();
      } else if (key === 'Done') {
        this._Keyboard.visible = false;
        this._setState('Input');
      } else {
        inputTag.insert(key);
      }
    }
    this._Input._changePlaceholder();
    this.$updatedVOD();
  }

  $clearInput() {
    const inputTag = this._Input.tag('Input');
    inputTag.clear();
    this._Input._changePlaceholder();
    this.$updatedVOD();
  }

  $updatedVOD() {
    let inputValue = this._Input.tag('Input').value.toLowerCase();
    let filteredData = this._data.filter((list) => {
      return list.title.toLowerCase().includes(inputValue);
    });

    this.$changedVODGenre({ data: filteredData });
  }

  $changedVODLanguage({ data }) {
    let listOfGenres = [];
    const items = data.genres.map((list, index) => ({
      type: Button,
      collision: true,
      w: 200,
      h: 60,
      data: list,
      flag: 'genre',
      index: index,
    }));

    listOfGenres.push({
      type: VODRow,
      x: 0,
      y: 50,
      h: 200,
      title: 'CATEGORIES',
      items: items,
      collision: true,
      scrollIndex: 4,
    });
    this.patch({
      CategoriesSection: undefined,
    });
    this.patch({
      CategoriesSection: {
        y: 300,
        x: 30,
        h: 120,
        type: Column,
        collision: true,
        style: {
          itemSpacing: 30,
        },
        items: listOfGenres,
      },
    });
    this.$changedVODGenre({ data: data.genres[0].vod });
  }

  async $changedVODGenre({ data }) {
    const items = data.map((list, index) => ({
      type: KidCard,
      w: 265,
      h: 510,
      collision: true,
      data: list,
      index: index,
    }));
    const pageItems = await Promise.all(
      Array.from({ length: Math.ceil(items.length / 6) }).map(async (_, i) => {
        const start = i * 6;
        const end = start + 6;
        const rowItems = items.slice(start, end).map((item, j) => {
          return { ...item, rowIndex: i, itemIndex: j };
        });

        return {
          type: Row,
          h: 510,
          collision: true,
          items: rowItems,
          style: {
            itemSpacing: 30,
          },
        };
      })
    );
    this.tag('VODWrapper').patch({
      VODSection: undefined,
    });
    this.tag('VODWrapper').patch({
      VODSection: {
        type: Column,
        x: 20,
        y: 20,
        plinko: true,
        collision: true,
        lazyUpCount: 2,
        lazyUpCountBuffer: 2,
        items: pageItems,
      },
    });
  }

  _handleLeft() {
    Router.focusWidget('sidebar');
  }

  $setInputState() {
    this._setState('Input');
  }

  static _states() {
    return [
      class Input extends this {
        _getFocused() {
          return this._Input;
        }

        _handleDown() {
          this._setState('LanguagesSection');
          this._Input.tag('Input').listening = false;
        }

        _handleClick() {
          this._handleEnter();
        }

        _handleEnter() {
          this._Keyboard.visible = true;
          this._setState('Keyboard');
          this._Input.tag('Input').listening = true;
        }
      },
      class LanguagesSection extends this {
        _getFocused() {
          return this._LanguagesSection;
        }

        _handleDown() {
          this._setState('CategoriesSection');
        }

        _handleUp() {
          this._setState('Input');
          this._Input.tag('Input').listening = true;
        }
      },

      class CategoriesSection extends this {
        _getFocused() {
          return this._CategoriesSection;
        }
        _handleDown() {
          this._setState('VODSection');
        }

        _handleUp() {
          this._setState('LanguagesSection');
        }
      },

      class VODSection extends this {
        _handleUp() {
          this._setState('CategoriesSection');
        }
        _getFocused() {
          return this.tag('VODSection');
        }
      },
      class Keyboard extends this {
        _getFocused() {
          return this._Keyboard;
        }

        _handleBack() {
          this._Keyboard.visible = false;
          this._setState('Input');
        }

        _handleLeft() {
          return true;
        }
      },
    ];
  }
}

export default Search;
