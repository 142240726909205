import request from '../index';

export const getMyList = () => {
  const profile = JSON.parse(localStorage.getItem('user'));
  return request({
    url: `/settings/favourite/get/${profile.subscription.id}/${profile.profiles[0].id}/2`,
  });
};

export const setMyList = (contentId) => {
  const profile = JSON.parse(localStorage.getItem('user'));
  return request({
    url: `/settings/favourite/set/${contentId}/${profile.subscription.id}/${profile.profiles[0].id}/2`,
  });
};

export const deleteMyList = (contentId) => {
  const profile = JSON.parse(localStorage.getItem('user'));
  return request({
    url: `/settings/favourite/del/${contentId}/${profile.subscription.id}/${profile.profiles[0].id}/2`,
  });
};

export const getFavourite = (subscription, userProfile) => {
  const profile = JSON.parse(localStorage.getItem('user'));
  return request({
    url: `/settings/favourite/get/${subscription}/${userProfile}/2`,
  });
};
