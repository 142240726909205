import Lightning from '@lightningjs/sdk/src/Lightning';
import { buttons } from './TVChannelButtonsList';
import TVChannelInfoButton from './TVChannelInfoButton';

export default class TVChannelInfoButtons extends Lightning.Component {
  constructor(props) {
    super(props);
    this._buttons = buttons;
  }
  _construct() {
    this._selectedButton = 0;
  }
  static _template() {
    return {
      collision: true,
      flex: { direction: 'row', padding: 5, alignItems: 'center' },
      flexItem: { marginLeft: 10, alignSelf: 'stretch', grow: 1, maxWidth: 250 },
      w: 1920,
      h: 90,
      x: 0,
      y: 180,
    };
  }

  _getFocused() {
    return this.children[this._selectedButton];
  }

  _enable() {
    this._buttons = buttons.map((button, index) => ({ ...button, index }));
    this.patch({
      children: this._buttons,
    });
  }

  _setStreamTitle(title) {
    buttons[3].label = title;
    // this._buttons = buttons.map((button, index) => ({ ...button, index }));
    this.patch({
      children: this._buttons,
    });
  }

  _hideInfo(isEPG) {
    const isLocked = this.fireAncestors('$findCurrentChannel');
    this._buttons = [...buttons];

    if (isEPG == undefined || isEPG.length === 0) {
      this._buttons.splice(2, 1);
    }

    const noviElement = isLocked
      ? {
          type: TVChannelInfoButton,
          name: 'locked',
          label: 'Locked',
          icon: 'locked.SVG',
          iconActive: 'locked.SVG',
          flexItem: { marginLeft: 30 },
          getWidth: 150,
        }
      : {
          type: TVChannelInfoButton,
          name: 'lock',
          label: 'Lock',
          icon: 'lock.SVG',
          iconActive: 'locked.SVG',
          flexItem: { marginLeft: 30 },
          getWidth: 120,
        };

    this._buttons.push(noviElement);
    this._buttons = this._buttons.map((button, index) => ({ ...button, index }));
    this.patch({
      children: this._buttons,
    });
  }

  _changeFavouriteIcon(flag) {
    this.children[1]._changeIcon(flag);
  }

  _addLikeOrNot(isFavourite) {
    this._buttons = [...buttons];

    if (isFavourite) {
      this._buttons[1].name = 'liked';
      this._buttons[1].label = 'Favourite';
      this._buttons[1].icon = 'favorite.SVG';
    } else {
      this._buttons[1].name = 'favourite';
      this._buttons[1].label = 'Favourite';
      this._buttons[1].icon = 'redflix_infobar_favorite.svg';
    }

    this._buttons = this._buttons.map((button, index) => ({ ...button, index }));
    this.patch({
      children: this._buttons,
    });
  }

  $setFocus(index) {
    this._selectedButton = index;
    this._setState('Buttons');
  }

  _handleLeft() {
    this._selectedButton = Math.max(0, this._selectedButton - 1);
    return false;
  }

  _handleRight() {
    this._selectedButton = Math.min(this.children.length - 1, this._selectedButton + 1);
    return false;
  }

  static _states() {
    return [
      class Button extends this {
        _getFocused() {
          this.children[this._selectedButton];
        }
      },
    ];
  }
}
