import { Colors, Utils } from '@lightningjs/sdk';
import { Input } from '@lightningjs/ui-components';
import ClearButton from './ClearButton';
import lng from '@lightningjs/core';

export default class InputField extends lng.Component {
  static _template() {
    return {
      InputWrapper: {
        collision: true,
        w: 1310,
        h: 110,
        rect: true,
        flex: { direction: 'row', alignItems: 'center' },
        color: Colors('#49484d').get(),
        Children: {
          flex: { direction: 'row' },
          IconWrapper: {
            h: 110,
            w: 80,
            flex: { direction: 'row', alignItems: 'center', justifyContent: 'center' },
            Icon: {
              texture: lng.Tools.getSvgTexture(Utils.asset('/icons/ic_menu_search.SVG'), 50, 50),
            },
          },
          Input: {
            type: Input,
            y: 5,
            w: 1100,
            h: 100,
            listening: false,
            color: Colors('#ffffff').alpha(0).get(),
            style: {
              backgroundColor: Colors('black').alpha(0).get(),
            },
          },
          ClearIconWrapper: {
            h: 110,
            w: 120,
            flex: { direction: 'row', alignItems: 'center', justifyContent: 'center' },
            ClearIcon: {
              type: ClearButton,
              buttonProps: {
                icon: '/images/cross.svg',
                width: 35,
                height: 35,
              },
            },
          },
        },
      },
      Placeholder: {
        y: 3,
        x: 110,
        h: 110,
        w: 600,
        flex: { direction: 'row', alignItems: 'center' },
        color: Colors('#49484d').alpha(0).get(),
        Text: {
          text: { text: 'Search Movie and Series', fontSize: 30 },
        },
      },
    };
  }

  get _Placeholder() {
    return this.tag('Placeholder');
  }

  get _Input() {
    return this.tag('Input');
  }

  get _ClearIcon() {
    return this.tag('ClearIconWrapper.ClearIcon');
  }

  $setClearButtonState() {
    this._setState('ClearIcon');
  }

  _changePlaceholder() {
    if (this._Input.value === '') {
      this._Placeholder.patch({
        visible: true,
      });
    } else {
      this._Placeholder.visible = false;
    }
  }

  _active() {
    this._setState('Input');
  }

  static _states() {
    return [
      class Input extends this {
        _getFocused() {
          return this;
        }

        _handleRight() {
          this._setState('ClearIcon');
        }

        _focus() {
          this.fireAncestors('$setInputState');
          this.tag('Input').listening = true;
        }

        _unfocus() {
          this.tag('Input').listening = false;
        }

        _handleHover() {
          this._focus();
        }

        _handleUnhover() {
          this._unfocus();
        }
      },

      class ClearIcon extends this {
        _getFocused() {
          return this._ClearIcon;
        }

        _handleLeft() {
          this._setState('Input');
        }
      },
    ];
  }
}
