import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

export default class Packages extends lng.Component {
  constructor(props) {
    super(props);
  }
  static _template() {
    return {
      Button: {
        rect: true,
        collision: true,
        flex: {
          direction: 'row',
          alignItems: 'center',
        },
        Package: {
          x: 30,
          w: 375,
          h: 70,
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          Info: {
            text: { fontSize: 30 },
          },
        },

        StartExpire: {
          w: 500,
          h: 70,
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          Info: {
            y: 3,
            text: { fontSize: 30 },
          },
        },

        Status: {
          w: 400,
          h: 70,
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          Info: {
            y: 3,
            text: { fontSize: 30 },
          },
        },
      },
    };
  }

  set buttonInfo(val) {
    this._buttonInfo = val;
  }

  set index(val) {
    this._index = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  get _Package() {
    return this.tag('Package.Info');
  }

  get _StartExpire() {
    return this.tag('StartExpire.Info');
  }

  get _Status() {
    return this.tag('Status.Info');
  }

  _focus() {
    this._Button.patch({
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        this._buttonInfo.radius,
        5,
        Colors('#c71617').get(),
        true,
        Colors(this._buttonInfo.color).get()
      ),
    });
  }

  _unfocus() {
    this._Button.patch({
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        this._buttonInfo.radius,
        3,
        Colors(this._buttonInfo.color).get(),
        true,
        Colors(this._buttonInfo.color).get()
      ),
    });
  }
  _handleHover() {
    this.fireAncestors('$setState', 'Package');
    // Clear old timeout, if any
    // if (this._hoverTimeoutId) {
    //   clearTimeout(this._hoverTimeoutId);
    // }

    // // Set new timeout
    // this._hoverTimeoutId = setTimeout(() => {
    //   this.fireAncestors('$setFocus', this._index);
    // }, 10);

    // this._focus();
  }

  _handleUnhover() {
    // this._unfocus();
  }

  _enable() {
    this._Button.patch({
      w: this.w,
      h: this.h,
      // color: Colors(this._buttonInfo.color).get(),
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        this._buttonInfo.radius,
        3,
        Colors(this._buttonInfo.color).get(),
        true,
        Colors(this._buttonInfo.color).get()
      ),
    });
    this._Package.patch({
      text: { text: this._buttonInfo.title },
    });
    const currentTime = new Date().getTime();
    const startTime = new Date(this._buttonInfo.startTime).getTime();
    const endTime = new Date(this._buttonInfo.endTime).getTime();

    const isActive = currentTime >= startTime && currentTime <= endTime;
    this._setStartExpire();
    this._Status.patch({
      color: isActive ? Colors('#129c3f').get() : Colors('#c71617').get(),
      text: { text: isActive ? 'ACTIVE' : 'EXPIRED' },
    });
  }

  _setStartExpire() {
    let startTime = this._buttonInfo.startTime;
    let endTime = this._buttonInfo.endTime;

    function createTime(time) {
      var stringSaVremenom = time;
      var datumIVreme = stringSaVremenom.match(/\d{4}-\d{2}-\d{2}/)[0];
      var samoDatumFormatiran = datumIVreme.replace(/-/g, '/');
      return samoDatumFormatiran.slice(0, 10);
    }

    this._StartExpire.patch({
      text: { text: `${createTime(startTime)} - ${createTime(endTime)}` },
    });
  }
}
