import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

export default class SubtitleButton extends lng.Component {
  static _template() {
    return {
      Button: {
        collision: true,
        x: 20,
        y: 10,
        ContentTitle: {
          w: 400,
          flex: { direction: 'row', alignItems: 'center', justifyContent: 'center' },
        },
        FilterBorder: {},
      },
    };
  }

  set index(val) {
    this._index = val;
  }

  set buttonInfo(val) {
    return (this._buttonInfo = val);
  }

  get _FilterBorder() {
    return this.tag('FilterBorder');
  }

  get _ContentTitle() {
    return this.tag('ContentTitle');
  }

  get _Button() {
    return this.tag('Button');
  }

  _handleEnter() {
    this.fireAncestors('$preventHideProgressBar');
    if (this._buttonInfo.title !== 'OFF') {
      this.fireAncestors('$showSubtitle', { data: this._buttonInfo });
    } else {
      this.fireAncestors('$deleteSubtitle');
    }
  }

  _enable() {
    this._ContentTitle.patch({
      x: 20,
      y: 6,
      text: { text: this._buttonInfo.title },
    });
  }

  _focus() {
    this.fireAncestors('$preventInterval');
    this._Button.patch({
      texture: lng.Tools.getRoundRect(
        400,
        60,
        10,
        3,
        Colors('#C40000').get(),
        true,
        Colors('#C40000').get()
      ),
    });
  }

  _unfocus() {
    this._Button.patch({
      texture: null,
    });
  }

  _handleHover() {
    this._focus();
    this.fireAncestors('$setFocus', this._index);
  }

  _handleClick() {
    this._handleEnter();
  }

  _handleUnhover() {
    this._unfocus();
  }
}
