import { getIndex } from '../../../api/index/index';
import { getLiveList } from '../../../api/liveList/index';
import ChannelButton from '../components/ChannelButton';

export default async (page) => {
  const { data: indexData } = await getIndex();
  const index = indexData.index;

  const { groups, genres } = index;

  const adultGenreId = genres.find((genre) => genre.title === 'Adult')?.id;
  if (!adultGenreId) {
    console.error('Nije pronađen žanr "Adults"');
    return;
  }

  const { data: liveData } = await getLiveList();
  const channels = liveData.live;

  const adultChannels = channels.filter((channel) => channel.genre_id === adultGenreId.toString());

  const items = adultChannels.map((channel, index) => ({
    type: ChannelButton,
    w: 550,
    h: 80,
    channelInfo: channel,
    index: index + 1,
  }));
  if (items.length == 0) {
    page.tag('Wrapper').patch({
      visible: false,
    });
    page._Text.patch({
      text: {
        text: 'Oops! It looks like there’s nothing here yet. Stay tuned for exciting content coming soon!',
      },
    });
  } else {
    page.tag('Wrapper').patch({
      visible: true,
    });
    page._Text.patch({
      text: {
        text: '',
      },
    });
  }
  page.fullList = adultChannels;
  page._ChannelsList.items = items;
  page._Player.firstChannel = adultChannels[0];
  page._Player.index = 1;
};
