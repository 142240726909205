// API
import { getMovies } from '../../../api/movies/index';
import { getIndex } from '../../../api/index/index';

// Components
import VODComponent from '../components/VODComponent';
import VODRow from '../components/VODRow';
import ShowMoreCard from '../components/ShowMoreCard';

const ITEMS_LIMIT = 19;

async function fetchMoviesData() {
  const [indexData, moviesResponse] = await Promise.all([getIndex(), getMovies()]);
  return {
    indexData,
    moviesData: moviesResponse.data.vod || [],
    continueToWatchMovies: JSON.parse(localStorage.getItem('movies')) || [],
  };
}

function mapMovies(movies, languages, genres) {
  return movies
    .filter((movie) => !movie.genre_id.includes('Kids'))
    .filter((movie) => {
      const language = languages?.find((lng) => lng.id === movie.language_id);
      const languageTitle = language ? language.title : 'Unknown';
      return languageTitle !== 'Other';
    })
    .map((movie) => {
      const language = languages?.find((lng) => lng.id === movie.language_id);
      const languageTitle = language ? language.title : 'Unknown';
      const movieGenresList = movie.genre_id
        .split(',')
        .map(Number)
        .map((genreId) => genres?.find((genr) => genr.id === genreId)?.title)
        .filter((title) => title);

      return { ...movie, genres: movieGenresList, languageTitle };
    })
    .reduce((acc, movie) => {
      const languageGroup = acc.find((group) => group.title === movie.languageTitle);

      if (!languageGroup) {
        acc.push({ title: movie.languageTitle, movies: [movie] });
      } else {
        languageGroup.movies.push(movie);
      }
      return acc;
    }, []);
}

function createVODRow(languageGroup, continueToWatch = false) {
  const limitedMovies = languageGroup.movies.slice(0, ITEMS_LIMIT);
  const items = limitedMovies.map((movie, index) => ({
    type: VODComponent,
    w: 255,
    h: 400,
    collision: true,
    index: index,
    data: movie,
    language: languageGroup.title,
    flag: 'movies',
  }));

  const additionalItem =
    languageGroup.movies.length > ITEMS_LIMIT
      ? {
          type: ShowMoreCard,
          w: 255,
          h: 400,
          language: languageGroup.title,
          collision: true,
          data: {
            image_url: '/images/see_more.jpg',
            title: 'Show More',
            description: '',
            genres: [],
          },
          fullData: continueToWatch
            ? { title: 'Continue to Watch', movies: languageGroup.movies }
            : languageGroup,
          flag: 'movieslist',
        }
      : null;

  return {
    type: VODRow,
    h: 550,
    x: 20,
    y: 80,
    title: continueToWatch ? 'Continue to Watch' : languageGroup.title,
    items: [...items, additionalItem].filter(Boolean),
    scrollIndex: 3,
    collision: true,
    style: {
      itemSpacing: 10,
    },
  };
}

export default async function (page) {
  try {
    const { indexData, moviesData, continueToWatchMovies } = await fetchMoviesData();

    const languages = indexData.data.index?.languages;
    const genres = indexData.data.index.genres;
    const moviesList = mapMovies(moviesData, languages, genres);

    const filteredContinueToWatch = continueToWatchMovies.filter((movie) => {
      const language = languages?.find((lang) => lang.id === movie.language_id);
      return language && language.title !== 'Other'; // Filtriramo filmove čiji jezik nije "Other"
    });

    const pageItems = moviesList.map((languageGroup) => createVODRow(languageGroup));

    if (filteredContinueToWatch.length > 0) {
      pageItems.unshift(
        createVODRow({ title: 'Continue to Watch', movies: filteredContinueToWatch }, true)
      );
    }

    page._Column.items = pageItems;
  } catch (error) {
    console.error('Error fetching movies:', error);
  }
}
