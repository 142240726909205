import Lightning from '@lightningjs/sdk/src/Lightning';
import { Column } from '@lightningjs/ui-components';
import { Colors, Router } from '@lightningjs/sdk';
import SidebarItem from './SidebarItem';

const defaultSidebarLinks = [
  {
    id: 1,
    title: 'SEARCH',
    image: '/icons/ic_menu_search.SVG',
    height: 55,
    width: 55,
    path: 'search',
  },
  {
    id: 2,
    title: 'LIVE',
    image: '/icons/ic_menu_tv.SVG',
    height: 50,
    width: 50,
    path: 'liveTv',
  },
  {
    id: 3,
    title: 'MOVIES',
    image: '/icons/ic_menu_movie.SVG',
    height: 50,
    width: 50,
    path: 'movies',
  },
  {
    id: 4,
    title: 'SERIES',
    image: '/icons/ic_series_test.SVG',
    height: 50,
    width: 50,
    path: 'series',
  },

  {
    id: 5,
    title: 'KIDS',
    image: '/icons/icon_kids.SVG',
    height: 50,
    width: 50,
    path: 'kids',
  },
  {
    id: 6,
    title: 'MY LIST',
    image: '/icons/ic_star.SVG',
    height: 55,
    width: 55,
    path: 'myList',
  },

  {
    id: 7,
    title: 'SETTINGS',
    image: '/icons/ic_menu_settings.SVG',
    height: 55,
    width: 55,
    path: 'profile',
  },
];

let sidebarLength = defaultSidebarLinks.length;
export default class SidebarItems extends Lightning.Component {
  constructor(props) {
    super(props);
    this._index = 0;
    this._sidebarLinks;
  }
  static _template() {
    return {
      collision: true,
      zIndex: 200,
      // w: 250,
      // h: 1080,
      // rect: true,
      Column: {
        type: Column,
        plinko: true,
        collision: true,
        items: defaultSidebarLinks.map((item, index) => ({
          type: SidebarItem,
          collision: true,
          w: 250,
          h: 80,
          plinko: true,
          index: index,
          color: Colors('#FFFFFF').get(),
          item,
          zIndex: 200,
        })),
      },
    };
  }
  _init() {
    this._selectedLink = this._Column;
    this._sidebarLinks = [...defaultSidebarLinks];
    this._modifieSidebar();
  }

  $setFocus(index) {
    this._Column.selectedIndex = index;
  }

  _modifieSidebar() {
    const adultContent = localStorage.getItem('adultContent');

    if (adultContent && adultContent == 'ON') {
      const newSidebarLink = {
        id: 8,
        title: 'ADULT',
        image: '/images/adult.svg',
        height: 55,
        width: 55,
        path: 'adult',
      };
      this._sidebarLinks.splice(6, 0, newSidebarLink);
      sidebarLength = this._sidebarLinks.length;
    } else {
      this._sidebarLinks = [...defaultSidebarLinks];
      sidebarLength = this._sidebarLinks.length;
    }

    this.patch({
      Column: undefined,
    });
    this.patch({
      Column: {
        type: Column,
        plinko: true,
        collision: true,
        zIndex: 200,
        items: this._sidebarLinks.map((item, index) => ({
          type: SidebarItem,
          h: 80,
          w: 250,
          plinko: true,
          index: index,
          collision: true,
          color: Colors('#FFFFFF').get(),
          item,
          zIndex: 200,
        })),
      },
    });
    this._selectedLink = this.tag('Column');
  }

  _setManualFocus() {
    switch (Router.getActiveHash()) {
      case 'search':
        this._index = 0;
        return this._index;
      case 'home':
        this._index = 1;
        return this._index;
      case 'movies':
        this._index = 2;
        return this._index;
      case 'series':
        this._index = 3;
        return this._index;
      case 'kids':
        this._index = 4;
        return this._index;
      case 'myList':
        this._index = 5;
        return this._index;
      case 'adult':
        this._index = 6;
        return this._index;
      case 'profile':
        const adultContent = localStorage.getItem('adultContent');
        adultContent && adultContent == 'ON' ? (this._index = 7) : (this._index = 6);
        return this._index;
      default:
        this._index = 1;
        return this._index;
    }
  }

  _active() {
    console.log('Active Index');
    this._Column.selectedIndex = this._setManualFocus();
  }

  $unselectCurrentIndex() {
    this._Column.items[this._index]._unfocus();
  }

  // _incrementFocus() {
  //   if (this._index < sidebarLength - 1) {
  //     return (this._index += 1);
  //   } else return this._index;
  // }
  // _decrementFocus() {
  //   if (this._index > 0) {
  //     return (this._index -= 1);
  //   } else return this._index;
  // }

  _handleHover() {
    // this._focus();
    return false;
  }

  _handleUnhover() {
    return false;
  }

  _focus() {
    this._selectedLink = this.tag('Column').items[this._setManualFocus()];
  }
  // $decrementSidebar() {
  //   this._selectedLink = this.tag('Column').items[this._decrementFocus()];
  // }
  // $incrementSidebar() {
  //   this._selectedLink = this.tag('Column').items[this._incrementFocus()];
  // }

  get _Column() {
    return this.tag('Column');
  }
  _getFocused() {
    return this._Column;
  }
}
