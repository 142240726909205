import lng from '@lightningjs/core';
import { Colors, Router, Utils } from '@lightningjs/sdk';

export default class Button extends lng.Component {
  static _template() {
    return {
      Button: {
        collision: true,
        rect: true,
        color: Colors('#3d3d3d').get(),
        flex: {
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        },
        Wrapper: {
          flex: {
            direction: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          Image: {},
          Text: {
            y: 3,
            text: {
              fontSize: 25,
              color: Colors('#fafafa').get(),
              letterSpacing: 2,
              lineHeight: 30,
              maxLines: 2,
              wordWrapWidth: 310,
              textAlign: 'center',
              verticalAlign: 'middle',
              fontStyle: 'normal bold',
            },
          },
        },
      },
    };
  }

  set buttonInfo(val) {
    this._buttonInfo = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  get _Image() {
    return this.tag('Image');
  }

  get _Text() {
    return this.tag('Text');
  }

  _handleHover() {
    this.fireAncestors('$unselectCurrentIndex');
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._handleEnter();
  }

  _enable() {
    this.tag('Wrapper').patch({
      w: this.w - 50,
      h: this.h,
    });
    this._Button.patch({
      w: this.w,
      h: this.h,
      texture: lng.Tools.getRoundRect(this.w, this.h, 10, 3, false),
    });

    this._Image.patch({
      texture: lng.Tools.getSvgTexture(Utils.asset(this._buttonInfo.image), 50, 50),
    });

    this._Text.patch({
      text: { text: this._buttonInfo.title },
    });
  }

  _AddToMyList() {
    this.fireAncestors('$addToMyList');
    this._buttonInfo.title = 'Remove';
    this._buttonInfo.image = '/icons/new/test_ok_icon_black.SVG';
    this.tag('Wrapper').patch({
      Text: undefined,
      Image: undefined,
    });
    this.tag('Wrapper').patch({
      Image: {
        texture: lng.Tools.getSvgTexture(Utils.asset(this._buttonInfo.image), 50, 50),
      },
      Text: {
        y: 3,
        text: {
          fontSize: 25,
          color: Colors('#fafafa').get(),
          letterSpacing: 2,
          lineHeight: 30,
          maxLines: 2,
          wordWrapWidth: 310,
          textAlign: 'center',
          verticalAlign: 'middle',
          text: this._buttonInfo.title,
          fontStyle: 'normal bold',
        },
      },
    });
  }

  _RemoveFromMyList() {
    this.fireAncestors('$removeFromMyList');
    this._buttonInfo.title = 'My List';
    this._buttonInfo.image = '/icons/test_add_icon.SVG';
    this.tag('Wrapper').patch({
      Image: undefined,
      Text: undefined,
    });
    this.tag('Wrapper').patch({
      Image: {
        texture: lng.Tools.getSvgTexture(Utils.asset(this._buttonInfo.image), 50, 50),
      },
      Text: {
        y: 3,
        text: {
          fontSize: 25,
          color: Colors('#fafafa').get(),
          letterSpacing: 2,
          lineHeight: 30,
          maxLines: 2,
          wordWrapWidth: 310,
          textAlign: 'center',
          verticalAlign: 'middle',
          text: this._buttonInfo.title,
          fontStyle: 'normal bold',
        },
      },
    });
  }

  _focus() {
    this._Button.patch({ color: Colors('#c71617').get() });
  }

  _unfocus() {
    this._Button.patch({ color: Colors('#3d3d3d').get() });
  }

  _handleEnter() {
    switch (this._buttonInfo.title) {
      case 'Continue':
        return this.fireAncestors('$playVOD', 'movie');
      case 'Watch':
        return this.fireAncestors('$playVOD', 'movie');
      case 'Trailer':
        return this.fireAncestors('$playVOD', 'trailer');
      case 'My List':
        return this._AddToMyList();
      case 'Remove':
        return this._RemoveFromMyList();
    }
  }
}
