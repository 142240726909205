import lng from '@lightningjs/core';
import { Colors, Utils } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';
import { Marquee } from '@lightningjs/ui-components';

export default class Channels extends lng.Component {
  set buttonInfo(val) {
    return (this._buttonInfo = val);
  }

  static _template() {
    return {
      flex: { direction: 'column', alignItems: 'center', justifyContent: 'center' },
      collision: true,
      Button: {
        clipping: true,
        flex: { direction: 'row', alignItems: 'center' },
        ChannelNumber: {
          x: 10,
          w: 90,
        },
        ChannelImage: { flexItem: { marginLeft: 0, marginRight: 20 } },
        ChannelName: {
          type: Marquee,
          w: 170,
          //   y: -20,
        },
        ChannelLock: {
          w: 40,
          h: 40,
        },
        ChannelFavourite: {
          w: 20,
          h: 20,
          x: -3,
        },
      },
    };
  }

  get _Button() {
    return this.tag('Button');
  }

  get _ChannelName() {
    return this.tag('ChannelName');
  }

  get _ChannelImage() {
    return this.tag('ChannelImage');
  }

  get _ChannelNumber() {
    return this.tag('ChannelNumber');
  }

  set isLocked(val) {
    this._isLocked = val;
  }

  set index(val) {
    this._index = val;
  }

  get isFavourite() {
    return this._isFavourite;
  }

  set isFavourite(val) {
    this._isFavourite = val;
  }

  _enable() {
    if (this._isLocked) {
      this._setLockIcon();
    }

    if (this._isFavourite) {
      this._setFavIcon();
    }

    this.patch({ w: this.w, h: this.h });

    this._Button.patch({ w: this.w - 50, h: this.h });
    this._ChannelName.patch({
      text: { text: this._buttonInfo.channelInfo.title, fontSize: 30, maxLines: 1 },
    });

    if (this._buttonInfo.channelInfo.image_url !== 'test') {
      this._ChannelImage.patch({
        texture: {
          type: lng.textures.ImageTexture,
          src: this._buttonInfo.channelInfo.image_url,
          resizeMode: { type: 'contain', w: 100, h: 80 },
        },
      });
    } else {
      this._ChannelImage.patch({
        texture: Lightning.Tools.getSvgTexture(Utils.asset(`icons/tv_default_icon.SVG`), 60, 60),
      });
    }

    this._changeNumber();
  }

  _focus() {
    this._Button.patch({
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        5,
        3,
        Colors('#C40000').get(),
        this.parentFocus,
        Colors('#C40000').get()
      ),
    });
  }

  _unfocus() {
    this.tag('ChannelName').patch({
      color: Colors('#ffff00').get(),
    });
    this._Button.patch({
      texture: null,
    });
  }

  _handleHover() {
    this.fireAncestors('$setFocus', { index: this._index, flag: 'Channels' });
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._handleEnter();
  }

  _changeNumber() {
    const paddedIndex = String(this._buttonInfo.channelInfo.channelNumber).padStart(4, '0');
    this._ChannelNumber.patch({
      text: { text: paddedIndex, fontSize: 25 },
    });
  }

  _setLockIcon() {
    this.tag('ChannelLock').patch({
      texture: lng.Tools.getSvgTexture(Utils.asset('/icons/lockedChannel.SVG'), 40, 40),
    });
  }

  _delLockIcon() {
    this.tag('ChannelLock').patch({
      texture: undefined,
    });
  }

  _setFavIcon() {
    this.tag('ChannelFavourite').patch({
      texture: lng.Tools.getSvgTexture(Utils.asset('/icons/favorite.SVG'), 30, 30),
    });
  }

  _delFavIcon() {
    this.tag('ChannelFavourite').patch({
      texture: undefined,
    });
  }

  _handleEnter() {
    // let newChannelInfo = this._buttonInfo.channelInfo;
    // this.fireAncestors('$channelFired', newChannelInfo, this._buttonInfo.index);
    this.fireAncestors('$lastChannelFired', this._buttonInfo);
    this.fireAncestors('$hideLastChannelsModal');
  }
}
