import lng from '@lightningjs/core';
import { Colors, Router, VideoPlayer } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';
import Player from './components/Player';

export default class Adult extends lng.Component {
  constructor(props) {
    super(props);
    this._channelIndex = 0;
    this._backStatus = false;
  }
  static _template() {
    return {
      collision: true,
      Wrapper: {
        x: 150,
        w: 700,
        h: 1080,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        ChannelsList: {
          type: Column,
          collision: true,
          // scrollIndex: 5,
          lazyScroll: true,
          w: 700,
          h: 1000,
          rect: true,
          clipping: true,
          color: Colors('#2d2d2d').alpha(0.7).get(),
          shader: {
            type: lng.shaders.RoundedRectangle,
            radius: 15,
          },
        },
      },
      TextWrapper: {
        w: 1750,
        h: 1080,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        Text: {
          w: 900,
          x: 150,
          y: 20,
          text: {
            wordWrap: true,
            fontSize: 45,
            maxLines: 2,
            lineHeight: 50,
            textAlign: 'center',
          },
        },
      },
      Player: {
        type: Player,
        collision: true,
      },
    };
  }
  set fullList(val) {
    this._fullList = val;
  }

  $previousChannel(index) {
    const length = this._fullList.length;
    if (index - 1 <= 0) {
      const previousChannel = this._fullList[length - 1];
      this._Player._changeChannel(previousChannel, length);
    } else {
      const previousChannel = this._fullList[index - 2];
      this._Player._changeChannel(previousChannel, index - 1);
    }
  }

  $showChannelBox(index) {
    this._ChannelsList.visible = true;
    this._ChannelsList.selectedIndex = index;
    this._setState('ChannelsList');
  }

  $nextChannel(index) {
    const length = this._fullList.length;
    if (index >= length - 1) {
      const nextChannel = this._fullList[0];
      this._Player._changeChannel(nextChannel, 1);
    } else {
      const nextChannel = this._fullList[index + 1];
      this._Player._changeChannel(nextChannel, index + 2);
    }
  }

  $newChannel({ channel, index }) {
    this._Player._updateChannel(channel, index);
  }

  $setFocus(index) {
    let currentIndex = this.tag('ChannelsList').selectedIndex;
    this.tag('ChannelsList').items[currentIndex]._unfocus();
  }

  $unselectCurrentIndex() {
    let currentIndex = this._ChannelsList.selectedIndex;
    this._ChannelsList.items[currentIndex]._unfocus();
  }

  $unselectFocusChannel(index) {
    this._ChannelsList.items[index]?._unfocus();
  }

  $scroll() {
    let currentIndex = this._ChannelsList.selectedIndex;
    let nextIndex = currentIndex + 1;
    let previousIndex = currentIndex - 1;
    // Inkrementiranje ili dekrementiranje lorem.x za 400, ovisno o smjeru skroliranja
    if (event.deltaY > 0) {
      this._ChannelsList.scrollTo(nextIndex, 50);
      // lorem.x -= 400;
    } else if (event.deltaY < 0) {
      console.log('Rotacija prema gore');
      // lorem.x += 400;
      this._ChannelsList.scrollTo(previousIndex, 50);
    } else {
      console.log('Nema vertikalne rotacije');
    }
  }

  get _ChannelsList() {
    return this.tag('ChannelsList');
  }

  get _Player() {
    return this.tag('Player');
  }

  get _Text() {
    return this.tag('Text');
  }

  get _Wrapper() {
    return this.tag('Wrapper');
  }
  $selectButton(props) {
    this._ChannelsList?.items[this._channelIndex]?._unselectButton();
    this._channelIndex = props;
    this._ChannelsList?.items[props]?._selectButton();
  }

  _active() {
    this._setState('ChannelsList');
  }

  _enable() {
    VideoPlayer.clear();
    this._ChannelsList.visible = true;
  }

  _handleLeft() {
    Router.focusWidget('sidebar');
  }

  $showChannelContainer() {
    this._Player.$showChannelContainer();
  }

  $hideChannelList() {
    if (this._ChannelsList.visible) {
      this._ChannelsList.visible = false;
      this.fireAncestors('$showWidget', false);
      this._setState('Player');
    }
  }

  $setBackStatus(status) {
    this._backStatus = status;
  }

  _handleBack() {
    if (this._ChannelsList.items.length > 0) {
      if (this._backStatus) {
        Router.back();
        // this.fireAncestors('$showWidget', true);
        return;
      }
      if (this._ChannelsList.visible) {
        this._ChannelsList.visible = false;
        this.fireAncestors('$showWidget', false);
        this._setState('Player');
      } else {
        Router.back();
        this.fireAncestors('$showWidget', true);
      }
    } else {
      Router.back();
    }
  }

  _disable() {
    VideoPlayer.loader(null);
    VideoPlayer.unloader(null);
    VideoPlayer.clear();
    localStorage.setItem('adultContent', 'OFF');
    this.fireAncestors('$updateSidebar');
  }

  static _states() {
    return [
      class ChannelsList extends this {
        _getFocused() {
          return this._ChannelsList;
        }

        _handleUp() {
          this._ChannelsList.selectedIndex = this._fullList.length - 1;
        }

        _handleDown() {
          this._ChannelsList.selectedIndex = 0;
        }
        _handleScroll() {
          this.$scroll();
        }

        _handleClick() {
          return false;
        }
      },
      class Player extends this {
        _getFocused() {
          return this.tag('Player');
        }
      },
    ];
  }
}
