import { getMyList } from '../../../api/myList/index';
import { getSerieDetails } from '../../../api/series/index';
import { getIndex } from '../../../api/index/index';

import SeasonButton from '../components/SeasonButton';
import Button from '../../SingleSerie/components/Button';

const createButtonItems = (buttons, continueToWatch) =>
  buttons.map((buttonInfo) => ({
    type: Button,
    w: continueToWatch ? 220 : 200,
    h: 80,
    buttonInfo,
  }));

export default async function (page, params) {
  try {
    const {
      data: { detail, episodes },
    } = await getSerieDetails(params.id, 3);
    const seriesString = localStorage.getItem('series');
    const series = seriesString ? JSON.parse(seriesString) : [];
    const continueToWatch = series.find((serie) => serie.content_id == params.id);
    const indexData = await getIndex();
    const { genres = [], languages = [] } = indexData.data.index || {};
    const language = languages.find((lng) => lng.id === detail.language_id);

    const serieGenresList = detail.genre_id
      .split(',')
      .map(Number)
      .map((genreId) => genres.find((genr) => genr.id === genreId)?.title)
      .filter(Boolean);

    const myList = await getMyList(1, 1, 1);
    const myListStatus = myList.data.includes(detail.content_id.toString());

    const Buttons = [
      { id: 1, title: continueToWatch ? 'Continue' : 'Watch', image: 'icons/ic_play_4.SVG' },
      { id: 2, title: 'Trailer', image: 'icons/ic_trailer.SVG' },
      {
        id: 3,
        title: myListStatus ? 'Remove' : 'My List',
        image: myListStatus ? '/icons/new/test_ok_icon_black.SVG' : 'icons/test_add_icon.SVG',
      },
    ];

    const items = createButtonItems(Buttons, continueToWatch);

    page._Buttons.items = items;
    page.myListStatus = myListStatus;

    const serieDetail = continueToWatch
      ? { continueToWatch: series[0]?.continueToWatch || 0, ...detail }
      : { continueToWatch: 0, ...detail };

    page.serieDetail = serieDetail;
    page.genres = serieGenresList;
    page.language = language?.title || '';

    const seasonsList = episodes.reduce((acc, episode) => {
      const seasonIndex = episode.season - 1;
      if (!acc[seasonIndex]) {
        acc[seasonIndex] = {
          title: `Season ${episode.season}`,
          episodes: [],
        };
      }
      acc[seasonIndex].episodes.push({
        title: `Episode ${episode.episode}`,
        videoURL: episode.content_stream,
        ...episode,
      });
      return acc;
    }, []);

    const seasons = seasonsList.map((season, index) => ({
      type: SeasonButton,
      w: 180,
      h: 60,
      index,
      initialIndex: continueToWatch ? continueToWatch.season - 1 : 0,
      buttonInfo: season,
      indicator: 'seasons',
    }));

    const episodesItems =
      seasonsList[continueToWatch ? continueToWatch.season - 1 : 0]?.episodes.map(
        (episode, index) => ({
          type: SeasonButton,
          w: 180,
          h: 60,
          index,
          initialIndex: continueToWatch ? continueToWatch.episode - 1 : 0,
          buttonInfo: episode,
          indicator: 'episodes',
        })
      ) || [];

    const firstSeasonFirstEpisodeDetails = continueToWatch
      ? continueToWatch
      : seasonsList[0]?.episodes[0];

    page._Seasons.items = seasons;
    page._Episodes.items = episodesItems;
    page.seasons = seasonsList;
    page.firstEpisode = firstSeasonFirstEpisodeDetails;
  } catch (error) {
    console.error('Error fetching series details:', error);
  }
}
