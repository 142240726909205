import { Router } from '@lightningjs/sdk';

// Not Found
import NotFound from './pages/NotFound/NotFound';

// Single Movie
import SingleMovie from './pages/SingleMovie/SingleMovie';
import movie from './pages/SingleMovie/providers/movie';

// Series
import Series from './pages/Series/Series';
import series from './pages/Series/providers/series';

// Movies
import Movies from './pages/Movies/Movies';
import movies from './pages/Movies/providers/movies';

// Single Serie
import SingleSerie from './pages/SingleSerie/SingleSerie';
import serie from './pages/SingleSerie/providers/serie';

// Home
import Home from './pages/Home/Home';
import home from './pages/Home/providers/home';

//Live Channels
import LiveChannels from './pages/LiveChannels/LiveChannels';
import liveChannels from './pages/LiveChannels/providers/liveChannels';

// Kids
import Kids from './pages/Kids/Kids';
import kids from './pages/Kids/providers/kids';

// Search
import Search from './pages/Search/Search';
import search from './pages/Search/providers/search';

//VODPlayer
import VODPlayer from './pages/VODPlayer/VODPlayer';
import player from './pages/VODPlayer/providers/player';

// MyList
import MyList from './pages/MyList/MyList';
import mylist from './pages/MyList/providers/mylist';

// Register
import Register from './pages/Register/Register';
import register from './pages/Register/providers/register';

// All Movies
import VODLanguage from './pages/VODLanguage/VODLanguage';
import vodlanguage from './pages/VODLanguage/providers/vodlanguage';
// Settings
import Settings from './pages/Settings/Settings';
import settings from './pages/Settings/providers/settings';

// Adult
import Adult from './pages/Adult/Adult';
import adult from './pages/Adult/providers/adult';

export default {
  // First we define a root, this is the hash were the browser will point to
  // at the moment that you boot your app
  root: 'home',
  // Next we can define the rest of our routes
  // boot: api.loadConfig,
  routes: [
    {
      path: 'player',
      component: VODPlayer,
      on: player,
      cache: 0,
    },
    { path: 'register', component: Register, on: register },
    { path: 'search', component: Search, on: search, widgets: ['sidebar'], cache: 60 * 60 },
    {
      path: 'liveTv',
      component: LiveChannels,
      on: liveChannels,
      widgets: ['sidebar'],
      cache: 60 * 60,
    },
    {
      path: 'home',
      component: Home,
      on: home,
      options: {
        preventStorage: true,
      },
    },
    { path: 'movies/:id', component: SingleMovie, on: movie },
    { path: 'series/:id', component: SingleSerie, on: serie },
    { path: 'series', component: Series, widgets: ['sidebar'], on: series, cache: 60 * 60 },
    { path: 'movies', component: Movies, widgets: ['sidebar'], on: movies, cache: 60 * 60 },
    { path: 'kids', component: Kids, widgets: ['sidebar'], on: kids, cache: 60 * 60 },
    { path: 'myList', component: MyList, widgets: ['sidebar'], caches: 0, on: mylist },
    {
      path: 'adult',
      component: Adult,
      widgets: ['sidebar'],
      on: adult,
      options: {
        preventStorage: true,
      },
    },
    {
      path: 'movieslist/:text',
      component: VODLanguage,
      cache: 60 * 60,
      on: vodlanguage,
    },
    {
      path: 'serieslist/:text',
      component: VODLanguage,
      cache: 60 * 60,
      on: vodlanguage,
    },
    {
      path: 'profile',
      component: Settings,
      widgets: ['sidebar'],
      on: settings,
      cache: 60 * 60,
    },
    {
      path: '*',
      component: NotFound,
      beforeNavigate() {
        Router.navigate('home', false);
      },
    },
  ],
  // beforeEachRoute: () => {
  //   return new Promise((resolve) => {
  //     if (userAuth()) {
  //     } else {
  //       Router.navigate('register');
  //     }
  //     resolve(true);
  //   });
  // },
};
