import { Column, Row } from '@lightningjs/ui-components';

// Api Calls
import { getSeries } from '../../../api/series/index';
import { getMovies } from '../../../api/movies/index';
import { getIndex } from '../../../api/index/index';

// Components
import VODRow from '../../Movies/components/VODRow';
import KidCard from '../../Kids/components/KidCard';
import Button from '../components/Button';

export default async function (page) {
  try {
    const { data } = await getIndex();
    let languages = data.index?.languages;
    let genresList = data.index.genres;
    let VODList = [];
    const movies = await getMovies();
    const series = await getSeries();

    const addToVODList = (list, languageTitle, genreTitle, item, isMovie) => {
      let findList = list.find((listItem) => listItem.languageTitle === languageTitle);
      if (!findList) {
        list.push({
          languageTitle: languageTitle,
          genres: [{ genre: genreTitle, vod: new Set([item]) }],
        });
      } else {
        let findGenre = findList.genres.find((g) => g.genre === genreTitle);
        if (!findGenre) {
          findList.genres.push({ genre: genreTitle, vod: new Set([item]) });
        } else {
          findGenre.vod.add(item);
        }
      }
    };

    const processMedia = (media, isMovie) => {
      media.forEach((item) => {
        item.flag = isMovie ? 'movies' : 'series';
        let language = languages.find((lng) => lng.id === item.language_id);
        let languageTitle = language ? language.title : 'Unknown';
        item.language = language ? language.title : 'Unknown';
        let genreIds = Array.isArray(item.genre_id)
          ? item.genre_id.map((genreId) => Number(genreId))
          : item.genre_id.split(',').map((genreId) => Number(genreId.trim()));

        // Check for 'All' category for the current language
        addToVODList(VODList, languageTitle, 'All', item, isMovie);

        // Check for 'All' category for all languages
        addToVODList(VODList, 'All', 'All', item, isMovie);
        item.genres = [];
        genreIds.forEach((genreId) => {
          let genre = genresList.find((g) => g.id === genreId);
          let genreTitle = genre ? genre.title : 'Unknown';
          item.genres.push(genreTitle);
          // Check for 'All' category for the current genre
          addToVODList(VODList, languageTitle, genreTitle, item);

          // Check for 'All' category for all genres
          addToVODList(VODList, 'All', genreTitle, item);
        });
      });
    };

    processMedia(movies.data.vod, true);
    processMedia(series.data.tvseries, false);

    // Convert sets to arrays
    VODList.forEach((listItem) => {
      listItem.genres.forEach((genreItem) => {
        genreItem.vod = Array.from(genreItem.vod);
      });
    });

    // Sort VODList to ensure 'All' is always first
    VODList.sort((a, b) => {
      if (a.languageTitle === 'All') {
        return -1;
      }
      if (b.languageTitle === 'All') {
        return 1;
      }
      return 0;
    });

    // Filter media list to include only those with the "Kids" genre
    const items = VODList[0].genres[0].vod.map((media, index) => ({
      type: KidCard,
      w: 265,
      h: 510,
      data: media,
      index: index,
    }));

    const languagesList = VODList.map((list, index) => ({
      type: Button,
      collision: true,
      w: 200,
      h: 60,
      data: list,
      flag: 'language',
      index: index,
    }));

    const genreList = VODList[0].genres.map((list, index) => ({
      type: Button,
      collision: true,
      w: 200,
      h: 60,
      data: list,
      flag: 'genre',
      index: index,
    }));

    let listOfItems = [];
    let listOfGenres = [];

    listOfItems.push({
      type: VODRow,
      x: 0,
      y: 50,
      h: 200,
      title: 'LANGUAGES',
      items: languagesList,
      collision: true,
      scrollIndex: 4,
    });

    listOfGenres.push({
      x: 0,
      y: 50,
      type: VODRow,
      h: 200,
      collision: true,
      title: 'CATEGORIES',
      items: genreList,
      scrollIndex: 4,
    });

    // Create rows in parallel
    const pageItems = await Promise.all(
      Array.from({ length: Math.ceil(items.length / 6) }).map(async (_, i) => {
        const start = i * 6;
        const end = start + 6;
        const rowItems = items.slice(start, end).map(async (item, j) => {
          return { ...item, rowIndex: i, itemIndex: j };
        });

        // Koristite Promise.all za čekanje na sve asinhrone operacije u map funkciji
        const resolvedRowItems = await Promise.all(rowItems);

        return {
          type: Row,
          collision: true,
          h: 510,
          items: resolvedRowItems,
          style: {
            itemSpacing: 30,
          },
        };
      })
    );

    page.data = VODList[0].genres[0].vod;
    page._CategoriesSection.items = listOfGenres;
    page._LanguagesSection.items = listOfItems;
    page._VODWrapper.patch({
      VODSection: undefined,
    });

    page._VODWrapper.patch({
      VODSection: {
        x: 20,
        y: 20,
        type: Column,
        collision: true,
        plinko: true,
        // items: pageItems,
        lazyUpCount: 2,
        lazyUpCountBuffer: 2,
      },
    });
    page._VODSection.items = [];
    page._VODSection.items = pageItems;
  } catch (error) {
    console.error('Error fetching media:', error);
  }
}
