import { Colors, Utils } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';

export default class MobileButton extends Lightning.Component {
  static _template() {
    return {
      Button: {
        collision: true,
      },
    };
  }

  set buttonInfo(val) {
    this._buttonInfo = val;
  }

  set index(val) {
    this._index = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  _enable() {
    this._Button.patch({
      color: Colors('#ffffff').get(),
      w: this.w,
      h: this.h,
      texture: Lightning.Tools.getSvgTexture(Utils.asset(this._buttonInfo?.icon), this.w, this.h),
    });
  }

  _handleHover() {
    this.fireAncestors('$setFocus', this._index);
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _focus() {
    this._Button.patch({
      texture: Lightning.Tools.getSvgTexture(
        Utils.asset(this._buttonInfo?.focusedIcon),
        this.w,
        this.h
      ),
    });
  }

  _unfocus() {
    this._Button.patch({
      texture: Lightning.Tools.getSvgTexture(Utils.asset(this._buttonInfo?.icon), this.w, this.h),
    });
  }
}
