import lightning from '@lightningjs/core';
import { Column } from '@lightningjs/ui-components';
import LiveChannelsButton from '../../Buttons/LiveChannelsButton';
import DescriptionButton from './DescriptionButton';
import LiveChannelsButtonData from '../../Buttons/LiveChannelsButtonData';

export default class EPGInfo extends lightning.Component {
  constructor(props) {
    super(props);
    this._data;
  }
  static _template() {
    return {
      collision: true,
      EPGInfo: {
        w: this.w,
        h: this.h,
        flex: { direction: 'row', justifyContent: 'center', aligItems: 'center' },
        VerticalLine: {
          // rect: true,
          w: 0,
          type: Column,
        },
        Date: {
          // y: 10,
          w: 300,
          type: Column,
        },
        Info: {
          type: Column,
        },
      },
    };
  }

  get _Date() {
    return this.tag('Date');
  }

  get _Info() {
    return this.tag('Info');
  }

  get _VerticalLine() {
    return this.tag('VerticalLine');
  }

  set data(val) {
    this._data = val;
    return (this._data = val);
  }

  get data() {
    return this._data;
  }

  /* $changedEPGInfo(data) {
    console.log('changedEPGInfo', data);
    let epg_desc = data.epg.map((epg) => ({
      type: DescriptionButton,
      h: 60,
      w: 600,
      buttonInfo: epg,
    }));
    this._Info.items = epg_desc;
  } */
  $changedEPGInfo(data) {
    let epg_desc = data.epg.map((epg) => ({
      type: DescriptionButton,
      h: 60,
      w: 600,
      buttonInfo: epg,
    }));
    this._Info.items = this._data.startTime;
  }

  /* _showEPG() {
    console.log('_showEPG ', this._data);

    let time = this._data.map((program) => ({
      type: LiveChannelsButtonData,
      w: 1000,
      h: 100,
      // buttonTitle: 'asd',
      // buttonTitle: `${program.startTime} - ${program.stopTime}`,
      buttonTitle: program,
      onEnter: '$changedEPGInfo',
      onEnterProps: program,
    }));
    this._Date.items = time;
    // this._VerticalLine.items = time;

    console.log('_showEPG ', programs);
  } */

  _showEPG() {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    let time = this._data.map((program) => {
      const [startHour, startMinute] = program.startTime.split(':');
      const programTime = new Date();
      programTime.setHours(Number(startHour), Number(startMinute));

      const isPastTime =
        programTime < currentTime ||
        (programTime.getHours() === currentHour && programTime.getMinutes() < currentMinute);

      return {
        type: LiveChannelsButtonData,
        collision: true,
        w: 990,
        h: 100,
        isPastTime: isPastTime,
        // buttonTitle: isPastTime ? `PAST: ${program}` : program,
        buttonTitle: program,
        onEnterProps: program,
      };
    });

    this._Date.items = time;
    // this._VerticalLine.items = time;
  }

  _enable() {
    this._Date.patch({
      h: this.h,
    });
    this._Info.patch({
      h: this.h,
    });
    /*  this._VerticalLine.patch({
      y: 3,
      w: 5,
      h: this.h - 8,
      rect: true,
    }); */
    this._VerticalLine.patch({
      h: this.h,
    });
  }

  _active() {
    this._setState('Date');
  }

  _handleHover() {
    console.log('EPG Container Hovered');
    this.fireAncestors('$setSectionState', 'PlayerSectionState');
    this._setState('Date');
  }

  static _states() {
    return [
      class Date extends this {
        _getFocused() {
          return this._Date;
        }
        /* _handleRight() {
          this._setState('Info');
        } */
        // _handleLeft() {
        //   this._setState('VerticalLine');
        // }
      },
      /* class Info extends this {
        _getFocused() {
          return this._Info;
        }
        _handleLeft() {
          this._setState('Date');
        }
      }, */

      /* class VerticalLine extends this {
        _getFocused() {
          return this._VerticalLine;
        }
        _handleRight() {
          this._setState('Date');
        }
      }, */
    ];
  }
}
