import lng from '@lightningjs/core';
import { Colors, Utils, VideoPlayer } from '@lightningjs/sdk';

export default class PlayerButton extends lng.Component {
  static _template() {
    return {
      flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
      Button: {
        collision: true,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        Icon: {
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        },
      },
    };
  }

  set index(val) {
    this._index = val;
  }

  set flag(val) {
    this._flag = val;
  }

  set buttonInfo(val) {
    return (this._buttonInfo = val);
  }

  get _Button() {
    return this.tag('Button');
  }

  get _Icon() {
    return this.tag('Icon');
  }

  _enable() {
    const { width, height, x, icon, iconWidth, iconHeight } = this._buttonInfo;
    this.patch({
      w: width,
    });
    this._Button.patch({
      w: width,
      h: height,
      texture: lng.Tools.getRoundRect(width, height, width / 2, 3, Colors('#C40000').get(), false),
    });
    if (this._buttonInfo.title == 'Pause' || this._buttonInfo.title == 'Play') {
      if (VideoPlayer.playing === true) {
        this._Icon.patch({
          x: x || 0,
          texture: lng.Tools.getSvgTexture(Utils.asset(icon), iconWidth, iconHeight),
        });
      } else {
        this._Icon.patch({
          x: 10,
          texture: lng.Tools.getSvgTexture(
            Utils.asset('/icons/ic_play_4.SVG'),
            iconWidth,
            iconHeight
          ),
        });
      }
    } else {
      this._Icon.patch({
        x: x || 0,
        texture: lng.Tools.getSvgTexture(Utils.asset(icon), iconWidth, iconHeight),
      });
    }
  }

  _updateButtonTexture(isFocused) {
    const { width, height } = this._buttonInfo;
    const roundRectTexture = lng.Tools.getRoundRect(
      width,
      height,
      width / 2,
      3,
      Colors('#C40000').get(),
      isFocused,
      Colors('#C40000').get()
    );
    this._Button.patch({ texture: roundRectTexture });
  }

  _focus() {
    this._updateButtonTexture(true);
    this.fireAncestors('$preventHideProgressBar');
  }

  _unfocus() {
    this._updateButtonTexture(false);
    this.fireAncestors('$preventHideProgressBar');
  }

  _handleClick() {
    this._handleEnter();
  }

  _handleHover() {
    this.fireAncestors('$setFocus', { index: this._index, flag: this._flag });
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _pauseVideo() {
    VideoPlayer.pause();
    this.fireAncestors('$clearInterval');
    this.fireAncestors('$clearSubtitleInterval');
    this._updateIcon(10, '/icons/ic_play_4.SVG', 70, 70);
    this._buttonInfo.title = 'Play';
  }

  _playVideo() {
    VideoPlayer.play();
    this.fireAncestors('$clearInterval');
    this.fireAncestors('$updateCurrentTime');
    this.fireAncestors('$subtitleInterval');
    this._updateIcon(
      0,
      '/images/pause.svg',
      this._buttonInfo.iconWidth,
      this._buttonInfo.iconHeight
    );
    this._buttonInfo.title = 'Pause';
  }

  _updateIcon(x, icon, iconWidth, iconHeight) {
    this._Icon.patch({
      x: x,
      texture: lng.Tools.getSvgTexture(Utils.asset(icon), iconWidth, iconHeight),
    });
  }

  _checkButton() {
    this.fireAncestors('$preventHideProgressBar');
    switch (this._buttonInfo.title) {
      case 'Pause':
        if (!VideoPlayer.playing) {
          return this._playVideo();
        }
        this._pauseVideo();
        break;
      case 'Play':
        if (VideoPlayer.playing) {
          return this._pauseVideo();
        }
    }
  }

  _handleEnter() {
    this.fireAncestors('$preventHideProgressBar');
    switch (this._buttonInfo.title) {
      case 'Backward':
        VideoPlayer.seek(VideoPlayer.currentTime - 10);
        this.fireAncestors('$updateCurrentTimeAndProgressBar');
        break;
      case 'Forward':
        VideoPlayer.seek(VideoPlayer.currentTime + 10);
        this.fireAncestors('$updateCurrentTimeAndProgressBar');
        break;
      case 'Pause':
        if (!VideoPlayer.playing) {
          return this._playVideo();
        }
        this._pauseVideo();
        break;
      case 'Play':
        if (VideoPlayer.playing) {
          return this._pauseVideo();
        }
        this._playVideo();
        break;
      case 'Subtitle':
        this.fireAncestors('$showSubtitleModal');
    }
  }
}
