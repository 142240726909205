import { Colors, Utils } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';
import { Column } from '@lightningjs/ui-components';
import Button from './Button';
import Channels from './Channels';
import { getLock } from '../../../../../api/settings';
import { getMyList } from '../../../../../api/myList';

export default class LastChannelsModal extends Lightning.Component {
  set currentChannel(val) {
    return (this._currentChannel = val);
  }

  static _template() {
    return {
      w: 600,
      h: 800,
      rect: true,
      color: Colors('#272729').get(),
      x: 1150,
      y: 20,
      clipping: true,
      collision: true,
      Hero: {
        w: 600,
        flex: { direction: 'row', justifyContent: 'center' },
        Title: { y: 10, text: { text: 'Last Channels' }, fontStyle: 'normal bold' },
      },
      Icon: {
        y: -15,
        type: Button,
        x: 500,
        collision: true,
        buttonProps: {
          icon: '/icons/trash.SVG',
          width: 80,
          height: 75,
        },
      },

      Wrapper: {
        y: 70,
        h: 700,
        w: 500,
        clipping: true,
      },
    };
  }

  _active() {
    // Retrieve items from local storage
    this._setState('DeleteButton');
    this._addChannels();
  }

  async _addChannels() {
    this.tag('Wrapper').patch({
      Channels: undefined,
    });
    const storedData = localStorage.getItem('lastChannels') || '[]'; // Replace 'yourKey' with your actual key
    const lockedChannels = await getLock();
    const favouriteList = await getMyList();
    const parsedData = JSON.parse(storedData);
    this.tag('Wrapper').patch({
      Channels: {
        y: 15,
        type: Column,
        collision: true,
        scrollIndex: 2,
        h: 100,
        plinko: true,
        lazyUpCount: 10,
        lazyUpCountBuffer: 10,
        items: parsedData.map((data, index) => ({
          type: Channels,
          collision: true,
          index: index,
          buttonInfo: data,
          w: 500,
          h: 65,
          isFavourite: favouriteList.data.includes(data.channelInfo.content_id.toString())
            ? true
            : false,
          isLocked: lockedChannels.data.includes(data.channelInfo.content_id) ? true : false,
        })),
      },
    });
    // this.tag('Channels').patch({});
    this._setState('DeleteButton');
  }

  $deleteLastChannels() {
    localStorage.removeItem('lastChannels');
    this.tag('Wrapper').patch({
      Channels: undefined,
    });
    this.fireAncestors('$hideLastChannelsModal');
  }

  $setFocus({ index, flag }) {
    this.tag('Channels').scrollTo(index, 100);
    this._setState(flag);
  }

  $setState(state) {
    this._setState(state);
  }

  static _states() {
    return [
      class Channels extends this {
        _handleUp() {
          this._setState('DeleteButton');
        }
        _getFocused() {
          return this.tag('Channels');
        }
      },
      class DeleteButton extends this {
        _handleDown() {
          this._setState('Channels');
        }
        _getFocused() {
          return this.tag('Icon');
        }
      },
    ];
  }
}
