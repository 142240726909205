import lng from '@lightningjs/core';
import { Colors, Utils, VideoPlayer } from '@lightningjs/sdk';
import { loader, unloader } from '../../../components/Player/video';
import { getToken } from '../../../utils/device';

export default class Player extends lng.Component {
  static _template() {
    return {
      collision: true,
      w: 1920,
      h: 1080,
      ProgressContainer: {
        zIndex: 110,
        visible: false,
        y: 780,
        w: 1920,
        h: 300,
        rect: true,
        color: Colors('#000000').alpha(0.7).get(),
        flex: { direction: 'column' },
        ChannelInfo: {
          y: 40,
          x: 80,
          w: 500,
          h: 70,
          flex: { direction: 'row', justifyContent: 'space-between', alignItems: 'center' },
          ChannelNumber: { text: { fontSize: 50 } },
          ChannelIcon: {},
          ChannelName: { text: { fontSize: 50 } },
        },
        ChannelCategory: {
          y: 40,
          x: 80,
          text: { text: 'Other | Adult', fontSize: 50 },
        },
      },
    };
  }

  set firstChannel(val) {
    this._firstChannel = val;
  }

  set index(val) {
    this._index = val;
  }

  async _active() {
    this.fireAncestors('$punchHole');
    this.fireAncestors('$selectButton', 0);

    // VideoPlayer.open(this._firstChannel.streams.live[0].content_stream);
    this.fireAncestors('$selectButton', 0);

    const requestOptions = {
      method: 'GET',
      headers: {
        'X-Auth': await getToken(), // Dodaj token u Authorization zaglavlje
        'X-Client-Version': 'ZeenTV Hisense 1.0.0',
      },
    };
    const playerSettings = {
      debug: true,
    };
    fetch(this._firstChannel?.streams.live[0].content_stream, requestOptions)
      .then((response) => {
        VideoPlayer.open(response.url, playerSettings);
        VideoPlayer.play();

        // Proveri da li je odgovor uspešan (status koda 200)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Ako je odgovor uspešan, parsiraj odgovor kao tekst
        return response.text();
      })
      .then((data) => {
        // Sada možeš raditi sa podacima, na primer, reprodukovati URL
        console.log('Reprodukcija URL-a:', data);

        // Ovde možeš dodati kod za reprodukciju URL-a
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }
  _init() {
    VideoPlayer.consumer(this);
    VideoPlayer.loader(loader);
    VideoPlayer.unloader(unloader);
  }

  _enable() {
    VideoPlayer.position(0, 0);
    VideoPlayer.size(1920, 1080);
    // VideoPlayer.consumer(this);
    // VideoPlayer.loader(loader);
    // VideoPlayer.unloader(unloader);
    VideoPlayer.loop(false);
    this.tag('ChannelNumber').patch({
      text: { text: this._index.toString().padStart(4, '0') },
    });
    this.tag('ChannelIcon').patch({
      texture: lng.Tools.getSvgTexture(
        this._firstChannel?.image_url == 'test'
          ? Utils.asset('/icons/tv_default_icon.SVG')
          : this._channelInfo?.image_url,
        60,
        60
      ),
    });
    this.tag('ChannelName').patch({ text: { text: this._firstChannel?.title } });
  }

  async _updateChannel(channel, index) {
    this._index = index;
    VideoPlayer.consumer(this);
    // VideoPlayer.open(channel.streams.live[0].content_stream);
    this._fetchChannelUrl(channel.streams.live[0].content_stream);

    this.tag('ChannelNumber').patch({
      text: { text: index.toString().padStart(4, '0') },
    });
    this.tag('ChannelIcon').patch({
      texture: lng.Tools.getSvgTexture(
        channel?.image_url == 'test'
          ? Utils.asset('/icons/tv_default_icon.SVG')
          : channel?.image_url,
        60,
        60
      ),
    });
    this.tag('ChannelName').patch({ text: { text: channel.title } });

    // Clear any existing timeout
    clearTimeout(this._progressContainerTimeout);

    if (this.parent._ChannelsList.visible === false) {
      this.tag('ProgressContainer').visible = true;

      // Set a new timeout and store its ID
      this._progressContainerTimeout = setTimeout(() => {
        this.tag('ProgressContainer').visible = false;
      }, 1500);
    }
  }

  $showChannelContainer() {
    clearTimeout(this._progressContainerTimeout);

    if (this.parent._ChannelsList.visible === false) {
      this.tag('ProgressContainer').visible = true;

      // Set a new timeout and store its ID
      this._progressContainerTimeout = setTimeout(() => {
        this.tag('ProgressContainer').visible = false;
      }, 1500);
    }
  }

  async _fetchChannelUrl(url) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'X-Auth': await getToken(),
        'X-Client-Version': 'ZeenTV Hisense 1.0.0',
      },
    };
    const playerSettings = {
      debug: true,
    };

    fetch(url, requestOptions)
      .then((response) => {
        VideoPlayer.open(response.url, playerSettings);
        VideoPlayer.play();

        // Proveri da li je odgovor uspešan (status koda 200)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Ako je odgovor uspešan, parsiraj odgovor kao tekst
        return response.text();
      })
      .then((data) => {
        // Sada možeš raditi sa podacima, na primer, reprodukovati URL
        console.log('Reprodukcija URL-a:', data);

        // Ovde možeš dodati kod za reprodukciju URL-a
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  _handleChannelUp() {
    this._handleUp();
  }

  _handleChannelDown() {
    this._handleDown();
  }

  _handleDown() {
    this.fireAncestors('$previousChannel', this._index);
    this.fireAncestors('$selectButton', this._index - 1);
  }

  _handleUp() {
    this.fireAncestors('$nextChannel', this._index - 1);
    this.fireAncestors('$selectButton', this._index - 1);
  }

  _handleEnter() {
    this.fireAncestors('$showWidget', true);
    this.fireAncestors('$showChannelBox', this._index - 1);
  }

  _handleBack() {
    this.fireAncestors('$showWidget', true);
    this.fireAncestors('$showChannelBox', this._index - 1);
    this.fireAncestors('$setBackStatus', true);
  }

  _handleClick() {
    console.log('Clicked');
    this._handleEnter();
  }
  _changeChannel(previousChannel, index) {
    this._index = index;
    this._updateChannel(previousChannel, index);
  }
}
