import { Colors, Router } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';
import { Row } from '@lightningjs/ui-components';

// Components
import UserSettings from './components/UserSettings/UserSettings';

export default class Settings extends Lightning.Component {
  static _template() {
    return {
      Wrapper: {
        w: 1920,
        h: 1080,
        rect: true,
        color: Colors('#000000').get(),
        SettingsOptions: {
          collision: true,
          type: Row,
          x: 170,
          y: 50,
          w: 1920,
          h: 200,
        },
        SettingContainer: {
          type: UserSettings,
          x: 170,
          y: 200,
        },
      },
    };
  }

  get _SettingsOptions() {
    return this.tag('SettingsOptions');
  }

  get _SettingContainer() {
    return this.tag('SettingContainer');
  }

  $changeComponent({ data }) {
    data;
    this.tag('Wrapper').patch({
      SettingContainer: undefined,
    });
    this.tag('Wrapper').patch({
      SettingContainer: {
        collision: true,
        type: data.component,
        x: 170,
        y: 200,
      },
    });
  }

  _firstEnable() {
    this._setState('SettingsOptions');
  }

  $setFocus(index) {
    this._SettingsOptions.selectedIndex = index;
  }

  $unselectCurrentIndex() {
    let currentIndex = this._SettingsOptions.selectedIndex;
    this._SettingsOptions.items[currentIndex]._unfocus();
  }

  $changeState(status) {
    this._setState(status);
  }

  _handleLeft() {
    Router.focusWidget('sidebar');
  }

  static _states() {
    return [
      class SettingsOptions extends this {
        _getFocused() {
          return this._SettingsOptions;
        }
        _handleDown() {
          this._setState('SettingContainer');
        }
      },
      class SettingContainer extends this {
        _getFocused() {
          return this._SettingContainer;
        }
        _handleUp() {
          this._setState('SettingsOptions');
        }
      },
    ];
  }
}
