export const createDateAndTimeLabel = (date = new Date()) => {
  const day = date.getDate();
  const month = date.getMonth() + 1; // Adding 1 to correctly represent the month
  const year = date.getFullYear();
  const DayTime = date.getHours() / 12 >= 1 ? 'PM' : 'AM';
  let hour = date.getHours() % 12;
  hour = String(hour ? hour : 12).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}-${month}-${year} ${hour}:${minutes} ${DayTime}`;
};
