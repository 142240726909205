import { Colors } from '@lightningjs/sdk';
import lng from '@lightningjs/core';

export default class NotFound extends lng.Component {
  static _template() {
    return {
      _testId: 'NotFound',
      title: 'Not Found',
      ...super._template(),
      SectionsContainer: {
        x: 30,
        y: 30,
        Title: {
          text: {
            text: '404 Page Not Found',
            textColor: Colors('#ffffff').get(),
          },
        },
      },
    };
  }

  _getFocused() {
    return this;
  }
}
