import request from '../index';
export const getMovies = (languageId = 0, countryId = 0) =>
  request({
    url: `/vod/list/${languageId}/${countryId}`,
    method: 'GET',
  });

export const getMovieDetails = (contentId, contentTypeId) =>
  request({
    url: `/contents/details/${contentTypeId}/${contentId}`,
  });
