import { getMovieDetails } from '../../../api/movies/index';
import { getMyList } from '../../../api/myList';
import { getIndex } from '../../../api/index/index';

//Components
import Button from '../components/Button';

export default async function initializePage(page, params) {
  try {
    const indexData = await getIndex();
    const myList = await getMyList(1, 1, 1);

    const moviesString = localStorage.getItem('movies');
    const movies = moviesString ? JSON.parse(moviesString) : [];
    const continueToWatch = !!movies.find((mov) => mov.content_id == params.id);

    const { data } = await getMovieDetails(params.id, 2);

    const genres = indexData.data.index.genres;
    const languages = indexData.data.index?.languages;
    const language = languages?.find((lng) => lng.id === data.detail.language_id);

    const myListStatus = myList.data.includes(params.id.toString());

    const movieGenresList = data.detail.genre_id
      .split(',')
      .map(Number)
      .map((genreId) => genres?.find((genr) => genr.id === genreId)?.title)
      .filter((title) => title);

    const buttonsInfo = [
      { id: 1, title: continueToWatch ? 'Continue' : 'Watch', image: 'icons/ic_play_4.SVG' },
      { id: 2, title: 'Trailer', image: 'icons/ic_trailer.SVG' },
      {
        id: 3,
        title: myListStatus ? 'Remove' : 'My List',
        image: myListStatus ? '/icons/new/test_ok_icon_black.SVG' : 'icons/test_add_icon.SVG',
      },
    ];

    const buttonItems = buttonsInfo.map((buttonInfo) => ({
      type: Button,
      w: continueToWatch ? 220 : 200,
      h: 80,
      buttonInfo,
    }));

    page._Buttons.items = buttonItems;
    page.myListStatus = myListStatus;

    const movieDetail = continueToWatch
      ? { continueToWatch: movies[0].continueToWatch, ...data.detail }
      : { continueToWatch: 0, ...data.detail };

    page.movieDetail = movieDetail;
    page.genres = movieGenresList;
    page.language = language?.title || 'Unknown Language';
  } catch (error) {
    console.error('Error:', error);
  }
}
