// API
import { getSeries } from '../../../api/series/index';
import { getIndex } from '../../../api/index/index';
import VODComponent from '../../Movies/components/VODComponent';
import VODRow from '../../Movies/components/VODRow';
import ShowMoreCard from '../../Movies/components/ShowMoreCard';

const ITEMS_LIMIT = 19;

async function fetchSeriesData() {
  const [indexData, seriesResponse] = await Promise.all([getIndex(), getSeries()]);
  return {
    indexData,
    seriesData: seriesResponse.data.tvseries || [],
    continueToWatchSeries: JSON.parse(localStorage.getItem('series')) || [],
  };
}

function mapSeries(series, languages, genres) {
  return series
    .filter((serie) => !serie.genre_id.includes('Kids')) // Filtriramo serije čiji žanr nije "Kids"
    .filter((serie) => {
      const language = languages?.find((lng) => lng.id === serie.language_id);
      const languageTitle = language ? language.title : 'Unknown';
      return languageTitle !== 'Other'; // Filtriramo serije čiji jezik nije "Other"
    })
    .map((serie) => {
      const language = languages?.find((lng) => lng.id === serie.language_id);
      const languageTitle = language ? language.title : 'Unknown';
      const serieGenresList = serie.genre_id
        .split(',')
        .map(Number)
        .map((genreId) => genres?.find((genr) => genr.id === genreId)?.title)
        .filter((title) => title);

      return { ...serie, genres: serieGenresList, languageTitle };
    })
    .reduce((acc, serie) => {
      const languageGroup = acc.find((group) => group.title === serie.languageTitle);

      if (!languageGroup) {
        acc.push({ title: serie.languageTitle, series: [serie] });
      } else {
        languageGroup.series.push(serie);
      }
      return acc;
    }, []);
}

function createVODRow(languageGroup, continueToWatch = false) {
  const limitedSeries = languageGroup.series.slice(0, ITEMS_LIMIT);
  const items = limitedSeries.map((serie, index) => ({
    type: VODComponent,
    w: 255,
    h: 400,
    data: serie,
    index: index,
    collision: true,
    language: languageGroup.title,
    flag: 'series',
  }));

  const additionalItem =
    languageGroup.series.length > ITEMS_LIMIT
      ? {
          type: ShowMoreCard,
          w: 255,
          h: 400,
          collision: true,
          language: languageGroup.title,
          data: {
            image_url: '/images/see_more.jpg',
            title: 'Show More',
            description: '',
            genres: [],
          },
          fullData: continueToWatch
            ? { title: 'Continue to Watch', series: languageGroup.series }
            : languageGroup,
          flag: 'serieslist',
        }
      : null;

  return {
    type: VODRow,
    h: 550,
    x: 20,
    y: 80,
    title: continueToWatch ? 'Continue to Watch' : languageGroup.title,
    items: [...items, additionalItem].filter(Boolean),
    scrollIndex: 3,
    collision: true,
    style: {
      itemSpacing: 10,
    },
  };
}

export default async function (page) {
  try {
    const { indexData, seriesData, continueToWatchSeries } = await fetchSeriesData();

    const languages = indexData.data.index?.languages;
    const genres = indexData.data.index.genres;
    const seriesList = mapSeries(seriesData, languages, genres);

    const filteredContinueToWatch = continueToWatchSeries.filter((serie) => {
      const language = languages?.find((lang) => lang.id === serie.language_id);
      return language && language.title !== 'Other'; // Filtriramo serije čiji jezik nije "Other"
    });

    const pageItems = seriesList.map((languageGroup) => createVODRow(languageGroup));

    if (filteredContinueToWatch.length > 0) {
      pageItems.unshift(
        createVODRow({ title: 'Continue to Watch', series: filteredContinueToWatch }, true)
      );
    }

    page._Column.items = pageItems;
  } catch (error) {
    console.error('Error fetching series:', error);
  }
}
