import { Colors } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';

export default class InfoModal extends Lightning.Component {
  static _template() {
    return {
      y: 0,
      w: 1920,
      h: 790,
      rect: true,
      color: Colors('#000000').alpha(0.7).get(),
      Title: {},
      Wrapper: {
        Image: {},
        Details: {
          flex: { direction: 'column' },
          Duration: {},
          ProgressBar: {
            clipping: true,
            w: 1300,
            h: 40,
            x: 450,
            y: 280,
            rect: true,
            color: Colors('#a0a0a0').get(),
            Progress: {
              rect: true,
              w: 0,
              h: 40,
              color: Colors('#C40000').get(),
            },
          },
          TimeContainer: {
            x: 450,
            y: 300,
            w: 1300,
            flex: { direction: 'row', justifyContent: 'space-between' },
            Start: {},
            End: {},
          },
        },
      },
      Description: {},
    };
  }

  set epg(val) {
    this._epg = val;
  }

  async _findCurrentEPG(epgData) {
    const currentTime = new Date(); // Get current time

    let currentEvent = null;
    for (const day of epgData) {
      for (const event of day.epg) {
        const startTime = this.parseTime(event.startTime);
        const stopTime = this.parseTime(event.stopTime);
        if (currentTime >= startTime && currentTime < stopTime) {
          // If current time is within the program's time range
          currentEvent = event;
          break;
        }
      }
      if (currentEvent) {
        break; // Exit the loop if current event is found
      }
    }

    return currentEvent;
  }

  parseTime(timeStr) {
    const [hours, minutes] = timeStr.split(':').map(Number);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    const parsedTime = new Date(year, month, day, hours, minutes);
    return parsedTime;
  }
  _checkIfLeftToRight(text) {
    var arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(text);
  }

  async _enable() {
    const currentEPG = await this._findCurrentEPG(this._epg);
    if (currentEPG) {
      this.tag('Title').patch({
        y: 100,
        x: 450,
        text: { text: currentEPG.title },
      });
      this.tag('Image').patch({
        x: 50,
        y: 250,
        texture: Lightning.Tools.getSvgTexture(currentEPG.icon, 300, 170),
      });
      const startTime = this.parseTime(currentEPG.startTime);
      const stopTime = this.parseTime(currentEPG.stopTime);
      const currentTime = new Date();
      const elapsedTime = currentTime - startTime;
      const totalTime = stopTime - startTime;
      const progressPercentage = (elapsedTime / totalTime) * 100;

      this.tag('Duration').patch({
        text: { text: `Duration: ${Math.round(totalTime / (1000 * 60))} min` },
        y: 260,
        x: 450,
      });

      this.tag('Start').patch({
        text: { text: `Start: ${currentEPG?.startTime}` },
      });
      this.tag('End').patch({
        text: { text: `End: ${currentEPG?.stopTime}` },
      });

      this.tag('ProgressBar')
        .tag('Progress')
        .patch({
          w: 1300 * (progressPercentage / 100),
        });
      const isArabic = this._checkIfLeftToRight(currentEPG.description);
      this.tag('Description').patch({
        y: 460,
        x: 450,
        w: 1300,
        text: { text: currentEPG.description, textAlign: isArabic ? 'right' : 'left' },
      });
    }
  }
}
