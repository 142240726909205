import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

export default class SubscriptionButton extends lng.Component {
  constructor(props) {
    super(props);
  }
  static _template() {
    return {
      Button: {
        rect: true,
        collision: true,
        rect: true,
        color: Colors('#232323').get(),
        flex: {
          direction: 'row',
          alignItems: 'center',
        },
        Text: {
          x: 30,
          w: 300,
          h: 70,
          flex: { direction: 'row', alignItems: 'center' },
          Info: {},
        },

        VerticalLine: {
          w: 2,
          h: 55,
          rect: true,
        },

        Data: {
          w: 1050 - 300,
          h: 70,
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          Info: {
            y: 3,
          },
        },
      },
    };
  }

  set index(val) {
    this._index = val;
  }

  set buttonInfo(val) {
    this._buttonInfo = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  get _Text() {
    return this.tag('Text.Info');
  }

  get _Data() {
    return this.tag('Data.Info');
  }

  _enable() {
    this._Button.patch({
      w: this.w,
      h: this.h,
      texture: lng.Tools.getRoundRect(this.w, this.h, 10, 3, false),
    });
    this._Text.patch({
      text: { text: this._buttonInfo.title, fontSize: 30 },
    });
    this._Data.patch({
      text: { text: this._buttonInfo.option, fontSize: 30 },
    });
  }

  _handleEnter() {
    const { title, option } = this._buttonInfo;
    const { text } = this._Data;

    switch (title) {
      case 'Change Quality':
        this.fireAncestors('$showModal', 'quality');
        break;
      case '+18':
        if (text.text === 'OFF') {
          this.fireAncestors('$showModal', 'show+18');
        } else {
          const newOption = 'OFF';
          localStorage.setItem('adultContent', newOption);
          this._buttonInfo.option = newOption;
          this._Data.patch({
            text: { text: newOption, fontSize: 30 },
          });
          this.fireAncestors('$updateSidebar');
        }
        break;
      case 'Change Pin':
        this.fireAncestors('$showModal', 'changePin');
        break;
    }
  }

  _handleHover() {
    this.fireAncestors('$changeState', 'SettingContainer');
    this.fireAncestors('$setFocus', this._index);
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._handleEnter();
  }

  _focus() {
    this._Button.patch({ color: Colors('#C40000').get() });
  }
  _unfocus() {
    this._Button.patch({ color: Colors('#232323').get() });
  }
}
