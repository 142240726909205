import lng from '@lightningjs/core';

import SubscriptionInput from '../../../../Settings/components/TransferSettings/SubscriptionInput';
import { Colors } from '@lightningjs/sdk';
// import Keyboard from '../../../../Register/components/Keyboard';
import { getPassword } from '../../../../../api/settings/index';
import MessageBox from '../../../../Settings/MessageBox';
import Keyboard from '../../../../../components/Keyboard/components/Keyboard';

let step = 1;
export default class LockModal extends lng.Component {
  static _template() {
    return {
      Modal: {
        x: 0,
        y: 0,
        w: 1920,
        h: 1080,
        rect: true,
        collision: true,
        zIndex: 205,
        color: Colors('#000000').alpha(0.9).get(),
        flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
        Title: { text: { text: 'Enter Child Lock Password' } },
        Wrapper: {
          flex: { direction: 'row', justifyContent: 'center' },
          InputField: {
            type: SubscriptionInput,
            y: 50,
            w: 700,
            h: 100,
            zIndex: 205,
            placeholder: 'Lock Password',
            collision: true,
            isPassword: true,
          },
        },
      },
      Keyboard: {
        w: 1920,
        h: 1080,
        rect: true,
        color: Colors('#000000').alpha(0).get(),
        flex: { direction: 'row', justifyContent: 'center' },
        type: Keyboard,
        y: 760,
        // x: 130,
        zIndex: 205,
        visible: false,
        limit: 4,
      },
    };
  }

  _setMessage(msg) {
    this.patch({
      MessageBox: {
        type: MessageBox,
        x: 650,
        y: 900,
        w: 550,
        h: 60,
        zIndex: 250,
        message: msg,
      },
    });
    setTimeout(() => {
      this.patch({
        MessageBox: undefined,
      });
    }, 2000);
  }

  get _InputField() {
    return this.tag('InputField');
  }

  _active() {
    this._setState('InputField');
  }

  $setState(state) {
    this._setState(state);
  }

  $setFocusKeyboard() {
    this._setState('Keyboard');
  }

  $showKeyboard() {
    this.tag('Keyboard').patch({
      visible: true,
    });
    this._InputField.tag('Input').listening = true;
    this._setState('Keyboard');
  }

  // async $pressedDone() {
  //   if (step == 1) {
  //     this.tag('Keyboard').visible = false;
  //     this._setState('InputField');
  //     this._InputField.tag('Input').listening = false;
  //     const inputValue = this._InputField.tag('Input').value;
  //     const { data } = await getPassword();
  //     if (inputValue == '') {
  //       this.fireAncestors('$hideModal', { flag: 'changePin', quality: 'empty' });
  //     }
  //     if (inputValue == data[0]) {
  //       step = 2;
  //       this.tag('Title').patch({
  //         text: { text: 'NEW PIN' },
  //       });
  //       this.tag('Keyboard').visible = true;
  //       this._InputField.tag('Input').listening = true;
  //       this._InputField.tag('Input').clear();

  //       this._setState('Keyboard');
  //     } else {
  //       this.fireAncestors('$hideModal', { flag: 'changePin', quality: 'wrong' });
  //     }
  //     return;
  //   }
  //   if (step == 2) {
  //     this.tag('Keyboard').visible = false;
  //     this._setState('InputField');
  //     this._InputField.tag('Input').listening = false;
  //     const inputValue = this._InputField.tag('Input').value;

  //     if (inputValue == '') {
  //       this.fireAncestors('$hideModal', { flag: 'changePin', quality: 'empty' });
  //     }
  //     if (inputValue.length > 3) {
  //       await setPassword(inputValue);
  //       this.fireAncestors('$hideModal', { flag: 'changePin', quality: inputValue });
  //     } else {
  //       this.fireAncestors('$hideModal', { flag: 'changePin', quality: 'wrong' });
  //     }
  //     step = 1;

  //     return;
  //   }
  // }

  async $pressedDone() {
    this.tag('Keyboard').visible = false;
    this._setState('InputField');
    const inputValue = this._InputField.tag('Input').value;
    if (inputValue == '') {
      this._setMessage('Pin can not be empty!');
    }
    if (inputValue.length < 4) {
      this._setMessage('Pin must be 4 numbers');
    } else {
      const { data } = await getPassword();
      if (inputValue == data[0]) {
        this.fireAncestors('$lockChannelFired', {
          flag: this._flag,
          index: this._index,
          data: this._data,
        });
      } else {
        this._setMessage('Wrong pin');
      }
    }
    this._InputField.tag('Input').clear();
    this._InputField.tag('Input').listening = false;
    this._InputField._changePlaceholder();
  }

  set flag(val) {
    this._flag = val;
  }

  set index(val) {
    this._index = val;
  }

  set data(val) {
    this._data = val;
  }

  static _states() {
    return [
      class InputField extends this {
        _getFocused() {
          return this.tag('InputField');
        }
      },
      class Keyboard extends this {
        _getFocused() {
          return this.tag('Keyboard');
        }
      },
    ];
  }
}
