import TVChannelInfoButton from './TVChannelInfoButton';

export const buttons = [
  {
    type: TVChannelInfoButton,
    name: 'channelList',
    label: 'Channel list',
    icon: 'redflix_infobar_ok.SVG',
    flexItem: { marginLeft: 60 },
    getWidth: 200,
    index: 0,
  },
  {
    type: TVChannelInfoButton,
    name: 'favourite',
    label: 'Favourite',
    icon: 'redflix_infobar_favorite.svg',
    flexItem: { marginLeft: 30 },
    getWidth: 180,
    index: 1,
  },
  {
    type: TVChannelInfoButton,
    name: 'info',
    label: 'Info',
    icon: 'ic_info_default.SVG',
    flexItem: { marginLeft: 30 },
    getWidth: 120,
    index: 2,
  },
  {
    type: TVChannelInfoButton,
    name: 'channelQuality',
    label: 'HD',
    icon: 'redflix_infobar_hd.SVG',
    flexItem: { marginLeft: 30 },
    getWidth: 120,
    index: 3,
  },
  {
    type: TVChannelInfoButton,
    name: 'lastChannels',
    label: 'Last Channels',
    icon: 'last_channels.SVG',
    flexItem: { marginLeft: 30 },
    getWidth: 250,
    index: 4,
  },
  // {
  //   type: TVChannelInfoButton,
  //   name: 'lock',
  //   label: 'Lock',
  //   icon: 'lock.SVG',
  //   iconActive: 'locked.SVG',
  //   flexItem: { marginLeft: 30 },
  //   getWidth: 120,
  // },
];
