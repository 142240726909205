import lng from '@lightningjs/core';
import { Colors, Router, Utils } from '@lightningjs/sdk';

export default class SidebarItem extends lng.Component {
  static _template() {
    return {
      Item: {
        collision: true,
        rect: true,
        h: 78,
        w: 253,
        x: 3,
        y: 3,
        color: Colors('#f2f2f200').get(),
        flex: { alignItems: 'center' },
        IconWrapper: {
          w: 37,
          flex: { alignItems: 'center' },
          Image: {},
        },
        Text: {
          y: 5,
        },
      },
      FocusBorder: {},
    };
  }

  set item({ id, image, height, path, title, width }) {
    this._item = { id, image, height, path, title, width };
  }

  get _Image() {
    return this.tag('Image');
  }

  get _Text() {
    return this.tag('Text');
  }

  set index(val) {
    this._index = val;
  }

  _handleHover() {
    // Clear old timeout, if any
    if (this._hoverTimeoutId) {
      clearTimeout(this._hoverTimeoutId);
    }

    this.fireAncestors('$unselectCurrentIndex');

    // Set new timeout
    this._hoverTimeoutId = setTimeout(() => {
      this.fireAncestors('$setFocus', this._index);
    }, 1);

    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._handleEnter();
  }

  _focus() {
    super._focus();
    this.tag('Item').patch({
      color: Colors('#737373').get(),
    });

    this.tag('FocusBorder').patch({
      texture: lng.Tools.getRoundRect(255, 80, 5, 5, Colors('#cacaca').get(), false),
    });
  }

  _unfocus() {
    super._unfocus();
    this.tag('Item').patch({
      color: Colors('#61616100').get(),
    });
    this.tag('FocusBorder').patch({
      texture: null,
    });
  }

  // _handleDown() {
  //   this.fireAncestors('$incrementSidebar');
  // }

  // _handleUp() {
  //   this.fireAncestors('$decrementSidebar');
  // }

  _handleEnter() {
    Router.navigate(this._item.path);
    Router.focusPage('MyListPage');
  }

  _firstEnable() {
    this._Image.patch({
      x: 20,
      texture: lng.Tools.getSvgTexture(
        Utils.asset(this._item.image),
        this._item.width,
        this._item.height
      ),
    });
    this._Text.patch({
      x: 60,
      text: {
        text: this._item.title,
        fontSize: 30,
      },
    });
  }
}
