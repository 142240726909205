import videojs from 'video.js';

class VideojsPlayerManager {
  constructor() {
    this._player = null;
    this._loadingPromise = null;
  }

  async _setupVideojsPlayer(videoEl, options) {
    this._player = videojs(
      videoEl,
      {
        html5: {
          nativeCaptions: false,
          nativeTextTracks: false,
          hls: {
            handleManifestRedirects: true,
          },
          // vhs: {
          //   withCredentials: true, // Postavi withCredentials na true za sve XHR zahtjeve manifesta i segmenata
          //   overrideNative: true, // Pokušaj koristiti videojs-http-streaming čak i na platformama koje pružaju neku razinu podrške za HLS
          //   enableLowInitialPlaylist: true, // Omogući odabir najniže bitrate liste za početak reprodukcije
          //   limitRenditionByPlayerDimensions: true, // Uzmi u obzir dimenzije player-a pri odabiru rendicije
          //   parse708captions: false, // Ne parsiraj 708 titlove u toku stream-a
          //   useDevicePixelRatio: true, // Uzmi u obzir odnos piksela uređaja pri odabiru rendicije
          //   useCueTags: false, // Ne koristi oznake za reklame ako ih ne koristite
          //   playlistExclusionDuration: 10, // Isključi listu na 10 sekundi ako dođe do greške
          //   maxPlaylistRetries: 2, // Maksimalan broj pokušaja učitavanja playliste prije nego što se trajno isključi
          //   bandwidth: 4000000, // Postavi početnu procijenjenu širinu pojasa
          // },
        },
      },
      function onPlayerReady() {
        console.log('Your player is ready!');
        this.play();
        this.on('ended', () => {
          console.log('Awww...over so soon?!');
        });
      }
    );
  }

  async loadPlayback(url, videoEl, options) {
    if (!this._player) {
      console.log('Creating new Video.js player instance');
      await this._setupVideojsPlayer(videoEl, options);
    }

    if (this._loadingPromise) {
      console.warn('Playback is already being loaded');
      await this._loadingPromise;
      return;
    }

    console.log('Loading playback with URL:', url);
    this._loadingPromise = new Promise((resolve, reject) => {
      this._player.src({
        src: url,
        type: 'application/x-mpegURL',
        withCredentials: true,
      });
      this._player.ready(() => {
        console.log('Playback loaded');
        resolve();
      });
      this._player.on('error', (error) => {
        console.error('Error loading playback:', error);
        reject(error);
      });
    });

    await this._loadingPromise;
    this._loadingPromise = null;
  }

  async unloadPlayback() {
    if (!this._player) {
      console.warn('No playback loaded');
      return;
    }

    if (this._loadingPromise) {
      console.warn('Playback is being loaded, cannot unload now');
      await this._loadingPromise;
      return;
    }

    console.log('Unloading playback');
    this._player.dispose();
    this._player = null;
  }
}

const videojsPlayerManager = new VideojsPlayerManager();

export const loader = async (url, videoEl, options) => {
  console.log('Loading playback with URL:', url);
  await videojsPlayerManager.loadPlayback(url, videoEl, options);
};

export const unloader = async () => {
  console.log('Unloading playback');
  await videojsPlayerManager.unloadPlayback();
};
