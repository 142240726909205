import lng from '@lightningjs/core';
import { Colors, VideoPlayer } from '@lightningjs/sdk';
import { Row } from '@lightningjs/ui-components';

// Components
import PlayerButton from './PlayerButton';
import ProgressBar from './ProgressBar';

const ListOfButtons = [
  {
    id: 1,
    icon: '/icons/iz2.SVG',
    title: 'Backward',
    width: 90,
    height: 90,
    iconWidth: 100,
    iconHeight: 100,
  },
  {
    id: 2,
    icon: '/images/pause.svg',
    title: 'Pause',
    width: 120,
    height: 120,
    iconWidth: 70,
    iconHeight: 70,
  },
  {
    id: 3,
    icon: '/icons/der1.SVG',
    title: 'Forward',
    width: 90,
    height: 90,
    iconWidth: 100,
    iconHeight: 100,
  },
];
export default class VODProgressContainer extends lng.Component {
  constructor(props) {
    super(props);
    this._targetProgressBarWidth = 0;
  }

  static _template() {
    return {
      y: 830,
      w: 1920,
      h: 250,
      rect: true,
      color: Colors('#000000').alpha(0.6).get(),
      visible: false,
      ProgressBar: {
        collision: true,
        type: ProgressBar,
      },
      PlayerOption: {
        w: 1920,
        h: 250,
        flex: { direction: 'row', justifyContent: 'space-around' },
        VODTitle: {
          x: 20,
          y: 30,
          w: 800,
          text: { fontSize: 35, fontStyle: ' normal bold' },
        },
        Buttons: {
          type: Row,
          collision: true,
          y: 10,
          w: 420,
          h: 240,
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          items: ListOfButtons.map((buttonInfo, index) => ({
            type: PlayerButton,
            index: index,
            buttonInfo,
            flag: 'controls',
          })),
        },
        VODTime: {
          w: 800,
          flex: { direction: 'row', justifyContent: 'flex-end' },
          Time: {
            x: -20,
            y: 30,
            text: { text: '00:00:00 / 00:00:00', fontSize: 35, fontStyle: ' normal bold' },
          },
        },
      },
      SubtitleModal: {
        x: 1710,
        w: 60,
        h: 250,
        collision: true,
        flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
        SubtitleButton: {
          w: 90,
          h: 90,
          type: PlayerButton,
          flag: 'subtitle',
          buttonInfo: {
            id: 1,
            icon: '/images/translate.svg',
            title: 'Subtitle',
            width: 90,
            height: 90,
            iconWidth: 50,
            iconHeight: 50,
          },
        },
      },
    };
  }

  set data(val) {
    this._data = val;
  }

  set flag(val) {
    this._flag = val;
  }

  get _VODTitle() {
    return this.tag('VODTitle');
  }

  get _VODTime() {
    return this.tag('VODTime.Time');
  }

  get _Buttons() {
    return this.tag('Buttons');
  }

  get _ProgressBarIndicator() {
    return this.tag('ProgressBar.ProgressBarIndicator');
  }

  get _SubtitleModal() {
    return this.tag('SubtitleModal.SubtitleButton');
  }

  get _ProgressBar() {
    return this.tag('ProgressBar');
  }

  _active() {
    this._setState('Buttons');
  }

  set showSubtitle(val) {
    this._showSubtitle = val;
  }

  $setFocus({ index, flag }) {
    if (flag == 'controls') {
      this._Buttons.selectedIndex = index;
      this._setState('Buttons');
    } else if (flag == 'subtitle') {
      this._setState('SubtitleModal');
    } else if (flag == 'progressIndicator') {
      this._setState('ProgressBarIndicator');
    }
  }

  $preventHideProgressBar() {
    clearInterval(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.fireAncestors('$hideProgressBar');
    }, 3000);
  }

  $updateCurrentTimeAndProgressBar() {
    const currentTime = this._formatTime(VideoPlayer.currentTime);
    const duration = this._formatTime(VideoPlayer.duration);
    this.tag('VODTime').patch({
      Time: undefined,
    });
    this.tag('VODTime').patch({
      Time: {
        x: -20,
        y: 30,
        text: { text: `${currentTime} / ${duration}`, fontSize: 35, fontStyle: ' normal bold' },
      },
    });

    this._targetProgressBarWidth = (VideoPlayer.currentTime / VideoPlayer.duration) * 1920;
    this._updateProgressBar();
  }

  _formatTime(seconds) {
    const padStart2 = (num) => String(num).padStart(2, '0');
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${padStart2(hours)}:${padStart2(minutes)}:${padStart2(remainingSeconds)}`;
  }

  _updateProgressBar() {
    const roundedWidth = Math.round(this._targetProgressBarWidth);
    // if (roundedWidth % 4 === 0) {
    this._ProgressBarIndicator.setSmooth('x', roundedWidth - 20);
    this.tag('ProgressBar.ProgressBar').setSmooth('w', roundedWidth);
    // }
  }

  $updateCurrentTime() {
    this.intervalId = setInterval(() => {
      const currentTime = this._formatTime(VideoPlayer.currentTime);
      const duration = this._formatTime(VideoPlayer.duration);

      this.tag('VODTime').patch({
        Time: undefined,
      });
      this.tag('VODTime').patch({
        Time: {
          x: -20,
          y: 30,
          text: { text: `${currentTime} / ${duration}`, fontSize: 35, fontStyle: ' normal bold' },
        },
      });

      // this._VODTime.patch({ text: `${currentTime} / ${duration}` });

      this._targetProgressBarWidth = (VideoPlayer.currentTime / VideoPlayer.duration) * 1920;
      this._updateProgressBar();
    }, 1000);
  }

  $clearInterval() {
    clearInterval(this.intervalId);
  }

  _enable() {
    if (this._flag === 'trailer' || this._showSubtitle == false) {
      this.tag('SubtitleModal').patch({ visible: false });
    } else {
      this.tag('SubtitleModal').patch({ visible: true });
    }
    this.$clearInterval();
    this.$updateCurrentTime();
    this._VODTitle.patch({ text: `${this._data.stream_title || this._data.title}` });
  }

  static _states() {
    return [
      class Buttons extends this {
        _getFocused() {
          return this._Buttons;
        }

        _handleUp() {
          this._setState('ProgressBarIndicator');
        }

        _handleRight() {
          if (this._flag !== 'trailer' && this._showSubtitle == true) {
            this._setState('SubtitleModal');
          }
        }
      },

      class ProgressBarIndicator extends this {
        _getFocused() {
          return this._ProgressBarIndicator;
        }

        _handleDown() {
          this._setState('Buttons');
        }
      },

      class SubtitleModal extends this {
        _getFocused() {
          return this._SubtitleModal;
        }

        _handleLeft() {
          this._setState('Buttons');
        }

        _handleUp() {
          this._setState('ProgressBarIndicator');
        }
      },
    ];
  }
}
