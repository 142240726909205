import request from '../index';
export const getSeries = (languageId = 0, countryId = 0) =>
  request({
    url: `/tvseries/list/${languageId}/${countryId}`,
    method: 'GET',
  });

export const getSerieDetails = (contentId, contentTypeId) =>
  request({
    url: `/contents/details/${contentTypeId}/${contentId}`,
    method: 'GET',
  });
