import lng from '@lightningjs/core';
import { Colors, Lightning, Router, Utils } from '@lightningjs/sdk';
import SidebarItems from './SidebarItems/index.js';

export default class Sidebar extends Lightning.Component {
  static _template() {
    return {
      collision: true,
      w: 120,
      h: 1080,
      rect: true,
      color: Colors('#282828').get(),
      clipping: true,
      zIndex: 100,
      Logo: {
        w: 120,
        flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
        Icon: {
          y: 30,
          rect: true,
          texture: lng.Tools.getSvgTexture(Utils.asset('/icons/zeentv-sidebar-icon.svg'), 50, 50),
        },
        w: 120,
      },
      Menu: {
        Items: {
          // collision: true,
          type: SidebarItems,
          // color: Colors('#000000').get(),
          // rect: true,
          h: 1080,
          w: 300,
          y: 200,
          x: 20,
        },
      },
    };
  }

  _handleHover() {
    this.smooth = { w: 300 };
    this.tag('Logo').smooth = { w: 300 };
    Router.focusWidget('sidebar');
    return false;
  }

  _handleUnhover() {
    this._unfocus();
    Router.focusPage();
    return false;
  }

  _focus() {
    console.log('SIDEBAR CONTAINER FOCUSED');
    this.tag('Items')._active();
    this.smooth = { w: 300 };
    this.tag('Logo').smooth = { w: 300 };
  }

  _unfocus() {
    console.log('SIDEBAR CONTAINER UNFOCUSED');
    this.smooth = { w: 120 };
    this.tag('Logo').smooth = { w: 120 };
  }

  _handleLeft() {
    return true;
  }

  _handleUp() {
    return true;
  }

  _handleDown() {
    return true;
  }

  _getFocused() {
    return this.tag('Items');
  }
}
