import request from '../index';

export const getQuality = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const subscriptionId = user?.subscription.id;
  const profileId = user?.profiles[0].id;

  return request({
    url: `/settings/quality/get/${subscriptionId}/${profileId}/2`,
    method: 'GET',
  });
};

export const resetQuality = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const subscriptionId = user?.subscription.id;
  const profileId = user?.profiles[0].id;

  return request({
    url: `/settings/quality/get/${subscriptionId}/${profileId}/2`,
    method: 'GET',
  });
};

export const setQuality = (quality) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const subscriptionId = user?.subscription.id;
  const profileId = user?.profiles[0].id;

  return request({
    url: `/settings/quality/set/${quality}/${subscriptionId}/${profileId}/2`,
    method: 'GET',
  });
};

export const transfer = (sub) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const subscriptionId = user?.subscription.id;
  const profileId = user?.profiles[0].id;

  return request({
    url: `/start/transfer/${sub}`,
    method: 'GET',
  });
};

export const getPassword = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const subscriptionId = user?.subscription.id;
  const profileId = user?.profiles[0].id;

  return request({
    url: `/settings/password/get/${subscriptionId}/${profileId}/2`,
    method: 'GET',
  });
};

export const setPassword = (password) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const subscriptionId = user?.subscription.id;
  const profileId = user?.profiles[0].id;

  return request({
    url: `/settings/password/set/${password}/${subscriptionId}/${profileId}/2`,
    method: 'GET',
  });
};

export const getLock = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const subscriptionId = user?.subscription.id;
  const profileId = user?.profiles[0].id;

  return request({
    url: `/settings/lock/get/${subscriptionId}/${profileId}/2`,
    method: 'GET',
  });
};

export const getLockk = (subscription, userProfile) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const subscriptionId = user?.subscription.id;
  const profileId = user?.profiles[0].id;

  return request({
    url: `/settings/lock/get/${subscription}/${userProfile}/2`,
    method: 'GET',
  });
};

export const setLock = (contentId) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const subscriptionId = user?.subscription.id;
  const profileId = user?.profiles[0].id;

  return request({
    url: `/settings/lock/set/${contentId}/${subscriptionId}/${profileId}/2`,
    method: 'GET',
  });
};

export const delLock = (contentId) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const subscriptionId = user?.subscription.id;
  const profileId = user?.profiles[0].id;

  return request({
    url: `/settings/lock/del/${contentId}/${subscriptionId}/${profileId}/2`,
    method: 'GET',
  });
};
