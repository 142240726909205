// Components
import LiveChannelsButton from '../components/Buttons/LiveChannelsButton';
import ChannelListButton from '../components/Buttons/ChannelListButton';

// API Calls
import { getIndex } from '../../../api/index/index';
import { getLiveList } from '../../../api/liveList/index';
import { getLock } from '../../../api/settings/index';
import { getMyList } from '../../../api/myList/index';
import { getAccess } from '../../../api/activation/index';

export default async function initializePage(page) {
  const access = await getAccess();
  if (access) {
    localStorage.setItem('user', JSON.stringify(access.data));
  }
  const languagesDictionary = await fetchLiveChannelsData();
  const languages = languagesDictionary.map((item, index) => ({
    type: LiveChannelsButton,
    collision: true,
    buttonTitle: item.title,
    onEnter: '$languageChange',
    onEnterProps: index,
  }));

  const categories = languagesDictionary[0].categories.map((item, index) => ({
    type: LiveChannelsButton,
    collision: true,
    buttonTitle: item.title,
    onEnter: '$categoryChange',
    onEnterProps: index,
  }));
  const lockedChannels = await getLock();
  const favouriteList = await getMyList();
  const liveChannels = languagesDictionary[0].categories[0].channels.map((item, index) => ({
    type: ChannelListButton,
    w: 500,
    h: 65,
    collision: true,
    buttonInfo: item,
    index,
    isFavourite: favouriteList.data.includes(item.content_id.toString()) ? true : false,
    isLocked: lockedChannels.data.includes(item.content_id) ? true : false,
  }));
  page._MenuSection._ChannelListSection.channels = languagesDictionary[0].categories[0].channels;
  page._initPage(languagesDictionary, languages, categories, liveChannels);
}

async function fetchLiveChannelsData() {
  const { data: indexData } = await getIndex(); //Fetch index JSON
  const index = indexData.index;
  const { groups, genres } = index;
  const { data: liveData } = await getLiveList(); // Fetch live list JSON
  const channels = liveData.live;
  const favoriteData = await getMyList();
  const languagesDictionary = [
    {
      title: 'All',
      categories: filterChannels('language_id', undefined, genres, channels, favoriteData),
    },
  ];
  groups.live.map((item) => {
    switch (item.item_type) {
      case 1:
        // Language case
        let language = index.languages.find((l) => l.id === item.item_id);
        languagesDictionary.push({
          title: language.title,
          categories: filterChannels('language_id', language.id, genres, channels, favoriteData),
        });
        break;
      case 2:
        // Country case
        let country = index.countries.find((c) => c.id === item.item_id);
        languagesDictionary.push({
          title: country.title,
          categories: filterChannels('country_id', country.id, genres, channels, favoriteData),
        });
        break;
      case 3:
        // Department case
        let department = index.departments.find((d) => d.id === item.item_id);
        languagesDictionary.push({
          title: department.title,
          categories: filterChannels(
            'department_id',
            department.id,
            genres,
            channels,
            favoriteData
          ),
        });
        break;
      default:
    }
  });
  // Remove all channels that have 0 categories, meaning they do not have channels
  return languagesDictionary.filter((language) => language.categories.length > 0);
}

// Function that helps us filter all channels by a specific field, and groups them into respective categories
function filterChannels(field, id, genres, channels, favoriteData) {
  return channels
    .filter((channel) => (id ? channel[field] === id : true)) // Get only channels that match the current Language/Category/Department
    .reduce((acc, channel) => {
      const channelCategory = genres.find((g) => g.id == channel.genre_id); // Find genre details
      if (channelCategory && channelCategory.title !== 'Adult') {
        // Add condition to exclude 'Adult' genre
        const allCategory = acc.find((item) => item.title === 'All');
        if (!allCategory) {
          channel.channelNumber = 1;
          acc.push({ title: 'All', channels: [channel] });
        } else {
          channel.channelNumber = allCategory.channels.length + 1;
          allCategory.channels.push(channel);
        }
        //
        // if (Array.isArray(favoriteData.data)) {
        //   if (favoriteData.data.some((favChannel) => +favChannel === channel.content_id)) {
        //     const categoryFavourites = acc.find((item) => item.title === 'Favourites');
        //     channel.setNewFavourite = true;
        //     if (!categoryFavourites) {
        //       acc.push({ title: 'Favourites', channels: [channel] });
        //     } else {
        //       categoryFavourites.channels.push(channel);
        //     }
        //   }
        // } else {
        //   console.log('fired here');
        //   acc.push({ title: 'Favourites', channels: [] });
        //   console.error('favoriteData.data is not an array');
        //   // Handle this case as appropriate
        // }
        //
        const categoryFavourites = acc.find((item) => item.title === 'Favourites');
        if (!categoryFavourites) {
          acc.push({ title: 'Favourites', channels: [] });
        }
        const existingCategory = acc.find((item) => item.title === channelCategory.title);
        if (!existingCategory) {
          // If it is a first category of this type, do initial push
          acc.push({ title: channelCategory.title, channels: [channel] });
        } else {
          // Else add on to existing array
          existingCategory.channels.push(channel);
        }
      }
      return acc;
    }, []);
}
