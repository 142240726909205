import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';

export default class SourceModal extends lng.Component {
  static _template() {
    return {
      x: 1420,
      y: 20,
      w: 500,
      h: 790,
      collision: true,
      color: Colors('#000000').alpha(0.6).get(),
      rect: true,
      clipping: true,
      Column: {
        type: Column,
        collision: true,
      },
    };
  }

  $setFocus(index) {
    this._Column.scrollTo(index, 50);
  }

  _handleScroll() {
    let currentIndex = this._Column.selectedIndex;
    let nextIndex = currentIndex + 1;
    let previousIndex = currentIndex - 1;
    // Inkrementiranje ili dekrementiranje lorem.x za 400, ovisno o smjeru skroliranja
    if (event.deltaY > 0) {
      this._Column.scrollTo(nextIndex, 50);
      // lorem.x -= 400;
    } else if (event.deltaY < 0) {
      console.log('Rotacija prema gore');
      // lorem.x += 400;
      this._Column.scrollTo(previousIndex, 50);
    } else {
      console.log('Nema vertikalne rotacije');
    }
  }

  _getFocused() {
    return this._Column;
  }

  _handleHover() {
    return false;
  }

  get _Column() {
    return this.tag('Column');
  }

  set content_id(val) {
    this._content_id = val;
  }
  set stream_id(val) {
    this._stream_id = val;
  }
  set content_type(val) {
    this._content_type = val;
  }
}
