import { Colors, Router, Utils } from '@lightningjs/sdk';
import lng from '@lightningjs/core';
import { Marquee } from '@lightningjs/ui-components';

export default class VODComponent extends lng.Component {
  constructor(props) {
    super(props);
    // Timeout that will fire the marquee animation if the user is on one channel longer than a second
    this._marqueeTimeout = undefined;
    this._selected = false;
  }
  static _template() {
    return {
      Button: {
        // collision: true,
        Item: {
          collision: true,
          flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
          Image: {},
          Info: {
            flex: { direction: 'row', justifyContent: 'space-around', alignItems: 'center' },
            Year: {
              y: 5,
              text: {
                fontSize: 30,
                lineHeight: 1,
                textAlign: 'center',
              },
            },
            ImdbScore: {
              flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
              Icon: {
                texture: lng.Tools.getSvgTexture(Utils.asset('/icons/imdb.SVG'), 80, 80),
              },
              Score: {
                x: 5,
                y: 5,
                text: {
                  fontSize: 30,
                  lineHeight: 1,
                  textAlign: 'center',
                  fontStyle: 'normal bold',
                },
              },
            },
          },
          Title: {
            type: Marquee,
            x: 10,
            w: 250,
            text: {
              fontSize: 30,
              lineHeight: 1,
              textAlign: 'left',
              fontStyle: 'normal bold',
              textColor: Colors('#ffffff').get(),
            },
          },
        },
        FocusRing: {
          alpha: 0,
        },
      },
    };
  }
  _construct() {
    super._construct();
    this.spacing = 10;
  }

  set index(val) {
    this._index = val;
  }

  set rowIndex(val) {
    this._rowIndex = val;
  }

  set data(val) {
    this._data = val;
  }
  get data() {
    return this._data;
  }
  get _Image() {
    return this.tag('Item.Image');
  }
  get _FocusRing() {
    return this.tag('FocusRing');
  }
  get _Title() {
    return this.tag('Title');
  }
  get _Item() {
    return this.tag('Item');
  }

  get _Year() {
    return this.tag('Year');
  }
  get _Score() {
    return this.tag('Score');
  }

  get _Info() {
    return this.tag('Info');
  }

  _handleHover(target) {
    this.fireAncestors('$unselectCurrentIndex');
    this.fireAncestors('$setFocus', { rowIndex: this._rowIndex, index: this._index });
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._captureEnter();
  }

  _handleScroll(event) {
    this.fireAncestors('$scroll', event);
    this._unfocus();
  }

  _firstEnable() {
    this._Title.patch({
      title: {
        fontSize: 30,
        textAlign: 'left',
        // wordWrapWidth: this.w - this.spacing,
        maxLines: 1,
        text: this._data.title,
      },
    });
    this._Info.patch({
      w: this.w,
      h: 80,
    });
    this._Year.patch({
      text: {
        text: this._data.year,
      },
    });
    this._Score.patch({
      text: {
        text: this._data.imdb_score,
      },
    });
    this._FocusRing.patch({
      x: this.w / 2,
      y: this.h / 2,
      w: this.w + this.spacing - 2,
      h: this.h + this.spacing - 2,
      mount: 0.5,
      // zIndex: 2,
      texture: lng.Tools.getRoundRect(
        this.w + this.spacing,
        this.h + this.spacing,
        0,
        7,
        Colors('#ffffff').get(),
        false
      ),
    });
    this._Image.patch({
      w: this.w,
      h: this.h - 150,
      rtt: true,
      // zIndex: 2,
      texture: {
        type: lng.textures.ImageTexture,
        src: this._data.image_url,
        resizeMode: { type: 'contain', w: this.w, h: this.h - 150 },
      },
    });
  }
  _captureEnter() {
    Router.navigate(`${this._data.flag}/${this._data.content_id}`, {
      genres: this._data.genres,
      language: this._data.language,
    });
  }

  _focus() {
    this._FocusRing.smooth = { alpha: 1, scale: 1.04 };
    this._Item.smooth = { scale: 1.04 };
    this._marqueeTimeout = setTimeout(() => {
      this.tag('Title').patch({
        autoStart: true,
        repeat: -1,
      });
    }, 1200);
  }
  _unfocus() {
    this._FocusRing.smooth = { alpha: 0, scale: 1 };
    this._Item.smooth = { scale: 1 };
    clearTimeout(this._marqueeTimeout);
    this.tag('Title').patch({
      autoStart: false,
      repeat: 0,
    });
  }
}
