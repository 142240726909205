import lightning from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

const FILTER_BORDER_RADIUS = 5;

export default class LiveChannelsButton extends lightning.Component {
  constructor(props) {
    super(props);
    // Timeout that will fire the marquee animation if the user is on one channel longer than a second
    this._selected = false;
  }

  static _template() {
    return {
      collision: true,
      w: 350,
      h: 65,
      flex: { direction: 'row', justifyContent: 'center' },
      Button: {
        clipping: true,
        flex: { direction: 'row', alignItems: 'center' },
        ContentTitle: { x: 10, alignSelf: 'center' },
      },
    };
  }

  set buttonTitle(value) {
    this._buttonTitle = value;
  }

  set onEnterProps(value) {
    this._onEnterProps = value;
  }

  set onEnter(value) {
    this._onEnter = value;
  }

  get _Button() {
    return this.tag('Button');
  }

  get _ContentTitle() {
    return this.tag('ContentTitle');
  }

  _selectButton() {
    this._selected = true;
  }

  _unselectButton() {
    this._selected = false;
    this._Button.patch({
      texture: null,
    });
  }

  _enable() {
    this.patch({ w: this.w, h: this.h });
    this._Button.patch({ w: this.w - 50, h: this.h });
    this._ContentTitle.patch({
      text: { text: this._buttonTitle },
    });
  }

  _focus() {
    this._Button.patch({
      texture: lightning.Tools.getRoundRect(
        this.w,
        this.h,
        FILTER_BORDER_RADIUS,
        FILTER_BORDER_RADIUS,
        Colors('#C40000').get(),
        true,
        Colors('#C40000').get()
      ),
    });
  }

  _unfocus() {
    if (this._selected)
      this._Button.patch({
        texture: lightning.Tools.getRoundRect(
          this.w,
          this.h,
          FILTER_BORDER_RADIUS,
          FILTER_BORDER_RADIUS,
          Colors('#C40000').get(),
          false
        ),
      });
    else
      this._Button.patch({
        texture: null,
      });
  }

  _handleEnter() {
    if (this._buttonTitle == 'Favourites') {
      return this.fireAncestors('$showFavourites', this._onEnterProps);
    }
    this.fireAncestors(this._onEnter, this._onEnterProps);
  }

  _handleHover() {
    this.fireAncestors('$setFocus', this._onEnterProps);
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._handleEnter();
  }
}
