import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';
let itemsCopy;
export default class VODLanguage extends lng.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      rect: true,
      collision: true,
      color: Colors('#000000').get(),
      Language: {
        zIndex: 20,
        w: 1920,
        h: 100,
        color: Colors('#000000').get(),
        rect: true,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        Title: {},
      },
      VODWrapper: {
        w: 1920,
        h: 1000,
        collision: true,
        // Column: {
        //   type: Column,
        //   y: 120,
        //   x: 70,
        //   plinko: true,
        //   lazyUpCount: 2,
        //   lazyUpCountBuffer: 2,
        // },
      },
    };
  }

  set language(val) {
    this._language = val;
  }

  set items(val) {
    this._items = val;
  }

  get _Column() {
    return this.tag('Column');
  }

  get _Language() {
    return this.tag('Language.Title');
  }

  _enable() {
    itemsCopy = [...this._items];
    this.tag('VODWrapper').patch({
      Column: {
        type: Column,
        y: 120,
        x: 70,
        plinko: true,
        lazyUpCount: 2,
        collision: true,
        lazyUpCountBuffer: 2,
        items: itemsCopy,
      },
    });
    this._Language.patch({
      text: { text: this._language },
    });
  }

  _handleScroll(event, target) {
    let lorem = this._Column; // Ako koristite target, pretpostavka je da je _Episodes svojstvo ciljnog objekta
    let currentIndex = this._Column.selectedIndex;
    let nextIndex = currentIndex + 1;
    let previousIndex = currentIndex - 1;
    if (event.deltaY > 0) {
      this._Column.scrollTo(nextIndex, 50);
    } else if (event.deltaY < 0) {
      console.log('Rotacija prema gore');
      this._Column.scrollTo(previousIndex, 50);
    } else {
      console.log('Nema vertikalne rotacije');
    }
  }

  $scroll(event) {
    this._handleScroll(event);
    this._unfocus();
  }

  $setFocus({ rowIndex, index }) {
    let modifiedIndex = index % 6;
    // this._Column.selectedIndex = rowIndex;
    this._Column.scrollTo(rowIndex, 400);
    this._Column.items[rowIndex].selectedIndex = modifiedIndex;
  }

  $unselectCurrentIndex() {
    let currentIndex = this._Column.selectedIndex;
    let rowIndex = (this._Column.items[currentIndex].selectedIndex = undefined);
    // this._Column.items[currentIndex].items[rowIndex]._unfocus();
  }

  _getFocused() {
    return this._Column;
  }

  _disable() {
    this.tag('VODWrapper').patch({
      Column: undefined,
    });
  }
}
