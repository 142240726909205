import { setTvInfo, createAxiosInstance, tvInfo } from '../api';
import { getTime } from '../api/activation/index';
import { generateRAuth } from '../api/xAuth';

// Check Device
export const getDevice = () => {
  if (window.tizen) {
    return 'tizen';
  } else if (window.webos) {
    return 'webos';
  } else if (window.Hisense) {
    return 'hisense';
  }
  return 'web';
};

function uuidToMac(uuid) {
  var uuidWithoutHyphens = uuid.replace(/-/g, '');
  var macString = uuidWithoutHyphens.substring(0, 12);
  var macAddress = macString.match(/.{1,2}/g).join(':');
  return macAddress;
}

function getMACAndSN(UUID) {
  var macAdresa = UUID.substring(0, 12);
  macAdresa = macAdresa.match(/.{2}/g).join(':');
  var serijskiBroj = UUID.substring(12);
  return {
    macAdresa: macAdresa,
    serijskiBroj: serijskiBroj,
  };
}

export const getTVInfo = () => {
  const device = getDevice();
  switch (device) {
    case 'tizen':
      var _serialNumber = webapis.productinfo.getDuid();
      var _model = webapis.productinfo.getModel();
      var _brand = 'samsung';
      var _macAddress = webapis.network.getMac();
      setTvInfo({ mac: _macAddress, sn: _serialNumber, brand: _brand, model: _model });
      createAxiosInstance();
      break;
    case 'webos':
      var _model;
      var _brand;
      var _serialNumber;
      var _macAddress;
      console.group('WEBOS LOG');
      console.log('WEBOS', webOS);
      webOS.deviceInfo((device) => {
        console.log('LG MODEL', device.modelName);
        console.log('BRAND', device.brandName);
        _model = device.modelName;
        _brand = device.brandName;
      });

      // Get uuid
      webOS.service.request('luna://com.webos.service.sm', {
        method: 'deviceid/getIDs',
        parameters: {
          idType: ['LGUDID'],
        },
        onSuccess: function (inResponse) {
          console.log('UUID' + inResponse.idList[0].idValue);
          _macAddress = uuidToMac(inResponse.idList[0].idValue);
          console.log('MAC ADDRESS', _macAddress);
          // Call request for get SN
          webOS.service.request('luna://com.webos.service.tv.systemproperty', {
            method: 'getSystemInfo',
            parameters: { keys: ['serialNumber'] },
            onSuccess: function (result) {
              console.log('Serial Number:', result.serialNumber);
              _serialNumber = result.serialNumber;
              setTvInfo({ mac: _macAddress, sn: _serialNumber, brand: _brand, model: _model });
              createAxiosInstance();
            },
            onFailure: function (error) {
              console.error('Failed to get serial number:', error);
            },
          });
        },
        onFailure: function (inError) {
          console.log('Failed to get system ID information');
          console.log('[' + inError.errorCode + ']: ' + inError.errorText);
        },
      });
      console.groupEnd();
      break;
    case 'hisense':
      const UUID = window.Hisense_GetDeviceInfo().uuid;
      console.log('HISENSEINFO:', window.Hisense_GetDeviceInfo().brand);
      const info = getMACAndSN(UUID);
      _model = window.Hisense_GetDeviceInfo().model_name;
      _macAddress = info.macAdresa;
      _serialNumber = info.serijskiBroj;
      _brand = window.Hisense_GetDeviceInfo().brand;
      setTvInfo({ mac: _macAddress, sn: _serialNumber, brand: _brand, model: _model });
      createAxiosInstance();
      break;
    case 'web':
      // _macAddress = '54:44:a3:21:8b:ad';
      // _serialNumber = '6NMJ4ISLTHM5Z';

      // NEW LG (MITA OFFICE)
      _macAddress = '01:8f:db:f2:3f:9d';
      _serialNumber = '311MAMBJ2731';

      //GPT RANDOM
      // _macAddress = '3A:56:B7:89:5C:12';
      // _serialNumber = '9XHY7JMWQK34F';

      _brand = 'PC';
      _model = 'WEB';
      setTvInfo({ mac: _macAddress, sn: _serialNumber, brand: _brand, model: _model });
      createAxiosInstance();
      break;
  }

  //Digital Signane
  // mac: 54:44:a3:21:8b:ad
  // sn: 6NMJ4ISLTHM5Z

  // mac: _macAddress || '14:42:56:94:46:63',
  // sn: _serialNumber || '989552c3198e',
  // const data = {
  //   mac: _macAddress,
  //   sn: _serialNumber,
  //   brand: _brand || 'Samsung',
  //   model: _model || 'UGC9008',
  // };
  // return data;
};

export const getToken = async () => {
  const { mac, sn } = tvInfo;
  const time = await getTime();
  var modifiedMAC = mac.replace(/:/g, '');
  let token = generateRAuth(modifiedMAC, sn, time.unix);
  return token;
};
