import lng from '@lightningjs/core';
import { Router, VideoPlayer } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';

// Components
import VODInfo from './components/VODInfo';
import { loader, unloader } from '../../components/Player/HLS';
import VODComponent from './components/VODComponent';
import VODRow from './components/VODRow';
import ShowMoreCard from './components/ShowMoreCard';
export default class Movies extends lng.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      Content: {
        x: 150,
        y: 0,
        h: 500,
        w: 1720,
        type: VODInfo,
      },

      Wrapper: {
        x: 110,
        y: 520,
        w: 1810,
        h: 600,
        clipping: true,
        Column: {
          x: 41,
          y: 20,
          type: Column,
          collision: true,
        },
      },
    };
  }
  // _enable() {
  //   console.log(this._Column.items[0].title);
  //   let movies = JSON.parse(localStorage.getItem('movies')) || [];
  //   if (this._Column.items[0].title != 'Continue to Watch' && movies.length > 0) {
  //     const items = movies.map((movie) => ({
  //       type: VODComponent,
  //       w: 255,
  //       h: 400,
  //       data: movie,
  //       language: 'Continue to Watch',
  //       flag: 'movies',
  //     }));
  //     let row = [
  //       {
  //         type: VODRow,
  //         h: 550,
  //         x: 20,
  //         y: 80,
  //         title: 'Continue to Watch',
  //         items: items,
  //         scrollIndex: 3,
  //         style: {
  //           itemSpacing: 10,
  //         },
  //       },
  //     ];
  //     this._Column.items.unshift(row);
  //     return;
  //   }
  //   if (this._Column.items[0].title == 'Continue to Watch') {
  //     this._Column.items[0].items = undefined;
  //     console.log(movies);
  //     const items = movies.map((movie) => ({
  //       type: VODComponent,
  //       w: 255,
  //       h: 400,
  //       data: movie,
  //       language: 'Continue to Watch',
  //       flag: 'movies',
  //     }));
  //     let row = [
  //       {
  //         type: VODRow,
  //         h: 550,
  //         x: 20,
  //         y: 80,
  //         title: 'Continue to Watch',
  //         items: items,
  //         scrollIndex: 3,
  //         style: {
  //           itemSpacing: 10,
  //         },
  //       },
  //     ];
  //     this._Column.items[0].items = items;
  //   }

  //   if (this._Column.items[0].title == 'Continue to Watch' && (movies == [] || movies == false)) {
  //     console.log('fired here');
  //     this._Column.items[0] = undefined;
  //   }
  //   //END MOJE PROBE
  //   VideoPlayer.position(0, 720);
  //   VideoPlayer.size(1200, 675);
  //   VideoPlayer.consumer(this);
  //   VideoPlayer.loader(loader);
  //   VideoPlayer.unloader(unloader);
  // }

  _handleScroll(event, target) {
    let lorem = this._Column; // Ako koristite target, pretpostavka je da je _Episodes svojstvo ciljnog objekta
    let currentIndex = this._Column.selectedIndex;
    let nextIndex = currentIndex + 1;
    let previousIndex = currentIndex - 1;
    // Inkrementiranje ili dekrementiranje lorem.x za 400, ovisno o smjeru skroliranja
    if (event.deltaY > 0) {
      this._Column.scrollTo(nextIndex, 50);
      // lorem.x -= 400;
    } else if (event.deltaY < 0) {
      console.log('Rotacija prema gore');
      // lorem.x += 400;
      this._Column.scrollTo(previousIndex, 50);
    } else {
      console.log('Nema vertikalne rotacije');
    }
  }

  $moveVOD(event) {
    // let currentIndex = this._Column.selectedIndex;
    // let rowIndex = this._Column.items[currentIndex].selectedIndex;

    // let nextIndex = rowIndex + 1;
    // let previousIndex = rowIndex - 1;
    // // Inkrementiranje ili dekrementiranje lorem.x za 400, ovisno o smjeru skroliranja
    // if (event.deltaY > 0) {
    //   console.log(this._Column.selected);
    //   this._Column.items[currentIndex].scrollTo(nextIndex, 50);
    //   // lorem.x -= 400;
    // } else if (event.deltaY < 0) {
    //   console.log('Rotacija prema gore');
    //   // lorem.x += 400;
    //   this._Column.items[currentIndex].scrollTo(previousIndex, 50);
    // } else {
    //   console.log('Nema vertikalne rotacije');
    // }
    this._handleScroll(event);
  }
  $unselectCurrentIndex() {
    let currentIndex = this._Column.selectedIndex;
    let rowIndex = this._Column.items[currentIndex].selectedIndex;
    this._Column.items[currentIndex].items[rowIndex]._unfocus();
  }

  _enable() {
    this._updateContinueToWatch();
    // Postavljamo VideoPlayer
    VideoPlayer.position(0, 720);
    VideoPlayer.size(1200, 675);
    VideoPlayer.consumer(this);
    VideoPlayer.loader(loader);
    VideoPlayer.unloader(unloader);
  }

  $setFocus(index) {
    let currentIndex = this._Column.selectedIndex;
    this._Column.items[currentIndex].scrollTo(index, 10);
  }

  _updateContinueToWatch() {
    // Pronalazimo "Continue to Watch" stavku
    const continueToWatchIndex = this._Column.items.findIndex(
      (item) => item.title === 'Continue to Watch'
    );

    // Učitavamo listu filmova iz lokalnog skladišta
    const movies = JSON.parse(localStorage.getItem('movies')) || [];
    const moviesWithoutKids = movies.filter((movie) => !movie.genres.includes('Kids'));
    // Ako imamo "Continue to Watch" red i postoje filmovi
    if (continueToWatchIndex !== -1 && moviesWithoutKids.length > 0) {
      // Ažuriramo "Continue to Watch" red

      const limitedMovies = moviesWithoutKids.slice(0, 19);
      this._Column.removeItemAt(0);
      const items = limitedMovies.map((movie, index) => ({
        type: VODComponent,
        w: 255,
        h: 400,
        data: movie,
        index: index,
        collision: true,
        language: 'Continue to Watch',
        flag: 'movies',
      }));
      const additionalItem =
        moviesWithoutKids.length > 19
          ? {
              type: ShowMoreCard,
              w: 255,
              h: 400,
              language: 'Show More',
              collision: true,
              data: {
                image_url: '/images/see_more.jpg',
                title: 'Show More',
                description: '',
                genres: [],
              },
              fullData: { title: 'Continue to Watch', movies: moviesWithoutKids },
              flag: 'movieslist',
            }
          : null;
      const row = {
        type: VODRow,
        h: 550,
        x: 20,
        y: 80,
        title: 'Continue to Watch',
        collision: true,
        items: [...items, additionalItem].filter(Boolean),
        scrollIndex: 3,
        style: {
          itemSpacing: 10,
        },
      };

      this._Column.prependItems([row]);

      // Ažuriramo postojeći red
      // this._Column.items[continueToWatchIndex].items = items;
      // Ponovno postavljanje stila nakon ažuriranja
    }

    // Ako imamo filmove za "Continue to Watch" i nismo već dodali ovu sekciju
    if (moviesWithoutKids.length > 0 && continueToWatchIndex === -1) {
      const items = moviesWithoutKids.map((movie) => ({
        type: VODComponent,
        w: 255,
        h: 400,
        data: movie,
        language: 'Continue to Watch',
        collision: true,
        flag: 'movies',
      }));
      const row = {
        type: VODRow,
        h: 550,
        x: 20,
        y: 80,
        title: 'Continue to Watch',
        items: items,
        scrollIndex: 3,
        collision: true,
        style: {
          itemSpacing: 10,
        },
      };
      this._Column.prependItems([row]); // Dodajemo "Continue to Watch" sekciju na početak
    }

    // Ako imamo "Continue to Watch" sekciju i nemamo filmove za prikaz
    if (continueToWatchIndex !== -1 && moviesWithoutKids.length === 0) {
      this._Column.items.splice(continueToWatchIndex, 1); // Uklanjamo "Continue to Watch" sekciju
    }
    this._Column.selectedIndex = 0;
  }

  $setTrailer(trailer) {
    this._Content._Cover.visible = false;

    VideoPlayer.loop(true);
    const playerSettings = {
      debug: true,
    };
    VideoPlayer.open(trailer.trailer, playerSettings);
    VideoPlayer.play();
    this.fireAncestors('$punchHoleLiveChannelsPlayer', 720, 0, 1200, 600);
  }

  $stopVideo() {
    VideoPlayer.clear();
  }

  _checkIfLeftToRight(text) {
    var arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(text);
  }

  $changeVODInfo(data) {
    let text = data.description;
    const isLeftToRight = this._checkIfLeftToRight(text);

    if (isLeftToRight) {
      this._Content._Description.text = { text: data.description, textAlign: 'right' };
    } else {
      this._Content._Description.text = { text: data.description, textAlign: 'left' };
    }
    VideoPlayer.clear();
    this.fireAncestors('$unpunchHole');
    this._Content._Description.text = data.description;
    this._Content._Title.text = data.title;
    this._Content._Cover.patch({
      visible: true,
      texture: { src: data.backdrop },
    });
    const allGenres = data?.genres?.join(' • ');
    this._Content._Genre.text = allGenres;
  }

  $videoPlayerError(event) {
    console.log('Error', event);
  }

  $videoPlayerLoadStart(e) {
    console.log(e, 'Load Start');
  }

  $videoPlayerLoadedData(e) {
    console.log(e, 'Loaded Data');
  }

  $videoPlayerPlaying(e) {
    console.log(e, 'Playing');
  }

  get _Content() {
    return this.tag('Content');
  }

  get _Column() {
    return this.tag('Column');
  }

  _disable() {
    VideoPlayer.loader(null);
    VideoPlayer.unloader(null);
    VideoPlayer.clear();
  }

  _getFocused() {
    return this._Column;
  }

  _handleLeft() {
    Router.focusWidget('sidebar');
  }
}
