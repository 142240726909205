import { Colors, Router, Utils, VideoPlayer } from '@lightningjs/sdk';
import lng from '@lightningjs/core';
export default class ShowMoreCard extends lng.Component {
  static _template() {
    return {
      collision: true,
      Item: {
        Background: {
          w: 255,
          h: 400,
          rect: true,
          color: Colors('#000000').get(),
        },
        Image: {
          shader: {
            type: lng.shaders.RoundedRectangle,
            radius: 5,
          },
        },
      },
    };
  }

  _construct() {
    super._construct();
    this.spacing = 10;
    this.radius = 4;
  }

  set data(val) {
    this._data = val;
  }

  set fullData(val) {
    this._fullData = val;
  }

  set language(val) {
    this._language = val;
  }
  set flag(val) {
    this._flag = val;
  }

  get data() {
    return this._data;
  }

  get _Image() {
    return this.tag('Image');
  }

  get _Title() {
    return this.tag('Title');
  }

  get _Item() {
    return this.tag('Item');
  }

  _handleHover() {
    this.fireAncestors('$unselectCurrentIndex');
    this.fireAncestors('$setFocus', 19);
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._captureEnter();
  }

  _handleScroll(event) {
    this.fireAncestors('$moveVOD', event);
  }

  _firstEnable() {
    this.fireAncestors('$changeVODInfo', this._data);
    this._Image.patch({
      w: this.w,
      h: this.h,
      zIndex: 2,
      texture: {
        type: lng.textures.ImageTexture,
        src: Utils.asset(this._data.image_url),
        resizeMode: { type: 'contain', w: this.w, h: this.h },
      },
    });
  }

  _captureEnter() {
    this.fireAncestors('$stopVideo');
    Router.navigate(`${this._flag}/${this._fullData.title}`, {
      data: this._fullData,
    });
  }

  _focus() {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }
    VideoPlayer.clear();
    this.fireAncestors('$unpunchHole');

    // this.fireAncestors('$changeVODInfo', this._data);
    this.zIndex = 101;
    this.tag('Background').smooth = { alpha: 1, scale: 1.04 };
    this._Image.smooth = { scale: 1.1 };
  }

  _unfocus() {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }
    this.zIndex = 0;
    this.tag('Background').smooth = { scale: 1 };
    this._Image.smooth = { scale: 1 };
  }
}
