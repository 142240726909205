import lng from '@lightningjs/core';
import { VideoPlayer } from '@lightningjs/sdk';
import { loader, unloader } from '../../../../components/Player/video';

import TVChannelInfo from './TVChannelInfo/TVChannelInfo';
import { getQuality } from '../../../../api/settings/index';
import TVSpinner from './TVSpinner';
import { getToken } from '../../../../utils/device';
let myStream;
export default class TvPlayer extends lng.Component {
  constructor(props) {
    super(props);
    this._data;
  }
  static _template() {
    return {
      x: 0,
      y: 5,
      LoadingContainer: {
        x: 0,
        y: 0,
        rect: true,
        visible: false,
        color: 0xff000000,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        type: TVSpinner,
      },
      TVChannelInfo: {
        type: TVChannelInfo,
      },
    };
  }

  _construct() {
    this._updateNoiseInterval = undefined;
  }

  async _enable() {
    myStream = await getQuality();
    VideoPlayer.consumer(this);
  }

  _resize(x, y, w, h) {
    // small corrections to cover white background from punching a hole
    VideoPlayer.position(y, x);
    VideoPlayer.size(w, h);
    this.patch({
      w: w,
      h: h,
      LoadingContainer: {
        w: w,
        h: h,
      },
    });
  }

  async _init() {
    await VideoPlayer.consumer(this);
    await VideoPlayer.loader(loader);
    await VideoPlayer.unloader(unloader);
  }

  get data() {
    return this._data;
  }

  async _play(data, index) {
    this._data = data;
    VideoPlayer.clear();
    this.patch({
      LoadingContainer: {
        visible: false,
      },
    });
    this._updateNoiseInterval = setInterval(() => this._updateNoise(), 100);
    const playerSettings = {
      debug: true,
    };
    var streams = data.streams.live;
    var matchingStream = streams.find(function (stream) {
      return stream.stream_title === myStream?.data[0];
    });
    var finalStream = matchingStream
      ? matchingStream
      : streams.find(function (stream) {
          return stream.default === 1;
        });
    this.fireAncestors('$setQualityTitle', finalStream.stream_title);
    this.tag('TVChannelInfo').tag('Actions')._setStreamTitle(finalStream.stream_title);

    const requestOptions = {
      method: 'GET',
      headers: {
        'X-Auth': await getToken(), // Dodaj token u Authorization zaglavlje
        'X-Client-Version': 'ZeenTV Hisense 1.0.0',
      },
    };
    this.tag('TVChannelInfo')._addInfo(data, index);

    // Fetchaj sadržaj sa datog URL-a koristeći definisane opcije
    fetch(finalStream.content_stream, requestOptions)
      .then((response) => {
        VideoPlayer.open(response.url, playerSettings);
        VideoPlayer.play();
        setTimeout(() => {
          this.patch({
            LoadingContainer: {
              visible: false,
            },
          });
          this._updateNoiseInterval && clearInterval(this._updateNoiseInterval);
        }, 1000);
        // Proveri da li je odgovor uspešan (status koda 200)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Ako je odgovor uspešan, parsiraj odgovor kao tekst
        return response.text();
      })
      .then((data) => {
        // Sada možeš raditi sa podacima, na primer, reprodukovati URL
        console.log('Reprodukcija URL-a:', data);

        // Ovde možeš dodati kod za reprodukciju URL-a
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  async _playCertainStream(option) {
    // VideoPlayer.clear();
    const requestOptions = {
      method: 'GET',
      headers: {
        'X-Auth': await getToken(), // Dodaj token u Authorization zaglavlje
        'X-Client-Version': 'ZeenTV Hisense 1.0.0',
      },
    };
    const playerSettings = {
      debug: true,
    };
    fetch(option.content_stream, requestOptions)
      .then((response) => {
        VideoPlayer.open(response.url, playerSettings);
        VideoPlayer.play();

        // Proveri da li je odgovor uspešan (status koda 200)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Ako je odgovor uspešan, parsiraj odgovor kao tekst
        return response.text();
      })
      .then((data) => {
        // Sada možeš raditi sa podacima, na primer, reprodukovati URL
        console.log('Reprodukcija URL-a:', data);

        // Ovde možeš dodati kod za reprodukciju URL-a
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });

    // VideoPlayer.open(option.content_stream);
    // VideoPlayer.play();
  }

  $videoPlayerLoadStart() {
    this.tag('LoadingContainer').patch({
      visible: true,
    });
  }
  $videoPlayerLoadedData() {
    this.tag('LoadingContainer').patch({
      visible: false,
    });
  }

  $videoPlayerError(e) {
    console.log('Error', e);
  }

  _updateNoise() {
    const time = new Date().getTime() % 1000;
    this.patch({
      LoadingContainer: {
        shader: {
          time,
        },
      },
    });
  }
  // this doesnt work on first load after build, why?
  // $videoPlayerLoadedData() {
  //   this.patch({
  //     LoadingContainer: {
  //       visible: false,
  //     },
  //   })
  //   this._updateNoiseInterval && clearInterval(this._updateNoiseInterval)
  // }

  _getFocused() {
    return this.tag('TVChannelInfo');
  }
}
