import { Colors, Router, Utils, VideoPlayer } from '@lightningjs/sdk';
import lng from '@lightningjs/core';
import { Column } from '@lightningjs/ui-components';
import srtParser2 from 'srt-parser-2';

// Components
import VODProgressContainer from './components/VODProgressContainer';
import SourceModal from './components/SubtitleModal/SourceModal';
import { loader, unloader } from '../../components/Player/HLS';
import SplashScreenComponent from '../../components/SplashScreen/SplashScreenComponent';

export default class VODPlayer extends lng.Component {
  constructor(props) {
    super(props);
    this._subtitles = false;
  }

  static _template() {
    return {
      w: 1920,
      h: 1080,
      collision: true,
      SubtitleText: {
        y: 830,
        w: 1920,
        flex: {
          direction: 'row',
        },
        text: {
          lineHeight: 45,
          fontSize: 36,
          textColor: Colors('#ffffff').get(),
          textAlign: 'center',
        },
      },
      VODProgressContainer: {
        zIndex: 10,
        type: VODProgressContainer,
      },
      SubtitleModal: {
        collision: true,
        type: SourceModal,
        visible: false,
      },
      Column: {
        type: Column,
      },
      Loading: {
        type: SplashScreenComponent,
        w: 1920,
        h: 1080,
        color: Colors('#000000').get(),
        rect: true,
        visible: false,
      },
      Spinner: {
        x: 960,
        y: 540,
        w: 80,
        h: 80,
        texture: lng.Tools.getSvgTexture(Utils.asset('images/spinner.svg'), 80, 80),
        mount: 0.5,
        rotation: 0,
        visible: false,
      },
    };
  }

  set params({ data, flag }) {
    this._params = { data, flag };
  }

  set showSubtitle(val) {
    this._showSubtitle = val;
  }

  get _SubtitleText() {
    return this.tag('SubtitleText');
  }

  get _SubtitleModal() {
    return this.tag('SubtitleModal');
  }

  get VODProgressContainer() {
    return this.tag('VODProgressContainer');
  }

  $preventInterval() {
    this.VODProgressContainer.$preventHideProgressBar();
  }

  _enable() {
    this._setState('Column');
    this._SubtitleText.text = '';
    this.VODProgressContainer.$updateCurrentTimeAndProgressBar();
    this.VODProgressContainer.patch({
      data: this._params.data,
      flag: this._params.flag,
      visible: false,
    });
    this.VODProgressContainer._ProgressBar.patch({
      flag: this._params.flag,
    });
    VideoPlayer.position(0, 0);
    VideoPlayer.size(1920, 1080);
    VideoPlayer.consumer(this);
    VideoPlayer.loader(loader);
    VideoPlayer.unloader(unloader);
    VideoPlayer.loop(false);

    setTimeout(() => {
      this._startVideo();
    }, 10);
  }

  _startVideo() {
    if (this._params.flag === 'movie' && this._params.data.contentTypeID == 2) {
      const moviesString = localStorage.getItem('movies');
      const movies = moviesString ? JSON.parse(moviesString) : [];
      const continueToWatch = movies.find(
        (movie) => movie.content_id == this._params.data.content_id
      );
      if (continueToWatch) {
        VideoPlayer.seek(continueToWatch.continueToWatch);
        VideoPlayer.play();
      } else {
        VideoPlayer.seek(0);
        VideoPlayer.play();
      }
    }
    if (
      (this._params.flag === 'movie' || this._params.flag === 'episode') &&
      this._params.data.contentTypeID == 3
    ) {
      const seriesString = localStorage.getItem('series');
      const series = seriesString ? JSON.parse(seriesString) : [];
      const continueToWatch = series.find(
        (serie) => serie.stream_id == this._params.data.stream_id
      );
      if (continueToWatch) {
        VideoPlayer.seek(continueToWatch.continueToWatch);
        VideoPlayer.play();
      } else {
        VideoPlayer.seek(0);
        VideoPlayer.play();
      }
    }
  }

  async _active() {
    this.fireAncestors('$punchHole');
    VideoPlayer.open(this._VODUrl());
  }

  $addToContinueToWatch() {
    const { flag, data } = this._params;
    if (flag === 'movie' || flag === 'episode') {
      const { contentTypeID, content_id } = data;

      // Include currentTime in data
      data.continueToWatch = Math.round(VideoPlayer.currentTime);

      const addToLocalStorage = (key, existingItems) => {
        const index = existingItems.findIndex((item) => item.content_id === content_id);
        if (contentTypeID === 2 || contentTypeID === 3) {
          if (index === -1) {
            existingItems.push(data);
          } else {
            existingItems[index] = data;
          }
          localStorage.setItem(key, JSON.stringify(existingItems));
        }
      };

      if (contentTypeID === 3) {
        const existingSeries = JSON.parse(localStorage.getItem('series')) || [];
        addToLocalStorage('series', existingSeries);
      }

      if (contentTypeID === 2) {
        const existingMovies = JSON.parse(localStorage.getItem('movies')) || [];
        addToLocalStorage('movies', existingMovies);
      }
    }
  }

  _VODUrl() {
    switch (this._params.flag) {
      case 'movie':
        return this._params.data.content_stream;
      case 'episode':
        return this._params.data.videoURL;
      case 'trailer':
        return this._params.data.trailer;
    }
  }

  _handleMediaPlayPause() {
    // this.VODProgressContainer._Buttons.items[1]._checkButton();
    if (VideoPlayer.playing) {
      this._handleMediaPause();
    } else {
      this._handleMediaPlay();
    }
  }

  _handleMediaPlay() {
    this.VODProgressContainer.visible = true;
    this._setState('VODProgressContainer');
    this.VODProgressContainer.$preventHideProgressBar();
    VideoPlayer.play();
    this.VODProgressContainer.$clearInterval();
    this.VODProgressContainer.$updateCurrentTime();
    this.$subtitleInterval();
    this.VODProgressContainer._Buttons.items[1]._updateIcon(0, '/images/pause.svg', 70, 70);
    this.VODProgressContainer._Buttons.items[1]._buttonInfo.title = 'Pause';
    this.VODProgressContainer._Buttons.selectedIndex = 1;
  }

  _handleMediaPause() {
    this.VODProgressContainer.visible = true;
    this._setState('VODProgressContainer');
    this.VODProgressContainer.$preventHideProgressBar();
    VideoPlayer.pause();
    this.VODProgressContainer.$clearInterval();
    this.$clearSubtitleInterval();
    this.VODProgressContainer._Buttons.items[1]._updateIcon(10, '/icons/ic_play_4.SVG', 70, 70);
    this.VODProgressContainer._Buttons.items[1]._buttonInfo.title = 'Play';
    this.VODProgressContainer._Buttons.selectedIndex = 1;
  }

  _handleMediaStop() {
    console.log('MediaStop');
  }

  _handleMediaFastForward() {
    this.VODProgressContainer.visible = true;
    this._setState('VODProgressContainer');
    this.VODProgressContainer.$preventHideProgressBar();
    VideoPlayer.seek(VideoPlayer.currentTime + 10);
    this.VODProgressContainer.$updateCurrentTimeAndProgressBar();
    this.VODProgressContainer._Buttons.selectedIndex = 2;
  }

  _handleMediaRewind() {
    this.VODProgressContainer.visible = true;
    this._setState('VODProgressContainer');
    this.VODProgressContainer.$preventHideProgressBar();
    VideoPlayer.seek(VideoPlayer.currentTime - 10);
    this.VODProgressContainer.$updateCurrentTimeAndProgressBar();
    this.VODProgressContainer._Buttons.selectedIndex = 0;
  }

  $hideProgressBar() {
    this.VODProgressContainer.visible = false;
    this._SubtitleModal.visible = false;
    this._setState('Column');
  }

  // SUBTITLE FIELD

  $subtitleInterval() {
    if (this._subtitles) {
      this._updateTimeIntervall = setInterval(async () => {
        const currentSubtitle = this._subtitles.find(
          (subtitle) =>
            VideoPlayer.currentTime >= subtitle.startSeconds &&
            VideoPlayer.currentTime <= subtitle.endSeconds
        );

        if (currentSubtitle) {
          let cleanedSubtitleText = currentSubtitle.text;

          if (/<[^>]+>/.test(cleanedSubtitleText)) {
            cleanedSubtitleText = cleanedSubtitleText.replace(/<[^>]+>/g, '');
          }
          this.patch({
            SubtitleText: undefined,
          });
          this.patch({
            SubtitleText: {
              y: 830,
              w: 1920,
              flex: {
                direction: 'row',
              },
              text: {
                lineHeight: 45,
                fontSize: 36,
                textColor: Colors('#ffffff').get(),
                textAlign: 'center',
              },
            },
          });
          this._SubtitleText.patch({
            visible: true,
            text: {
              text: `${cleanedSubtitleText}`,
              fontStyle: 'normal bold',
            },
          });
        } else {
          this._SubtitleText.patch({
            visible: false,
          });
        }
      }, 500);
    }
  }

  $clearSubtitleInterval() {
    clearInterval(this._updateTimeIntervall);
  }

  $showSubtitleModal() {
    if (this._showSubtitle) {
      this._SubtitleModal.visible = true;
      this._setState('SubtitleModal');
    }
  }

  $deleteSubtitle() {
    this._subtitles = false;
    this._SubtitleText.patch({
      visible: false,
    });
    this.$clearSubtitleInterval();
    this._SubtitleModal.visible = false;
    this._setState('VODProgressContainer');
  }

  async $showSubtitle({ data }) {
    this._SubtitleModal.visible = false;
    this._setState('VODProgressContainer');
    const parser = new srtParser2();
    try {
      const srtSubtitlesRes = await fetch(data.url);
      const srtSubtitles = await srtSubtitlesRes.text();
      this._subtitles = parser.fromSrt(srtSubtitles);

      clearInterval(this._updateTimeIntervall);
      this._SubtitleText.patch({
        visible: false,
      });
      this.$subtitleInterval();
    } catch (error) {
      console.error('Failed to fetch subtitles:', error);
    }
  }

  // VIDEO PLAYER FUNCTIONS

  $videoPlayerWaiting() {
    this.tag('Spinner').patch({
      visible: true,
    });
    this._spin();
    this.tag('Loading').patch({
      visible: false,
    });
  }

  $videoPlayerPlaying() {
    this.tag('Spinner').patch({
      visible: false,
    });
  }

  _spin() {
    this.tag('Spinner')
      .animation({
        duration: 2,
        repeat: -1,
        actions: [{ p: 'rotation', v: { 0: 0, 1: 10 * Math.PI } }],
      })
      .start();
  }

  $videoPlayerEnded() {
    clearInterval(this.intervalId);
    if (this._params.flag === 'movie' && this._params.data.contentTypeID == 2) {
      const movies = JSON.parse(localStorage.getItem('movies')) || [];
      const movieId = this._params.data.content_id;
      const indexToRemove = movies.findIndex((movie) => movie.content_id === movieId);
      if (indexToRemove !== -1) {
        movies.splice(indexToRemove, 1);
        localStorage.setItem('movies', JSON.stringify(movies));
      }
      this.VODProgressContainer.$clearInterval();
      this.$clearSubtitleInterval();
      this._setState('Column');
      VideoPlayer.clear();
      Router.back();
    } else {
      this.$addToContinueToWatch();
      VideoPlayer.pause();
    }
  }

  $videoPlayerLoadStart() {
    this._setState('LoadingSpinner');
    this.tag('Loading').patch({
      visible: true,
    });
  }
  $videoPlayerLoadedData() {
    this._setState('Column');
    this.tag('Loading').patch({
      visible: false,
    });
  }

  _handleBack() {
    if (this.VODProgressContainer.visible && this._SubtitleModal.visible) {
      this._SubtitleModal.visible = false;
      this._setState('VODProgressContainer');
    } else if (this.VODProgressContainer.visible) {
      this.VODProgressContainer.visible = false;
      this._setState('Column');
    } else {
      this.$addToContinueToWatch();
      VideoPlayer.clear();
      this.VODProgressContainer.$clearInterval();
      this.$clearSubtitleInterval();
      this._setState('Column');
      clearInterval(this.intervalId);
      Router.back();
    }
  }

  // STATES

  static _states() {
    return [
      class VODProgressContainer extends this {
        _getFocused() {
          return this.VODProgressContainer;
        }
      },
      class Column extends this {
        _getFocused() {
          return this.tag('Column');
        }

        _handleClick() {
          this._handleEnter();
        }

        _handleEnter() {
          this.VODProgressContainer.visible = true;
          this._setState('VODProgressContainer');
        }
      },
      class LoadingSpinner extends this {
        _getFocused() {
          return this.tag('Loading');
        }
      },
      class SubtitleModal extends this {
        _getFocused() {
          return this._SubtitleModal;
        }
      },
    ];
  }
}
