import lng from '@lightningjs/core';
import SubscriptionInput from '../../TransferSettings/SubscriptionInput';
import { Colors } from '@lightningjs/sdk';
// import Keyboard from '../../../../Register/components/Keyboard';
import { getPassword, setPassword } from '../../../../../api/settings/index';
import Keyboard from '../../../../../components/Keyboard/components/Keyboard';

let step = 1;
export default class ChangePinModal extends lng.Component {
  static _template() {
    return {
      Modal: {
        collision: true,
        x: -170,
        y: -200,
        w: 1920,
        h: 1080,
        rect: true,
        zIndex: 205,
        color: Colors('#000000').alpha(0.8).get(),
        flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
        Title: { text: { text: 'Enter Child Lock Password' } },
        Wrapper: {
          flex: { direction: 'row', justifyContent: 'center' },
          InputField: {
            type: SubscriptionInput,
            y: 50,
            w: 700,
            h: 100,
            collision: true,
            zIndex: 500,
            placeholder: 'Lock Password',
            isPassword: true,
          },
        },
      },
      Keyboard: {
        type: Keyboard,
        w: 1920,
        h: 1080,
        rect: true,
        color: Colors('#000000').alpha(0).get(),
        flex: { direction: 'row', justifyContent: 'center' },
        y: 550,
        x: -150,
        zIndex: 205,
        visible: false,
        limit: 4,
      },
    };
  }

  _handleHover() {
    return false;
  }

  get _InputField() {
    return this.tag('InputField');
  }
  _active() {
    this._setState('InputField');
  }

  $showKeyboard() {
    this.tag('Keyboard').patch({
      visible: true,
    });
    this._InputField.tag('Input').listening = true;
    this._setState('Keyboard');
  }

  _handleBack() {
    step = 1;
    this.fireAncestors('$hideModal', { flag: '', quality: '' });
  }

  async $pressedDone() {
    if (step == 1) {
      this.tag('Keyboard').visible = false;
      this._setState('InputField');
      this._InputField.tag('Input').listening = false;
      const inputValue = this._InputField.tag('Input').value;
      const { data } = await getPassword();
      if (inputValue == '') {
        this.fireAncestors('$hideModal', { flag: 'changePin', quality: 'empty' });
      }
      if (inputValue == data[0]) {
        step = 2;
        this.tag('Title').patch({
          text: { text: 'NEW PIN' },
        });
        this.tag('Keyboard').visible = true;
        this._InputField.tag('Input').listening = true;
        this._InputField.tag('Input').clear();

        this._setState('Keyboard');
      } else {
        this.fireAncestors('$hideModal', { flag: 'changePin', quality: 'wrong' });
      }
      return;
    }
    if (step == 2) {
      this.tag('Keyboard').visible = false;
      this._setState('InputField');
      this._InputField.tag('Input').listening = false;
      const inputValue = this._InputField.tag('Input').value;

      if (inputValue == '') {
        this.fireAncestors('$hideModal', { flag: 'changePin', quality: 'empty' });
      }
      if (inputValue.length > 3) {
        await setPassword(inputValue);
        this.fireAncestors('$hideModal', { flag: 'changePin', quality: inputValue });
      } else {
        this.fireAncestors('$hideModal', { flag: 'changePin', quality: 'wrong' });
      }
      step = 1;

      return;
    }
  }

  $setFocusKeyboard() {
    this._setState('Keyboard');
  }

  $setState(state) {
    this._setState(state);
  }

  static _states() {
    return [
      class InputField extends this {
        _getFocused() {
          return this.tag('InputField');
        }
      },
      class Keyboard extends this {
        _getFocused() {
          return this.tag('Keyboard');
        }
      },
    ];
  }
}
