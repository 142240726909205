import lng from '@lightningjs/core';
import { Colors, Utils } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';
import IconButton from '../Buttons/IconButton';

export const ListOfOptions = [
  {
    id: 1,
    name: 'Search',
    icon: '/icons/search_d.SVG',
    onEnter: '$showSearch',
    onEnterProps: true,
  },
  {
    id: 2,
    name: 'Add',
    icon: '/icons/redflix_infobar_favorite.svg',
    width: 50,
    height: 50,
    onEnter: '$addorremove',
    onEnterProps: true,
  },

  // { id: 3, name: 'EPG', icon: '/icons/epg_d.SVG' },
  {
    id: 4,
    name: 'Quality',
    icon: '/icons/quality_d.SVG',
    onEnter: '$showQuality',
    onEnterProps: true,
  },
  {
    id: 5,
    name: 'Sort',
    icon: '/icons/ic_sort_abc_d.SVG',
    onEnter: '$sortChannels',
  },
  {
    id: 6,
    name: 'Previous',
    icon: '/icons/pageup_d.SVG',
    onEnter: '$navigateChannels',
    onEnterProps: -12,
  },
  {
    id: 7,
    name: 'Next',
    icon: '/icons/pagedown_d.SVG',
    onEnter: '$navigateChannels',
    onEnterProps: 12,
  },
];

export default class ChannelOptionsSection extends lng.Component {
  static _template() {
    return {
      w: 125,
      h: 1040,
      collision: true,
      clipping: true,
      x: 0,
      y: 0,
      texture: lng.Tools.getRoundRect(
        125,
        1040,
        10,
        3,
        Colors('#22252a').get(),
        true,
        Colors('#282b30').get()
      ),
      Column: {
        type: Column,
        y: 10,
        w: 125,
        collision: true,
        items: ListOfOptions.map((buttonProps, index) => ({
          type: IconButton,
          buttonProps,
          index: index,
        })),
      },
    };
  }

  _setIcon(sort) {
    let icon = !sort ? '/icons/ic_sort_abc_d.SVG' : '/icons/ic_sort_123_d.SVG';
    ListOfOptions[3].icon = icon;
    this._Column.items[3].Icon.texture = lng.Tools.getSvgTexture(Utils.asset(icon), 75, 75);

    this._Column.items[3].Icon.color = Colors('#ffffff').get();
  }

  _changeFavButton(flag) {
    if (flag) {
      this._Column.items[1].tag('Text').text = 'Remove';
      this._Column.items[1].tag('Icon').texture = lng.Tools.getSvgTexture(
        Utils.asset('/icons/favorite.SVG'),
        40,
        40
      );
    } else {
      this._Column.items[1].tag('Text').text = 'Add';
      this._Column.items[1].tag('Icon').texture = lng.Tools.getSvgTexture(
        Utils.asset('/icons/redflix_infobar_favorite.svg'),
        40,
        40
      );
    }
  }

  _handleHover() {
    this.fireAncestors('$setSectionState', 'MenuSectionState');
    this.fireAncestors('$setState', 'ChannelOptionsSection');
  }

  $setFocus(index) {
    this._Column.selectedIndex = index;
  }

  get _Column() {
    return this.tag('Column');
  }

  _getFocused() {
    return this._Column;
  }
}
