import lng from '@lightningjs/core';
import { Colors, Router, VideoPlayer } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';

export default class MyList extends lng.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      rect: true,
      color: Colors('#000000').get(),
      Column: {
        type: Column,
        x: 150,
        y: 20,
        collision: true,
      },
      Wrapper: {
        w: 1750,
        h: 1080,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        Text: {
          x: 150,
          y: 20,
          text: {
            fontSize: 45,
            maxLines: 2,
            textAlign: 'center',
          },
        },
      },
    };
  }

  get _Column() {
    return this.tag('Column');
  }

  get _Text() {
    return this.tag('Text');
  }

  _handleScroll(event, target) {
    let lorem = this._Column; // Ako koristite target, pretpostavka je da je _Episodes svojstvo ciljnog objekta
    let currentIndex = this._Column.selectedIndex;
    let nextIndex = currentIndex + 1;
    let previousIndex = currentIndex - 1;
    // Inkrementiranje ili dekrementiranje lorem.x za 400, ovisno o smjeru skroliranja
    if (event.deltaY > 0) {
      this._Column.scrollTo(nextIndex, 50);
      // lorem.x -= 400;
    } else if (event.deltaY < 0) {
      console.log('Rotacija prema gore');
      // lorem.x += 400;
      this._Column.scrollTo(previousIndex, 50);
    } else {
      console.log('Nema vertikalne rotacije');
    }

    // Ograniči lorem.x na raspon između -400 i 0
    // lorem.x = Math.max(Math.min(0, lorem.x), -400);

    // Ovdje možete dodati dodatne akcije ili ažuriranja sukladno promjenama u lorem.x
  }

  $scroll(event) {
    this._handleScroll(event);
    this._unfocus();
  }

  $setFocus({ rowIndex, index }) {
    let modifiedIndex = index % 6;
    // this._Column.selectedIndex = rowIndex;
    this._Column.scrollTo(rowIndex, 400);
    this._Column.items[rowIndex].selectedIndex = modifiedIndex;
  }

  $unselectCurrentIndex() {
    let currentIndex = this._Column.selectedIndex;
    let rowIndex = (this._Column.items[currentIndex].selectedIndex = undefined);
    // this._Column.items[currentIndex].items[rowIndex]._unfocus();
  }

  _handleLeft() {
    Router.focusWidget('sidebar');
  }

  _getFocused() {
    return this._Column;
  }
  _disable() {
    VideoPlayer.loader(null);
    VideoPlayer.unloader(null);
    VideoPlayer.clear();
  }
}
