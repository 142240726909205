import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

export default class ConnectButton extends lng.Component {
  static _template() {
    return {
      Button: {
        collision: true,
        rect: true,
        color: Colors('#424242').get(),
        flex: {
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        },

        Text: {
          y: 13,
          w: 220,
          h: 60,
          text: {
            fontSize: 25,
            color: Colors('#fafafa').get(),
            maxLines: 1,
            textAlign: 'center',
          },
        },
      },
    };
  }

  set buttonInfo(val) {
    this._buttonInfo = val;
  }

  set indicator(val) {
    this._indicator = val;
  }
  set index(val) {
    this._index = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  get _Text() {
    return this.tag('Text');
  }

  _firstEnable() {
    this._Button.patch({
      w: this.w,
      h: this.h,
      texture: lng.Tools.getRoundRect(this.w, this.h, 10, 3, false),
    });

    this._Text.patch({
      text: { text: this._buttonInfo.title },
    });
  }

  _focus() {
    this._Button.patch({ color: Colors('#C40000').get() });
  }

  _unfocus() {
    this._Button.patch({ color: Colors('#424242').get() });
  }

  _handleEnter() {
    switch (this._buttonInfo.title) {
      case 'Connect':
        this.fireAncestors('$checkCode');
        break;
    }
  }

  _handleHover() {
    this.fireAncestors('$setState', 'Button');
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this.fireAncestors('$hideKeyboard');
    this._handleEnter();
  }
}
