import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';
import { Input } from '@lightningjs/ui-components';

export default class SubscriptionInput extends Lightning.Component {
  static _template() {
    return {
      Button: {
        collision: true,
        Input: {
          collision: true,
          type: Input,
          style: {
            backgroundColor: Colors('blue').alpha(0).get(),
          },
          password: true,
          mask: '*',
          backgroundColor: Colors('red').get(),
        },
        Placeholder: {
          y: 3,
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          Text: {
            text: { fontSize: 40 },
          },
        },
      },
    };
  }

  set placeholder(val) {
    this._placeholder = val;
  }

  set isPassword(val) {
    this._isPassword = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  _enable() {
    this.tag('Input').patch({
      password: this._isPassword,
      mask: '*',
      w: this.w,
      h: this.h,
      shader: {
        type: lng.shaders.RoundedRectangle,
        radius: 10,
      },
    });

    this.tag('Placeholder.Text').patch({
      text: { text: this._placeholder },
    });
    this.tag('Placeholder').patch({
      w: this.w,
      h: this.h,
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        10,
        3,
        Colors('#3d3d3d').get(),
        true,
        Colors('#3d3d3d').get()
      ),
    });
  }

  _changePlaceholder() {
    const isInputEmpty = this.tag('Input').value === '';
    this.tag('Placeholder.Text').patch({
      visible: isInputEmpty,
    });
  }

  _handleEnter() {
    this.fireAncestors('$showKeyboard');
  }

  _handleClick() {
    this._handleEnter();
  }

  _handleHover() {
    this.fireAncestors('$setState', 'InputField');
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _focus() {
    this.tag('Placeholder').patch({
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        10,
        3,
        Colors('#C40000').get(),
        true,
        Colors('#000000').get()
      ),
    });
  }

  _unfocus() {
    this.tag('Placeholder').patch({
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        10,
        3,
        Colors('#3d3d3d').get(),
        true,
        Colors('#3d3d3d').get()
      ),
    });
  }
}
