import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

let selectedSeason = 0;
export default class SeasonButton extends lng.Component {
  static _template() {
    return {
      Button: {
        collision: true,
        rect: true,
        color: Colors('#3d3d3d').get(),
        flex: {
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        },
        Selector: {
          y: 3,
          text: { fontSize: 40 },
        },
        Text: {
          y: 3,
          text: {
            fontSize: 25,
            color: Colors('#fafafa').get(),
            wordWrapWidth: 310,
            textAlign: 'center',
          },
        },
      },
    };
  }

  set buttonInfo(val) {
    this._buttonInfo = val;
  }

  set indicator(val) {
    this._indicator = val;
  }

  set initialIndex(val) {
    this._initialIndex = val;
  }

  set index(val) {
    this._index = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  get _Text() {
    return this.tag('Text');
  }

  get _Selector() {
    return this.tag('Selector');
  }

  _enable() {
    if (this._indicator == 'seasons') {
      selectedSeason = this._initialIndex;
    }
    if (this._index === this._initialIndex) {
      this._Selector.patch({
        flexItem: { marginRight: 10 },
        text: { text: '•' },
      });
    } else {
      this._Selector.patch({
        flexItem: { marginRight: 0 },
        text: { text: '' },
      });
    }
    this._Button.patch({
      w: this.w,
      h: this.h,
      texture: lng.Tools.getRoundRect(this.w, this.h, 5, 3, false),
    });

    this._Text.patch({
      text: { text: this._buttonInfo.title },
    });
  }

  _focus() {
    this._Button.patch({ color: Colors('#C40000').get() });
  }

  _unfocus() {
    this._Button.patch({ color: Colors('#3d3d3d').get() });
  }

  _handleEnter() {
    // this.parent.children.forEach((button) => {
    //   if (button instanceof SeasonButton) {
    //     button._Selector.patch({
    //       flexItem: { marginRight: 0 },
    //       text: { text: '' },
    //     });
    //   }
    // });
    // this._Selector.patch({
    //   flexItem: { marginRight: 10 },
    //   text: { text: '•' },
    // });
    const handleCommonLogic = () => {
      this.parent.children.forEach((button) => {
        if (button instanceof SeasonButton) {
          button._Selector.patch({
            flexItem: { marginRight: 0 },
            text: { text: '' },
          });
        }
      });
      this._Selector.patch({
        flexItem: { marginRight: 10 },
        text: { text: '•' },
      });
    };
    switch (this._indicator) {
      case 'episodes':
        return this.fireAncestors('$playVOD', { data: this._buttonInfo, flag: 'episode' });
      case 'seasons':
        if (selectedSeason === this._index) {
          return true;
        }
        handleCommonLogic();
        this.fireAncestors('$changedSeasons', { episodes: this._buttonInfo.episodes });
        selectedSeason = this._index;
    }
  }

  _handleClick() {
    this._handleEnter();
  }

  _handleHover() {
    this.fireAncestors('$setState', this._indicator);

    // Clear old timeout, if any
    if (this._hoverTimeoutId) {
      clearTimeout(this._hoverTimeoutId);
    }

    // Set new timeout
    this._hoverTimeoutId = setTimeout(() => {
      this.fireAncestors('$setFocus', { index: this._index, flag: this._indicator });
    }, 10);

    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }
}
