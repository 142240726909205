import { tvInfo } from '../../../api';
import { getTVInfo } from '../../../utils/device';
import Information from '../components/Information';

export default async function initializePage(page) {
  const { brand, mac, model, sn } = tvInfo;

  const listOfOptions = [
    { id: 1, title: 'MAC Address', value: mac },
    { id: 2, title: 'Serial Number', value: sn },
    { id: 3, title: 'Brand', value: brand },
    { id: 4, title: 'Model', value: model },
  ];
  const items = listOfOptions.map((info) => ({
    type: Information,
    h: 60,
    w: 900,
    information: info,
  }));

  page._Column.items = items;
  page.tvInfo = {
    mac: mac,
    sn: sn,
  };
}
