import lightning from '@lightningjs/core';
import { Colors, Utils } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';

const FILTER_BORDER_RADIUS = 5;
const arabicText = 'هذا نص طويل لتمرير الماركي';

export default class LiveChannelsButtonData extends lightning.Component {
  constructor(props) {
    super(props);
    this._marqueeTimeout = undefined;
    this._selected = false;
  }

  static _template() {
    return {
      collision: true,
      w: 350,
      h: 65,
      flex: { direction: 'row', justifyContent: 'center' },
      Button: {
        collision: true,
        x: -20,
        clipping: true,
        flex: { direction: 'row', alignItems: 'center' },
        ContentIcon: {},
        ContentTime: { w: 170, x: 10, alignSelf: 'center' },
        ContentTitle: {
          // type: Marquee,
          w: 800,
          x: -50,
          y: 0,
          // alignSelf: 'center',
          /* text: {
            textAlign: 'right',
            direction: 'rtl', // Set the text direction to 'rtl'
          }, */
        },
      },
    };
  }

  set isPastTime(value) {
    this._isPastTime = value;
  }

  set buttonTitle(value) {
    this._time = value;

    if (this._isPastTime) {
      this._ContentIcon.patch({
        w: 50,
        h: 50,
        y: 7,
        x: 10,
        alignSelf: 'center',
        texture: Lightning.Tools.getSvgTexture(
          Utils.asset(`icons/dvr_channellist_icon.SVG`),
          50,
          50
        ),
      });
    }

    this._buttonTitle = this._checkIfLeftToRight(arabicText) ? value.description : ' ';
  }

  _checkIfLeftToRight(text) {
    var arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(text);
  }

  set onEnterProps(value) {
    this._onEnterProps = value;
  }

  set onEnter(value) {
    this._onEnter = value;
  }

  get _Button() {
    return this.tag('Button');
  }

  get _ContentTitle() {
    return this.tag('ContentTitle');
  }

  get _ContentTime() {
    return this.tag('ContentTime');
  }

  get _ContentIcon() {
    return this.tag('ContentIcon');
  }

  _selectButton() {
    this._selected = true;
  }

  _unselectButton() {
    this._selected = false;
    this._Button.patch({
      texture: null,
    });
  }

  _enable() {
    this.patch({ w: this.w, h: this.h });
    this._Button.patch({ w: this.w - 50, h: this.h });
    this._ContentTitle.patch({
      text: {
        text: this._buttonTitle,
        fontSize: 26,
        wordWrapWidth: 750,
        maxLines: 3,
        textAlign: 'right',
      },
    });
    this._ContentTime.patch({
      text: { text: `${this._time.startTime} - ${this._time.stopTime}`, fontSize: 24, maxLines: 1 },
    });
  }

  _focus() {
    this._marqueeTimeout = setTimeout(() => {
      this._ContentTitle.patch({
        // autoStart: true,
        // repeat: -1,
      });
    }, 1200);
    this._Button.patch({
      texture: lightning.Tools.getRoundRect(
        this.w,
        this.h,
        FILTER_BORDER_RADIUS,
        FILTER_BORDER_RADIUS,
        Colors('#C40000').get(),
        true,
        Colors('#C40000').get()
      ),
    });
  }

  _handleHover() {
    console.log('EPG Button Hovered');
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _unfocus() {
    clearTimeout(this._marqueeTimeout);
    this._ContentTitle.patch({
      // autoStart: false,
      // repeat: 0,
    });
    if (this._selected)
      this._Button.patch({
        texture: lightning.Tools.getRoundRect(
          this.w,
          this.h,
          FILTER_BORDER_RADIUS,
          FILTER_BORDER_RADIUS,
          Colors('#C40000').get(),
          false
        ),
      });
    else
      this._Button.patch({
        texture: null,
      });
  }

  _handleEnter() {
    // this.fireAncestors(this._onEnter, this._onEnterProps);
  }
}
