import Lightning from '@lightningjs/sdk/src/Lightning';
import TvPlayer from './TvPlayer';
import CurrentTvInfo from './CurrentTvInfo';
import EPG from './EPGSection/EPG';
import { Colors, Router } from '@lightningjs/sdk';
import { getLock } from '../../../../api/settings/index';

export const SmallPlayer = 'small';
export const ExpandedPlayer = 'expanded';
export const FullScreenPlayer = 'fullscreen';
const PlayerTypesSizes = {
  [SmallPlayer]: { w: 700, h: 394, x: 1205, y: 25 },
  [ExpandedPlayer]: { w: 950, h: 535, x: 955, y: 25 },
  [FullScreenPlayer]: { w: 1920, h: 1080, x: 0, y: 0 },
};

export default class PlayerSection extends Lightning.Component {
  constructor(props) {
    super(props);
    this._playerSize = undefined;
    this._autoCloseTimer = undefined;
    this._currentStatus = 'MenuSectionState';
    this._currentIndex;
    this._exitStatus = false;
  }

  static _template() {
    return {
      h: 1040,
      x: 5,
      collision: true,
      flex: { direction: 'column' },
      TvPlayer: { type: TvPlayer },
      CurrentTvInfo: { type: CurrentTvInfo },
      EPG: { type: EPG, h: 490, collision: true },
    };
  }

  $getChannelIndex() {
    return this._CurrentTvInfo._getIndex();
  }

  _getPlayerStatus() {
    return this._currentStatus;
  }

  get _TvPlayer() {
    return this.tag('TvPlayer');
  }

  get _CurrentTvInfo() {
    return this.tag('CurrentTvInfo');
  }

  get _EPG() {
    return this.tag('EPG');
  }

  _active() {
    this._handleSetInitialFocus();
    this._setState('EPG');
  }

  _enable() {
    this._handleChangePlayerSize(SmallPlayer);
  }

  $findCurrentChannel() {
    const isLocked = this.fireAncestors('$findChannelButton', this._currentIndex);
    return isLocked;
  }

  async _handleNewChannel(data, index) {
    this._currentIndex = index;
    this.fireAncestors('$findChannelIsFav', index);
    const isLocked = this.fireAncestors('$findChannelButton', index);

    if (isLocked) {
      this.fireAncestors('$showLockModal', { flag: 'check', index: index, data: data });
      return;
    }
    this._playChannel(data, index);
  }

  // OVO JE MOJ KOD
  _playChannel(data, index) {
    this._TvPlayer._play(data, index);
    this._CurrentTvInfo.patch({ data, index });
    this._CurrentTvInfo._getInfo();
    this.fireAncestors('$setIndex', index);
    this.fireAncestors('$selectChannel', index);

    // this._EPG.patch({ data });
    this._EPG._getInfo(data);
  }

  _handleSetInitialFocus() {
    if (this._EPG._EPGInfo._Date.items.length != 0) {
      this._setState('EPG');
    } else this._setState('EPG');
  }

  $showChannels(SmallPlayer) {
    this._handleChangePlayerSize(SmallPlayer);
    this._setState('EPG');
  }

  _handleChangePlayerSize(playerSize) {
    // get player size depending on player type (small,expended,fullscreen)
    const { x, y, w, h } = PlayerTypesSizes[playerSize];

    // showing and hiding EPG and tv info
    switch (true) {
      case playerSize != FullScreenPlayer && this._playerSize == FullScreenPlayer: {
        this._EPG.visible = true;
        this._CurrentTvInfo.visible = true;
        this.fireAncestors('$showWidget', true);
        break;
      }
      case playerSize == FullScreenPlayer && this._playerSize != FullScreenPlayer: {
        this._EPG.visible = false;
        this._CurrentTvInfo.visible = false;
        this.fireAncestors('$showWidget', false);
        this._setState('FullScreenLiveChannel');
        break;
      }
      default:
    }

    // Ancestors function in App.js that punches the hole in the Background
    this.fireAncestors('$punchHoleLiveChannelsPlayer', x, y, w, h);

    // save current player
    this._playerSize = playerSize;

    this.patch({ w: w });
    this._TvPlayer._resize(x, y, w, h);
    this._CurrentTvInfo.patch({ w: w });
    // Max height of the app - the dynamic height of the TvPlayer - the height of the CurrentTvInfo
    this._EPG.patch({ h: 1040 - h - 100, w: w });
    this._EPG._resize(x, y, w, 1040 - h - 100);
  }

  $checkEPG(programs) {
    this.tag('TvPlayer.TVChannelInfo')._checkEPG(programs);
  }

  static _states() {
    return [
      class FullScreenLiveChannel extends this {
        static _template() {
          return {
            ...super._template(),
          };
        }

        _timeoutWithdrawChannelInfo() {
          this._autoCloseTimer && clearTimeout(this._autoCloseTimer);
          this._autoCloseTimer = setTimeout(() => {
            this._autoCloseTimer = undefined;
            this._setState('FullScreenLiveChannel.NoProgressBar');
          }, 8000);
        }

        $enter() {
          this._timeoutWithdrawChannelInfo();
        }

        $exit() {
          this._autoCloseTimer && clearTimeout(this._autoCloseTimer);
        }

        _getFocused() {
          return this._TvPlayer;
        }

        _handleKey() {
          this._autoCloseTimer && clearTimeout(this._autoCloseTimer);
          this._timeoutWithdrawChannelInfo();
        }

        _handleChannelUp() {
          this._handleUp();
        }

        _handleChannelDown() {
          this._handleDown();
        }

        _handleUp() {
          this.fireAncestors('$hideInfoModal');
          this.fireAncestors('$nextChannel');
          this._autoCloseTimer && clearTimeout(this._autoCloseTimer);
          this._timeoutWithdrawChannelInfo();
        }

        _handleDown() {
          this.fireAncestors('$hideInfoModal');
          this.fireAncestors('$prevChannel');
          this._autoCloseTimer && clearTimeout(this._autoCloseTimer);
          this._timeoutWithdrawChannelInfo();
        }

        _handleBack() {
          this.fireAncestors('$hideInfoModal');
          this._setState('FullScreenLiveChannel.NoProgressBar');
          this._autoCloseTimer && clearTimeout(this._autoCloseTimer);
        }

        static _states() {
          return [
            class NoProgressBar extends FullScreenLiveChannel {
              $enter() {}

              $exit() {}

              _getFocused() {
                return undefined;
              }

              _handleKey() {
                // stop bubbleing
                return true;
              }

              _reOpenMenu() {
                // change state and open channel opetions again
                this._setState('FullScreenLiveChannel');
                // start timer to close menu if inactive
                this._timeoutWithdrawChannelInfo();
              }

              _handleEnter() {
                this._reOpenMenu();
              }

              _handleClick() {
                this._handleEnter();
              }

              _handleDown() {
                this.fireAncestors('$hideInfoModal');

                this.fireAncestors('$prevChannel');
                this._autoCloseTimer && clearTimeout(this._autoCloseTimer);
                this._timeoutWithdrawChannelInfo();
                this._reOpenMenu();
              }

              _handleChannelUp() {
                this._handleUp();
              }

              _handleChannelDown() {
                this._handleDown();
              }

              _handleUp() {
                this.fireAncestors('$hideInfoModal');

                this.fireAncestors('$nextChannel');
                this._autoCloseTimer && clearTimeout(this._autoCloseTimer);
                this._timeoutWithdrawChannelInfo();
                this._reOpenMenu();
              }

              _handleBack() {
                // go back to default view & focus widget
                this.fireAncestors('$showMenu');
                this._handleChangePlayerSize(SmallPlayer);
                // this.fireAncestors('$setState');
                this._setState('CurrentTvInfo');
                this.fireAncestors('$setToChannelList');
                this._exitStatus = true;
                this.fireAncestors('$checkFavourite');
                // Router.focusWidget('sidebar');
                this.fireAncestors('$showOriginalChannels');
              }
            },
          ];
        }
      },
      class EPG extends this {
        _getFocused() {
          return this._EPG;
        }

        // _handleUp() {
        //   this._setState('CurrentTvInfo');
        // }
      },
      class CurrentTvInfo extends this {
        _getFocused() {
          return this._CurrentTvInfo;
        }

        _handleDown() {
          if (this._EPG._EPGInfo._Date.items.length != 0) {
            this._setState('EPG');
          }
        }
      },
    ];
  }
}
