import Lightning from '@lightningjs/sdk/src/Lightning';
import { createDateAndTimeLabel } from '../../../../../utils/timeAndDate';
import TVChannelInfoButtons from './TVChannelButtons';
import { Colors, Utils } from '@lightningjs/sdk';
import { ProgressBar } from '@lightningjs/ui-components';
import lng from '@lightningjs/core';

let timeUpdateInterval = null;
export default class TVChannelInfo extends Lightning.Component {
  constructor(props) {
    super(props);
    this._channelData;
  }
  static _template() {
    return {
      w: 1920,
      h: 0,
      x: -5,
      y: 1080,
      clipping: true,
      collision: true,
      rect: true,
      color: Colors('#272729').get(),
      Info: {
        w: 1920,
        h: 210,
        x: 0,
        y: 0,
        flex: { direction: 'row', alignItems: 'center', justifyContent: 'center' },
        Logo: {
          w: 260,
          h: 250,
        },
        NameAndTime: {
          w: 1660,
          h: 200,
          flex: { direction: 'column' },
          Text: {
            w: 1600,
            h: 120,
            flex: { direction: 'row', justifyContent: 'space-between', alignItems: 'flex-end' },
            flexItem: { marginBottom: 20 },
            NameWrapper: {
              y: -70,
              ProgressBar: {
                type: ProgressBar,
                w: 1600,
                y: 80,
                progress: 0,
              },
              Name: {},
            },
            DateAndTime: {},
          },
          PlayBar: {
            w: 1600,
            h: 50,
          },
          CurrentAndNext: {
            w: 1600,
            h: 40,
            y: -40,
            flex: { direction: 'row', justifyContent: 'space-between' },
            Current: {
              text: {
                fontSize: 25,
              },
            },
            Next: {
              text: {
                fontSize: 25,
              },
            },
          },
        },
      },
      Actions: { type: TVChannelInfoButtons },
    };
  }

  async _findProgram(epg) {
    // Your EPG data
    const epgData = epg;
    // Function to convert HH:MM to minutes
    function timeToMinutes(time) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    }

    // Get current time in minutes
    const currentTime = new Date();
    const currentMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();

    // Search for the current and next program
    let currentProgram = null;
    let nextProgram = null;
    for (let program of epgData[0].epg) {
      const startMinutes = timeToMinutes(program.startTime);
      const stopMinutes = timeToMinutes(program.stopTime);

      if (startMinutes <= currentMinutes && stopMinutes > currentMinutes) {
        // If the current time is within the program's time range
        currentProgram = program;
      } else if (startMinutes > currentMinutes) {
        // If the program starts after the current time
        nextProgram = program;
        break; // Exit the loop since we found the next program
      }
    }
    // Patch current and next program titles to UI
    this.tag('Current').patch({
      text: { text: `Current: ${currentProgram?.title ? currentProgram.title : ''}` },
    });
    this.tag('Next').patch({
      text: { text: `Next: ${nextProgram?.title ? nextProgram.title : ''}` },
    });
  }

  _checkEPG(programs) {
    if (programs == undefined || programs.length === 0) {
      this.tag('Current').patch({
        text: { text: '' },
      });
      this.tag('Next').patch({
        text: { text: '' },
      });
    } else {
      this._findProgram(programs);
    }
    this.tag('Actions')._hideInfo(programs);
  }

  _focus() {
    this.tag('Info.NameAndTime.Text.DateAndTime').patch({
      flexItem: { marginBottom: 20 },
      text: {
        fontSize: 30,
        text: createDateAndTimeLabel(),
      },
    });
    this._startTimestampTimers();
    this.setSmooth('h', 300);
    this.setSmooth('y', 780);
  }

  _unfocus() {
    if (timeUpdateInterval) {
      clearTimeout(timeUpdateInterval);
      clearInterval(timeUpdateInterval);
    }
    this.setSmooth('h', 0);
    this.setSmooth('y', 1080);
  }

  _getFocused() {
    return this.tag('Actions');
  }

  $getData() {
    return this._channelData;
  }

  _getData() {
    return this._channelData;
  }

  _addInfo(channalData, index) {
    this._channelData = channalData;
    const paddedIndex = String(index + 1).padStart(4, '0');

    if (channalData.image_url !== 'test') {
      this.tag('Info.Logo').patch({
        LogoWrapper: {
          mountX: 0.5,
          mountY: 0.8,
          x: 140,
          y: 125,
          texture: {
            type: lng.textures.ImageTexture,
            src: channalData.image_url,
            resizeMode: { type: 'cover', w: 120, h: 72 },
          },
        },
      });
    } else {
      this.tag('Info.Logo').patch({
        LogoWrapper: undefined,
      });
      this.tag('Info.Logo').patch({
        LogoWrapper: {
          mountX: 0.5,
          mountY: 0.8,
          x: 140,
          y: 125,
          texture: lng.Tools.getSvgTexture(Utils.asset(`/icons/tv_default_icon.SVG`), 90, 90),
        },
      });
    }

    this.tag('Info.NameAndTime.Text').patch({
      NameWrapper: {
        Name: undefined,
      },
    });

    this.tag('Info.NameAndTime.Text').patch({
      NameWrapper: {
        Name: {
          text: {
            fontStyle: 'bold',
            fontSize: 50,
            text: paddedIndex + ' ' + channalData.title,
          },
        },
      },
    });
  }

  _startTimestampTimers() {
    timeUpdateInterval = setTimeout(() => {
      this.tag('Info.NameAndTime.Text.DateAndTime').patch({
        text: {
          text: createDateAndTimeLabel(),
        },
      });
      timeUpdateInterval = setInterval(() => {
        this.tag('Info.NameAndTime.Text.DateAndTime').patch({
          text: {
            text: createDateAndTimeLabel(),
          },
        });
      }, 60 * 1000);
    }, (60 - new Date().getSeconds()) * 1000);
  }
}
