import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

export default class MessageBox extends lng.Component {
  static _template() {
    return {
      MessageBox: {
        rect: true,
        color: Colors('#d6d6d6').get(),
        flex: {
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        },
        Title: {
          y: 3,
          color: Colors('#000000').get(),
          text: { text: `Can't log in` },
        },
      },
    };
  }

  set message(val) {
    this._message = val;
  }

  _enable() {
    this.tag('MessageBox').patch({
      w: this.w,
      h: this.h,
      shader: {
        type: lng.shaders.RoundedRectangle,
        radius: this.h / 2,
      },
    });
    this.tag('Title').patch({
      text: { text: this._message },
    });
  }
}
