import lng from '@lightningjs/core';
import { Colors, VideoPlayer } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';

export default class ProgressBar extends lng.Component {
  static _template() {
    return {
      w: 1920,
      h: 10,
      rect: true,
      color: Colors('#333434').get(),
      ProgressBar: {
        type: Column,
        rect: true,
        color: Colors('#ffffff').get(),
        h: 10,
        w: 0,
      },
      ProgressBarIndicator: {
        type: Column,
        collision: true,
        w: 20,
        h: 10,
        rect: true,
        visible: false,
        color: Colors('#C40000').get(),
      },
    };
  }

  set flag(val) {
    this._flag = val;
  }

  _enable() {
    this.fireAncestors('$updateCurrentTimeAndProgressBar');
  }

  _focus() {
    this._toggleProgressBarIndicator(true);
  }

  _unfocus() {
    this._toggleProgressBarIndicator(false);
  }

  _handleHover() {
    this.fireAncestors('$setFocus', { index: 0, flag: 'progressIndicator' });
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleRight() {
    this._seekVideo(this._flag === 'trailer' ? 20 : 120);
    this.fireAncestors('$preventHideProgressBar');
  }

  _handleScroll(event) {
    if (event.deltaY > 0) {
      this._seekVideo(this._flag === 'trailer' ? 20 : 120);
      this.fireAncestors('$preventHideProgressBar');
    } else if (event.deltaY < 0) {
      this._seekVideo(this._flag === 'trailer' ? -20 : -120);
      this.fireAncestors('$preventHideProgressBar');
    }
  }

  _handleLeft() {
    this._seekVideo(this._flag === 'trailer' ? -20 : -120);
    this.fireAncestors('$preventHideProgressBar');
  }

  _seekVideo(seconds) {
    VideoPlayer.seek(VideoPlayer.currentTime + seconds);
    this.fireAncestors('$updateCurrentTimeAndProgressBar');
  }

  _toggleProgressBarIndicator(visible) {
    this.tag('ProgressBarIndicator').patch({
      visible: visible,
    });
  }
}
