// Components
// import Keyboard from '../../../Register/components/Keyboard';
import lng from '@lightningjs/core';
import { transfer } from '../../../../api/settings/index';
import ConnectButton from '../../../Settings/components/UserSettings/ConnectButton';
import SubscriptionInput from '../../../Settings/components/TransferSettings/SubscriptionInput';
import MessageBox from '../../../Settings/MessageBox';
import { Colors, Router } from '@lightningjs/sdk';
import Keyboard from '../../../../components/Keyboard/components/Keyboard';

export default class TransferModal extends lng.Component {
  static _template() {
    return {
      collision: true,
      w: 1300,
      h: 700,
      y: 190,
      x: 210,
      rect: true,
      color: Colors('#141414').get(),
      flex: { direction: 'column', alignItems: 'center' },
      Wrapper: {
        flex: { direction: 'column', alignItems: 'center' },
        Title: {
          y: 70,
          text: { text: 'Subscription Transfer', fontSize: 50 },
        },
        Description: {
          y: 150,
          w: 885,
          text: {
            text: 'To use this feature you must unlock subscription transfer status on the managment panel. Please enter subscription id.',
            fontSize: 30,
            lineHeight: 40,
            letterSpacing: 1,
          },
        },
        SubscriptionContainer: {
          y: 200,
          flex: { direction: 'row' },
          SubscriptionInput: {
            type: SubscriptionInput,
            zIndex: 200,
            collision: true,
            w: 600,
            h: 80,
            placeholder: 'Subscription ID',
            isPassword: false,
          },
          Button: {
            x: 30,
            type: ConnectButton,
            zIndex: 200,
            collision: true,
            w: 200,
            h: 80,
            buttonInfo: { title: 'Connect' },
          },
        },
      },
      Keyboard: {
        type: Keyboard,
        // x: 170,
        y: 330,
        zIndex: 305,
        visible: false,
        limit: 50,
      },
    };
  }

  get _InputField() {
    return this.tag('SubscriptionInput');
  }

  get _Button() {
    return this.tag('Button');
  }

  _active() {
    this._setState('InputField');
  }

  $pressedDone() {
    this.tag('Keyboard').visible = false;
    this._setState('InputField');
    this._InputField.tag('Input').listening = false;
  }

  _handleBack() {
    this.tag('Keyboard').visible = false;
    this.fireAncestors('$hideModal');
  }

  $showKeyboard() {
    this.tag('Keyboard').patch({
      visible: true,
    });
    this._InputField.tag('Input').listening = true;
    this._setState('Keyboard');
  }

  _handleHover() {
    console.log('Hovered Modal');
  }
  _setMessage(msg) {
    this.patch({
      MessageBox: {
        type: MessageBox,
        y: 500,
        w: 550,
        h: 60,
        message: msg,
      },
    });
    setTimeout(() => {
      this.patch({
        MessageBox: undefined,
      });
    }, 2000);
  }

  async $checkCode() {
    const subscriptionId = this._InputField.tag('Input').value;

    if (subscriptionId === '') {
      this._setMessage('Usercode cannot be empty!');
      return;
    }
    try {
      const data = await transfer(subscriptionId);

      if (data === undefined) {
        this._setMessage('Cannot login');
      } else {
        this._setMessage('Successful');
        Router.navigate('home');
      }
    } catch (error) {
      console.error('Error calling transfer function:', error);
      this._setMessage('Cannot login');
    }
  }

  $setState(state) {
    this._setState(state);
  }

  $setFocusKeyboard() {
    this._setState('Keyboard');
  }

  static _states() {
    return [
      class InputField extends this {
        _getFocused() {
          return this._InputField;
        }

        _handleRight() {
          this._setState('Button');
        }
      },
      class Button extends this {
        _getFocused() {
          return this._Button;
        }

        _handleLeft() {
          this._setState('InputField');
        }
      },
      class Keyboard extends this {
        _getFocused() {
          return this.tag('Keyboard');
        }

        _handleBack() {
          this.tag('Keyboard').visible = false;
          this._setState('InputField');
          this._InputField.tag('Input').listening = false;
        }
      },
    ];
  }
}
