import { Column, Row } from '@lightningjs/ui-components';

// API functions
import { getSeries } from '../../../api/series/index';
import { getMovies } from '../../../api/movies/index';
import { getIndex } from '../../../api/index/index';

// Components
import KidCard from '../components/KidCard';

export default async function (page) {
  try {
    const { data } = await getIndex();
    const genres = data.index.genres;

    // Fetch movies and series concurrently
    const [moviesResponse, seriesResponse] = await Promise.all([getMovies(), getSeries()]);
    const moviesData = moviesResponse.data.vod;
    const seriesData = seriesResponse.data.tvseries;

    // Process movies and series concurrently
    const processMedia = (mediaData, mediaType) =>
      Promise.all(
        mediaData.map(async (media) => {
          const mediaGenres = media.genre_id.split(',').map(Number);
          const mediaGenresList = mediaGenres
            .map((genreId) => genres.find((genr) => genr.id === genreId)?.title)
            .filter(Boolean);

          media.genres = mediaGenresList;

          const language = data.index.languages.find((lng) => lng.id === media.language_id);
          media.language = language?.title;

          // Add a flag to distinguish between movies and series
          media.flag = mediaType;

          return media;
        })
      );

    // Process movies and series concurrently
    const [processedMovies, processedSeries] = await Promise.all([
      processMedia(moviesData, 'movies'),
      processMedia(seriesData, 'series'),
    ]);

    // Combine processed movies and series
    const mediaList = [...processedMovies, ...processedSeries];

    // Filter media list to include only those with the "Kids" genre
    const filteredMediaList = mediaList.filter((media) => media.genres.includes('Kids'));

    const items = filteredMediaList.map((media, index) => ({
      type: KidCard,
      w: 265,
      h: 510,
      data: media,
      index: index,
    }));

    // Create rows in parallel
    const pageItems = await Promise.all(
      Array.from({ length: Math.ceil(items.length / 6) }).map(async (_, i) => {
        const start = i * 6;
        const end = start + 6;
        const rowItems = items.slice(start, end).map((item, j) => {
          return { ...item, rowIndex: i, itemIndex: j };
        });

        // Kreirajte objekat reda sa informacijama o redu
        const rowObject = {
          type: Row,
          h: 510,
          collision: true,
          items: rowItems,
          style: {
            itemSpacing: 30,
          },
          rowIndex: i, // Dodajte rowIndex ovde
        };

        return rowObject; // Vratite objekat reda
      })
    );
    page.patch({
      Column: undefined,
    });

    page.patch(
      {
        Column: {
          type: Column,
          collision: true,
          plinko: true,
          x: 150,
          y: 20,
          scrollIndex: 1,
          lazyUpCount: 2,
          lazyUpCountBuffer: 2,
        },
      },
      true
    );
    page._Column.items = [];
    if (pageItems.length == 0) {
      page._Text.text = `Oops! It looks like there’s nothing here yet. Stay tuned for exciting content coming soon!`;
      page._Column.items = [];
    } else {
      page._Text.text = '';
      if (page._Column) {
        page._Column.items = pageItems;
      } else {
        page._Column = { items: [] };
      }
    }

    page._Column.scrollIndex = 1;
    page._Column.lazyUpCount = 2;
    page._Column.lazyUpCountBuffer = 2;
  } catch (error) {
    console.error('Error fetching media:', error);
  }
}
