import { Colors } from '@lightningjs/sdk';
import { Row } from '@lightningjs/ui-components';

export default class VODRow extends Row {
  static _template() {
    return {
      Title: {
        text: {
          x: 0,
          y: 0,
          color: Colors('#adadad').get(),
          fontSize: 30,
          fontStyle: 'normal bold',
        },
        zIndex: 5,
      },
    };
  }

  _attach() {
    super._attach && super._attach();
    this.patch({
      Items: {
        x: this.x,
        y: this.y,
      },
    });
  }
  get title() {
    return this.Title.text._text;
  }

  set title(val) {
    this.Title.text = val;
  }

  get Title() {
    return this.tag('Title');
  }
  _updatePosition() {
    super._attach && super._attach();
    this.patch({
      Items: {
        x: 20,
        y: 80,
      },
    });
  }
}
