import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

// Components
import SubscriptionInput from '../../TransferSettings/SubscriptionInput';
// import Keyboard from '../../../../Register/components/Keyboard';

// API Calls
import { getPassword } from '../../../../../api/settings/index';
import MessageBox from '../../../MessageBox';
import Keyboard from '../../../../../components/Keyboard/components/Keyboard';

export default class ShowContent extends lng.Component {
  static _template() {
    return {
      Modal: {
        x: -170,
        y: -200,
        w: 1920,
        h: 1080,
        collision: true,
        rect: true,
        zIndex: 205,
        color: Colors('#000000').alpha(0.8).get(),
        flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
        Title: { text: { text: 'Enter Child Lock Password' } },
        Wrapper: {
          flex: { direction: 'row', justifyContent: 'center' },
          InputField: {
            type: SubscriptionInput,
            y: 50,
            w: 700,
            h: 100,
            collision: true,
            zIndex: 500,
            placeholder: 'Lock Password',
            isPassword: true,
          },
        },
      },
      Keyboard: {
        type: Keyboard,
        w: 1920,
        h: 1080,
        y: 550,
        x: -150,
        rect: true,
        color: Colors('#000000').alpha(0).get(),
        flex: { direction: 'row', justifyContent: 'center' },
        zIndex: 205,
        visible: false,
        limit: 4,
      },
    };
  }

  get _InputField() {
    return this.tag('InputField');
  }

  _active() {
    this._setState('InputField');
  }

  async $pressedDone() {
    this.tag('Keyboard').visible = false;
    this._setState('InputField');
    this._InputField.tag('Input').listening = false;
    const inputValue = this._InputField.tag('Input').value;
    const { data } = await getPassword();
    if (inputValue == '') {
      this.fireAncestors('$hideModal', { flag: '+18', quality: 'empty' });
    }
    if (inputValue == data[0]) {
      this.fireAncestors('$hideModal', { flag: '+18', quality: 'ON' });
    } else {
      this.fireAncestors('$hideModal', { flag: '+18', quality: 'wrong' });
    }
  }

  $showKeyboard() {
    this.tag('Keyboard').patch({
      visible: true,
    });
    this._InputField.tag('Input').listening = true;
    this._setState('Keyboard');
  }

  _handleHover() {
    return false;
  }

  $setFocusKeyboard() {
    this._setState('Keyboard');
  }

  $setState(state) {
    this._setState(state);
  }

  static _states() {
    return [
      class InputField extends this {
        _getFocused() {
          return this.tag('InputField');
        }
      },
      class Keyboard extends this {
        _getFocused() {
          return this.tag('Keyboard');
        }
      },
    ];
  }
}
