// Components
import SubscriptionInput from './SubscriptionInput';
// import Keyboard from '../../../Register/components/Keyboard';
import ConnectButton from '../UserSettings/ConnectButton';
import lng from '@lightningjs/core';
import MessageBox from '../../MessageBox';
import { transfer } from '../../../../api/settings/index';
import { Colors, Router } from '@lightningjs/sdk';
import Keyboard from '../../../../components/Keyboard/components/Keyboard';

export default class Transfer extends lng.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      Modal: {
        collision: true,
        w: 1800 - 170,
        h: 1080,
        flex: { direction: 'column', alignItems: 'center' },
        Title: {
          y: 70,
          text: { text: 'Subscription Transfer', fontSize: 50 },
        },
        Description: {
          y: 150,
          w: 885,
          text: {
            text: 'To use this feature you must unlock subscription transfer status on the managment panel. Please enter subscription id.',
            fontSize: 30,
            lineHeight: 40,
            letterSpacing: 1,
          },
        },
        SubscriptionContainer: {
          y: 200,
          flex: { direction: 'row' },
          SubscriptionInput: {
            type: SubscriptionInput,
            w: 600,
            h: 80,
            collision: true,
            placeholder: 'Subscription ID',
            isPassword: false,
          },
          Button: {
            x: 30,
            type: ConnectButton,
            w: 200,
            h: 80,
            buttonInfo: { title: 'Connect' },
          },
        },
      },

      Keyboard: {
        type: Keyboard,
        x: -170,
        y: 550,
        w: 1920,
        h: 1080,
        flex: { direction: 'row', justifyContent: 'center' },
        zIndex: 99,
        visible: false,
        limit: 50,
      },
    };
  }

  get _InputField() {
    return this.tag('SubscriptionInput');
  }

  get _Button() {
    return this.tag('Button');
  }

  _active() {
    this._setState('InputField');
  }

  _handleHover() {
    this.fireAncestors('$changeState', 'SettingContainer');
  }
  $setFocusKeyboard() {
    this._setState('Keyboard');
  }

  $pressedDone() {
    this.tag('Keyboard').visible = false;
    this._setState('InputField');
    this._InputField.tag('Input').listening = false;
  }

  $hideKeyboard() {
    this.tag('Keyboard').visible = false;
    this._InputField.tag('Input').listening = false;
  }

  $showKeyboard() {
    this.tag('Keyboard').patch({
      visible: true,
    });
    this._InputField.tag('Input').listening = true;
    this._setState('Keyboard');
  }

  _setMessage(msg) {
    this.tag('Modal').patch({
      MessageBox: {
        type: MessageBox,
        y: 500,
        w: 550,
        h: 60,
        message: msg,
      },
    });
    setTimeout(() => {
      this.tag('Modal').patch({
        MessageBox: undefined,
      });
    }, 2000);
  }

  async $checkCode() {
    const subscriptionId = this._InputField.tag('Input').value;

    if (subscriptionId === '') {
      this._setMessage('Usercode cannot be empty!');
      return;
    }
    try {
      const data = await transfer(subscriptionId);

      if (data === undefined) {
        this._setMessage('Cannot login');
      } else {
        this._setMessage('Successful');
      }
    } catch (error) {
      console.error('Error calling transfer function:', error);
      this._setMessage('Cannot login');
    }
  }

  $setState(state) {
    this._setState(state);
  }

  _handleBack() {
    if (this.tag('Keyboard').visible == true) {
      this.tag('Keyboard').visible = false;
      this._InputField.tag('Input').listening = false;
      this._setState('InputField');
    } else {
      Router.back();
    }
  }

  static _states() {
    return [
      class InputField extends this {
        _getFocused() {
          return this._InputField;
        }

        _handleRight() {
          this._setState('Button');
        }
      },
      class Button extends this {
        _getFocused() {
          return this._Button;
        }

        _handleLeft() {
          this._setState('InputField');
        }
      },
      class Keyboard extends this {
        _getFocused() {
          return this.tag('Keyboard');
        }

        _handleLeft() {
          return true;
        }

        _handleUp() {
          return true;
        }
      },
    ];
  }
}
