import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
import { setQuality } from '../../../../../api/settings/index';

export default class Button extends lng.Component {
  static _template() {
    return {
      Button: {
        color: Colors('#42424200').get(),
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        Title: { y: 3 },
      },
    };
  }
  set option(val) {
    this._option = val;
  }

  set index(val) {
    this._index = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  _enable() {
    this._Button.patch({
      w: this.w,
      h: this.h,
      texture: lng.Tools.getRoundRect(this.w, this.h, 10, 3, false),
    });
    this.tag('Title').patch({
      text: { text: this._option.title },
    });
  }

  async _handleEnter() {
    setQuality(this._option.title);
    this.fireAncestors('$hideModal', { flag: 'quality', quality: this._option.title });
  }

  _handleClick() {
    this._handleEnter();
  }

  _handleHover(target) {
    this.fireAncestors('$setFocus', this._index);
    this._focus();
    return false;
  }

  _handleUnhover() {
    this._unfocus();
  }

  _focus() {
    this._Button.patch({ color: Colors('#C40000').get() });
  }
  _unfocus() {
    this._Button.patch({ color: Colors('#42424200').get() });
  }
}
