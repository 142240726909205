import lng from '@lightningjs/core';
import { Colors, Utils } from '@lightningjs/sdk';
import { Marquee } from '@lightningjs/ui-components';
let focusedItem = undefined;
export default class ChannelButton extends lng.Component {
  constructor(props) {
    super(props);
    // Timeout that will fire the marquee animation if the user is on one channel longer than a second
    this._marqueeTimeout = undefined;
    this._selected = false;
  }
  static _template() {
    return {
      Wrapper: {
        x: 30,
        y: 10,
        Button: {
          collision: true,
          flex: { direction: 'row', alignItems: 'center', justifyContent: 'flex-start' },
          Content: {
            w: 400,
            h: 70,
            x: 30,

            flex: { direction: 'row', justifyContent: 'space-between', alignItems: 'center' },
            ChannelNumber: {
              y: 5,
            },
            ChannelIcon: {
              y: -3,
            },
            ChannelName: {
              type: Marquee,
              y: -20,
              w: 200,
            },
          },
        },
      },
    };
  }
  set channelInfo(val) {
    this._channelInfo = val;
  }

  set index(val) {
    this._index = val;
  }

  get _ChannelName() {
    return this.tag('ChannelName');
  }

  get _Button() {
    return this.tag('Button');
  }

  _enable() {
    this._Button.patch({
      // w: this.w,
      // h: this.h,
      // texture: lng.Tools.getRoundRect(this.w, this.h, 5, 3, false),
      // color: Colors('#23232300').get(),
      w: this.w - 50,
      h: this.h,
    });
    this.tag('ChannelNumber').patch({
      text: { text: this._index.toString().padStart(4, '0') },
    });
    this.tag('ChannelIcon').patch({
      texture: lng.Tools.getSvgTexture(
        this._channelInfo.image_url == 'test'
          ? Utils.asset('/icons/tv_default_icon.SVG')
          : this._channelInfo.image_url,
        60,
        60
      ),
    });
    this.tag('ChannelName').patch({
      title: {
        maxLines: 1,
        fontSize: 30,
        // wordWrapWidth: 200,
        text: this._channelInfo.title,
      },
    });
  }

  _selectButton() {
    this._selected = true;
    this._Button.patch({
      texture: lng.Tools.getRoundRect(this.w, this.h, 5, 3, Colors('#C40000').get(), false),
    });
  }

  _unselectButton() {
    this._selected = false;
    this._Button.patch({
      texture: null,
    });
  }

  _focus() {
    this.fireAncestors('$unselectFocusChannel', focusedItem);
    super._focus();
    this._Button.patch({
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        5,
        3,
        Colors('#C40000').get(),
        true,
        Colors('#C40000').get()
      ),
    });
    this._marqueeTimeout = setTimeout(() => {
      this._ChannelName.patch({
        autoStart: true,
        repeat: -1,
      });
    }, 1200);
  }

  _unfocus() {
    if (this._selected) {
      this._Button.patch({
        texture: lng.Tools.getRoundRect(this.w, this.h, 5, 3, Colors('#C40000').get(), false),
      });
    } else {
      this._Button.patch({
        texture: null,
      });
    }
    clearTimeout(this._marqueeTimeout);
    this._ChannelName.patch({
      autoStart: false,
      repeat: 0,
    });
  }

  _handleHover() {
    this.fireAncestors('$setFocus', this._index - 1);
    this.fireAncestors('$unselectCurrentIndex');
    focusedItem = this._index - 1;
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this.fireAncestors('$setFocus', this._index - 1);
    this._handleEnter();
  }

  _handleScroll() {
    this.fireAncestors('$scroll');
  }

  _handleEnter() {
    this.fireAncestors('$setBackStatus', false);
    if (this._selected == false) {
      this.fireAncestors('$selectButton', this._index - 1);
    } else {
      this.fireAncestors('$hideChannelList');
      this.fireAncestors('$showChannelContainer');
      return;
    }
    this.fireAncestors('$newChannel', { channel: this._channelInfo, index: this._index });
    this._focus();
  }
}
