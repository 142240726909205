import Lightning from '@lightningjs/sdk/src/Lightning';
import { Column } from '@lightningjs/ui-components';
import { Router } from '@lightningjs/sdk';

// Components
import SubscriptionButton from '../UserSettings/SubscriptionsButtons';
import { getQuality } from '../../../../api/settings/index';
import QualityModal from './QualityModal/QualityModal';
import ShowContent from './ShowContent/ShowContent';
import MessageBox from '../../MessageBox';
import ChangePinModal from './ChangePinModal/ChangePinModal';

let AdultContent = localStorage.getItem('adultContent');

export default class GeneralSettings extends Lightning.Component {
  static _template() {
    return {
      Wrapper: {
        w: 1000,
        h: 700,
        Title: {
          text: { text: 'General Settings' },
        },
        GeneralSettingsOptions: {
          type: Column,
          y: 120,
          collision: true,
        },
      },
    };
  }

  async _enable() {
    let { data } = await getQuality().catch(() => ({ data: [] })); // Koristimo prazan niz ako se dogodi greška
    let quality = data && data.length > 0 && data[0].length > 0 ? data : 'DEFAULT'; // Provjeravamo postojanje niza i njegovu duljinu
    let AdultContent = localStorage.getItem('adultContent');
    const listOfGeneralSettings = [
      { id: 1, title: 'Change Quality', option: quality },
      { id: 2, title: '+18', option: AdultContent ? AdultContent : 'OFF' },
      { id: 3, title: 'Change Pin', option: '****' },
    ];
    this.tag('GeneralSettingsOptions').patch({
      items: listOfGeneralSettings.map((list, index) => ({
        type: SubscriptionButton,
        collision: true,
        buttonInfo: list,
        index: index,
        w: 1050,
        h: 70,
      })),
    });
  }

  $showModal(title) {
    switch (title) {
      case 'quality':
        this.patch({
          Modal: {
            visible: true,
            type: QualityModal,
          },
        });
        this._setState('Modal');
        break;
      case 'show+18':
        this.patch({
          Modal: {
            visible: true,
            type: ShowContent,
          },
        });
        this._setState('Modal');
        break;
      case 'changePin':
        this.patch({
          Modal: {
            visible: true,
            type: ChangePinModal,
          },
        });
        this._setState('Modal');
        break;
    }
  }

  _setMessage(msg) {
    this.patch({
      MessageBox: {
        type: MessageBox,
        y: 760,
        x: 550,
        w: 550,
        h: 90,
        message: msg,
      },
    });
    setTimeout(() => {
      this.patch({
        MessageBox: undefined,
      });
    }, 2000);
  }

  $hideModal({ flag, quality }) {
    this.tag('Modal').patch({
      visible: false,
    });
    this._setState('GeneralSettingsOptions');
    if (flag == 'quality') {
      this.tag('GeneralSettingsOptions').items[0].tag('Button.Data.Info').text = quality;
    }
    if (flag == '+18') {
      if (quality == 'wrong') {
        this._setMessage('Wrong pin!');
      } else if (quality == 'empty') {
        this._setMessage('Empty pin!');
      } else {
        this.tag('GeneralSettingsOptions').items[1].tag('Button.Data.Info').text = quality;
        localStorage.setItem('adultContent', quality);
        this.fireAncestors('$updateSidebar');
      }
    }
    if (flag == 'changePin') {
      if (quality == 'wrong') {
        this._setMessage('Wrong pin!');
      } else if (quality == 'empty') {
        this._setMessage('Empty pin!');
      } else if (quality == '') {
        this.patch({
          Modal: undefined,
        });

        return;
      } else {
        this._setMessage(`New pin: ${quality}`);
      }
    }

    this.patch({
      Modal: undefined,
    });
  }

  _handleBack() {
    if (this.tag('Modal') && this.tag('Modal').visible == true) {
      this.patch({
        Modal: undefined,
      });
      this._setState('GeneralSettingsOptions');
    } else {
      Router.back();
    }
  }

  _active() {
    this._setState('GeneralSettingsOptions');
  }

  $setFocus(index) {
    this.tag('GeneralSettingsOptions').selectedIndex = index;
  }

  static _states() {
    return [
      class GeneralSettingsOptions extends this {
        _getFocused() {
          return this.tag('GeneralSettingsOptions');
        }
        _handleHover() {
          console.log('General Hover');
        }
      },
      class Modal extends this {
        _handleUp() {
          return true;
        }
        _handleLeft() {
          return true;
        }

        _handleHover() {
          console.log('Modal Hover');
        }
        _getFocused() {
          return this.tag('Modal');
        }
      },
    ];
  }
}
