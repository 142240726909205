import { Row } from '@lightningjs/ui-components';

// API Calls
import { getMovies } from '../../../api/movies/index';
import { getSeries } from '../../../api/series/index';
import { getMyList } from '../../../api/myList';
import { getIndex } from '../../../api/index/index';

// Components
import KidCard from '../../Kids/components/KidCard';

export default async function (page) {
  const [myListResponse, moviesResponse, seriesResponse, indexResponse] = await Promise.all([
    getMyList(),
    getMovies(),
    getSeries(),
    getIndex(),
  ]);

  const { data } = myListResponse;
  const VODList = moviesResponse;
  const Series = seriesResponse;
  const index = indexResponse;

  const genres = index.data.index.genres;
  const languages = index.data.index.languages;

  const movies = VODList.data.vod
    .filter((vod) => data.includes(vod.content_id.toString()))
    .map((movie) => ({
      ...movie,
      flag: 'movies',
      genres: getGenresByIds(genres, movie.genre_id),
      language: getLanguageById(languages, movie.language_id),
    }));

  const series = Series.data.tvseries
    .filter((serie) => data.includes(serie.content_id.toString()))
    .map((serie) => ({
      ...serie,
      flag: 'series',
      genres: getGenresByIds(genres, serie.genre_id),
      language: getLanguageById(languages, serie.language_id),
    }));

  const myList = [...movies, ...series];

  const items = myList.map((list, index) => ({
    type: KidCard,
    w: 265,
    h: 510,
    data: list,
    index: index,
  }));

  const pageItems = Array.from({ length: Math.ceil(items.length / 6) }).map((_, i) => {
    const start = i * 6;
    const end = start + 6;
    const rowItems = items.slice(start, end).map((item, j) => {
      return { ...item, rowIndex: i, itemIndex: j };
    });

    return {
      type: Row,
      h: 510,
      items: rowItems,
      collision: true,
      style: {
        itemSpacing: 30,
      },
    };
  });

  if (pageItems.length === 0) {
    page._Text.text = `Your list is empty. Your favourite items will be displayed here.`;
    page._Column.items = [];
  } else {
    page._Text.text = '';
    if (page._Column) {
      page._Column.items = pageItems;
    } else {
      page._Column = { items: [] };
    }
  }
}

function getGenresByIds(genres, ids) {
  const genreIds = ids.split(',').map((id) => parseInt(id.trim(), 10));
  const genreNames = genreIds.map((id) => {
    const genre = genres.find((g) => g.id === id);
    return genre ? genre.title : null;
  });
  return genreNames.filter((name) => name !== null);
}

function getLanguageById(languages, id) {
  const language = languages.find((language) => language.id === id);
  return language && language.title;
}
