import lng from '@lightningjs/core';
import { Colors, Utils } from '@lightningjs/sdk';

export default class ContentSettingsButton extends lng.Component {
  static _template() {
    return {
      Button: {
        collision: true,
        rect: true,
        color: Colors('#232323').get(),
        flex: {
          direction: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        Text: {
          x: 40,
          y: 30,
          h: 100,
          text: {
            fontSize: 25,
            color: Colors('#fafafa').get(),
            maxLines: 1,
            textAlign: 'center',
          },
        },
        Icon: {
          x: -20,
          texture: lng.Tools.getSvgTexture(Utils.asset('/images/arrow-right.svg'), 70, 70),
        },
      },
    };
  }

  set index(val) {
    this._index = val;
  }

  set buttonInfo(val) {
    this._buttonInfo = val;
  }

  set indicator(val) {
    this._indicator = val;
  }
  set index(val) {
    this._index = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  get _Text() {
    return this.tag('Text');
  }

  _enable() {
    this._Button.patch({
      w: this.w,
      h: this.h,
      texture: lng.Tools.getRoundRect(this.w, this.h, 13, 3, false),
    });

    this._Text.patch({
      text: { text: this._buttonInfo.title, fontSize: 30, maxLines: 1 },
    });
  }

  _handleEnter() {
    switch (this._buttonInfo.id) {
      case 1:
        this.fireAncestors('$clearWatchHistory', 'movies');
        this.fireAncestors('$showMessage', this._buttonInfo.title);
        break;
      case 2:
        this.fireAncestors('$clearWatchHistory', 'series');
        this.fireAncestors('$showMessage', this._buttonInfo.title);

        break;
      case 3:
        this.fireAncestors('$clearMoviesFavourites');
        this.fireAncestors('$showMessage', this._buttonInfo.title);
        break;

      case 4:
        this.fireAncestors('$clearSeriesFavourites');
        this.fireAncestors('$showMessage', this._buttonInfo.title);
        break;

      case 5:
        this.fireAncestors('$clearWatchHistory', 'lastChannels');
        this.fireAncestors('$showMessage', this._buttonInfo.title);
        break;
      case 6:
        this.fireAncestors('$clearMoviesFavourites');
        this.fireAncestors('$clearSeriesFavourites');
        this.fireAncestors('$clearWatchHistory', 'series');
        this.fireAncestors('$clearWatchHistory', 'movies');
        this.fireAncestors('$clearWatchHistory', 'lastChannels');
        this.fireAncestors('$showMessage', this._buttonInfo.title);
        break;
    }
  }

  _focus() {
    this._Button.patch({ color: Colors('#C40000').get() });
  }

  _unfocus() {
    this._Button.patch({ color: Colors('#232323').get() });
  }

  _handleClick() {
    this._handleEnter();
  }

  _handleHover() {
    this.fireAncestors('$changeState', 'SettingContainer');
    this.fireAncestors('$setFocus', this._index);
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }
}
