import lightning from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

const FILTER_BORDER_RADIUS = 5;

export default class DescriptionButton extends lightning.Component {
  static _template() {
    return {
      Button: {
        ContentTitle: {
          w: this.w,
          h: this.h,
          flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
        },
        FilterBorder: {},
      },
    };
  }

  set buttonInfo(value) {
    this._buttonInfo = value;
  }

  get _filterBorder() {
    return this.tag('FilterBorder');
  }

  get _contentTitle() {
    return this.tag('ContentTitle');
  }

  _handleEnter() {}

  _enable() {
    this.tag('Button').patch({
      y: 10,
      x: 20,
    });

    this._contentTitle.patch({
      zIndex: 100,
      x: 20,
      y: 6,
      w: this.w,
      h: this.h,
      text: {
        text: `${this._buttonInfo?.startTime} - ${this._buttonInfo?.stopTime}  ${this._buttonInfo?.description}`,
        maxLines: 2,
        fontSize: 25,
        textDirection: 'rtl',
      },
    });
  }

  _focus() {
    this._filterBorder.patch({
      texture: lightning.Tools.getRoundRect(
        this.w + 30,
        this.h,
        FILTER_BORDER_RADIUS,
        FILTER_BORDER_RADIUS,
        Colors('#C40000').get(),
        true,
        Colors('#C40000').get()
      ),
    });
  }

  _unfocus() {
    this._filterBorder.patch({
      texture: null,
    });
  }
}
