import Lightning from '@lightningjs/sdk/src/Lightning';
import LanguageSection from './LanguageSection';
import CategoriesSection from './CategoriesSection';
import ChannelListSection, { defaultChannelColumnOptions } from './ChannelListSection';
import ChannelOptionsSection from './ChannelOptionsSection';
import { ExpandedPlayer, SmallPlayer } from '../PlayerSection/PlayerSection';
import LiveChannelsButton from '../Buttons/LiveChannelsButton';
import ChannelListButton from '../Buttons/ChannelListButton';
import { getLock } from '../../../../api/settings/index';
import { getMyList } from '../../../../api/myList/index';

export default class MenuSection extends Lightning.Component {
  constructor(props) {
    super(props);
    this._languageIndex = 0;
    this._categoryIndex = 0;
    this._channelIndex = 0;
    this._sortABC = false;
    this._updatedChannels;
    this._originalChannels;
  }

  static _template() {
    return {
      y: 20,
      flex: { direction: 'row', alignItems: 'center' },
      LanguageSection: {
        type: LanguageSection,
      },
      CategoriesSection: {
        collision: true,
        type: CategoriesSection,
      },
      ChannelListSection: {
        collision: true,
        type: ChannelListSection,
      },
      ChannelOptionsSection: {
        type: ChannelOptionsSection,
      },
    };
  }

  set languagesDictionary(value) {
    this._languagesDictionary = value;
  }

  get _LanguageSection() {
    return this.tag('LanguageSection');
  }

  get _CategoriesSection() {
    return this.tag('CategoriesSection');
  }

  get _ChannelListSection() {
    return this.tag('ChannelListSection');
  }

  get _ChannelOptionsSection() {
    return this.tag('ChannelOptionsSection');
  }

  _initMenu(languagesDictionary, languages, categories, channelList) {
    this.languagesDictionary = languagesDictionary;
    this.totalChannalNumber = channelList.length;
    this._LanguageSection._initLanguages(languages);
    this._CategoriesSection._initCategories(categories);
    this._ChannelListSection._initChannels(channelList);
  }

  _firstActive() {
    this._setState('LanguageSection');
    this._originalChannels =
      this._languagesDictionary[this._languageIndex].categories[this._categoryIndex].channels;

    this._updatedChannels = this._updatedChannels
      ? this._updatedChannels
      : this._languagesDictionary[this._languageIndex].categories[this._categoryIndex].channels;
    //   this._updatedChannels =
    //     this._languagesDictionary[this._languageIndex].categories[this._categoryIndex].channels;
    // }
    this._ChannelOptionsSection._setIcon(this._sortABC);
  }

  _nextChannel() {
    this.$playNewChannel({
      index: Math.min(this.totalChannalNumber - 1, this._channelIndex + 1),
      flag: false,
    });
  }

  _firstChannel() {
    this.$playNewChannel({
      index: Math.min(this.totalChannalNumber - 1, this._channelIndex),
      flag: false,
    });
  }

  _prevChannel() {
    this.$playNewChannel({ index: Math.max(0, this._channelIndex - 1), flag: false });
  }

  _playChannelActive() {
    if (this._channelIndex != undefined) {
      this.$playNewChannel({ index: this._channelIndex, flag: false });
    }
  }

  _playFirstChannel() {
    if (this._updatedChannels.length > 0) {
      this.$playNewChannel({ index: 0, flag: false });
      this._ChannelListSection._selectChannelItem(0);
    }
  }

  $navigateChannels(val) {
    const currentIndex = this._ChannelListSection._Column.selectedIndex;
    const allChannels = this._updatedChannels;
    const loadedLength = allChannels.length;

    let nextIndex = currentIndex + val;

    if (nextIndex < 0) {
      nextIndex = 0; // Ako je manje od nule, vrati na početak liste
    } else if (nextIndex >= loadedLength) {
      nextIndex = loadedLength - 1; // Postavi na poslednji indeks liste
    }

    this._ChannelListSection._Column.scrollTo(nextIndex, 80);
  }

  // MOJ KOD JE OVAJ ISPOD
  $lastChannelFired(info) {
    this._LanguageSection._Column.items[this._languageIndex]._unselectButton();
    this._LanguageSection._Column.items[info.lngIndex]._selectButton();

    this._languageIndex = info.lngIndex;

    const categories = this._languagesDictionary[this._languageIndex].categories.map(
      (item, index) => ({
        type: LiveChannelsButton,
        buttonTitle: item.title,
        onEnter: '$categoryChange',
        onEnterProps: index,
      })
    );
    this._CategoriesSection._Column.items = categories;

    this.$updateCategoryLastChannel(info.categoryIndex, info.index);
  }
  // ENDING MOJ KOD

  async $updateCategoryLastChannel(index, channelIndex) {
    this.fireAncestors('$setIsSearch', false);
    this._CategoriesSection._Column?.items[this._categoryIndex]?._unselectButton();
    this._CategoriesSection._Column.items[index]._selectButton();

    this._categoryIndex = index;

    const lockedChannels = await getLock();
    const favouriteList = await getMyList();
    const liveChannels = this._languagesDictionary[this._languageIndex].categories[
      this._categoryIndex
    ].channels.map((item, index) => ({
      type: ChannelListButton,
      w: 500,
      h: 65,
      buttonInfo: item,
      index,
      flag: false,
      isFavourite: favouriteList.data.includes(item.content_id.toString()) ? true : false,
      isLocked: lockedChannels.data.includes(item.content_id) ? true : false,
    }));

    // Small hack for better rendering of elements, instead of updating existing Column
    this._ChannelListSection._ColumnWrapper.patch({
      Column: undefined,
    });
    this._ChannelListSection._ColumnWrapper.patch({
      Column: {
        ...defaultChannelColumnOptions,
        items: liveChannels,
      },
    });
    this._ChannelListSection._channelList =
      this._languagesDictionary[this._languageIndex].categories[this._categoryIndex].channels;
    // this.fireAncestors('$adjustPlayerSize', ExpandedPlayer);
    // this._setState('ChannelListSection');
    this._originalChannels =
      this._languagesDictionary[this._languageIndex].categories[this._categoryIndex].channels;

    this._updatedChannels =
      this._languagesDictionary[this._languageIndex].categories[this._categoryIndex].channels;
    this.$playNewChannel({ index: channelIndex, flag: false });
    this.fireAncestors('$checkFavourite');
  }

  // On language change, update categories
  $languageChange(index) {
    this.fireAncestors('$setIsSearch', false);
    this.fireAncestors('$preventExitStatus');
    this._LanguageSection._Column.items[this._languageIndex]._unselectButton();
    this._LanguageSection._Column.items[index]._selectButton();

    this._languageIndex = index;

    const categories = this._languagesDictionary[this._languageIndex].categories.map(
      (item, index) => ({
        type: LiveChannelsButton,
        buttonTitle: item.title,
        onEnter: '$categoryChange',
        onEnterProps: index,
      })
    );
    this._CategoriesSection._Column.items = categories;
    this.$categoryChange(0);
  }

  async $showFavourites(index) {
    const lockedChannels = await getLock();
    const favouriteList = await getMyList();
    const liveChannels = this._languagesDictionary[0].categories[0];

    // Pronađi kanale u listi favorita s istim ID-jevima kao trenutni kanal
    const favouriteChannelIds = favouriteList.data.map((favourite) => favourite);
    const favouriteChannels = liveChannels.channels.filter((channel) =>
      favouriteChannelIds.includes(channel.content_id.toString())
    );

    if (favouriteChannels.length > 0) {
      this._CategoriesSection._Column?.items[this._categoryIndex]?._unselectButton();
      this._CategoriesSection._Column.items[index]._selectButton();
      this._categoryIndex = index;

      const updatedChannes = favouriteChannels.map((item, index) => ({
        type: ChannelListButton,
        w: 500,
        h: 65,
        buttonInfo: item,
        index: index,
        flag: false,
        isFavourite: true,
        isLocked: lockedChannels.data.includes(item.content_id) ? true : false,
      }));

      this._ChannelListSection._ColumnWrapper.patch({
        Column: undefined,
      });
      this._ChannelListSection._ColumnWrapper.patch({
        Column: {
          ...defaultChannelColumnOptions,
          items: updatedChannes,
        },
      });
      this._ChannelListSection._channelList = favouriteChannels;
      this.fireAncestors('$adjustPlayerSize', ExpandedPlayer);
      this._setState('ChannelListSection');
      this._originalChannels = favouriteChannels;
      this._updatedChannels = favouriteChannels;
      this.$playNewChannel({ index: 0, flag: false });
      this.fireAncestors('$checkFavourite');
      this._sortABC = false;
      this._ChannelOptionsSection._setIcon(this._sortABC);
    } else {
      this.fireAncestors('$showMessage');
    }
  }

  // On category change, update channel list
  async $categoryChange(index) {
    this.fireAncestors('$setIsSearch', false);

    this._CategoriesSection._Column?.items[this._categoryIndex]?._unselectButton();
    this._CategoriesSection._Column.items[index]._selectButton();

    this._categoryIndex = index;

    const lockedChannels = await getLock();
    const favouriteList = await getMyList();
    const liveChannels = this._languagesDictionary[this._languageIndex].categories[
      this._categoryIndex
    ].channels.map((item, index) => ({
      type: ChannelListButton,
      w: 500,
      h: 65,
      buttonInfo: item,
      index: index,
      flag: false,
      isFavourite: favouriteList.data.includes(item.content_id.toString()) ? true : false,
      isLocked: lockedChannels.data.includes(item.content_id) ? true : false,
    }));

    // Small hack for better rendering of elements, instead of updating existing Column
    this._ChannelListSection._ColumnWrapper.patch({
      Column: undefined,
    });
    this._ChannelListSection._ColumnWrapper.patch({
      Column: {
        ...defaultChannelColumnOptions,
        items: liveChannels,
      },
    });
    this._ChannelListSection._channelList =
      this._languagesDictionary[this._languageIndex].categories[this._categoryIndex].channels;
    this.fireAncestors('$adjustPlayerSize', ExpandedPlayer);
    this._setState('ChannelListSection');
    this._updatedChannels =
      this._languagesDictionary[this._languageIndex].categories[this._categoryIndex].channels;
    this._originalChannels =
      this._languagesDictionary[this._languageIndex].categories[this._categoryIndex].channels;
    this.$playNewChannel({ index: 0, flag: false });
    this.fireAncestors('$checkFavourite');
    this._sortABC = false;
    this._ChannelOptionsSection._setIcon(this._sortABC);
  }

  // MOJE JE ISPOD
  async _updateChannels(channels, status) {
    const lockedChannels = await getLock();
    const favouriteList = await getMyList();

    const liveChannels = channels.map((item, index) => ({
      type: ChannelListButton,
      w: 500,
      h: 65,
      buttonInfo: item,
      index: index,
      flag: 'certainChannels',
      isFavourite: favouriteList.data.includes(item.content_id.toString()) ? true : false,
      isLocked: lockedChannels.data.includes(item.content_id) ? true : false,
    }));
    // this._ChannelListSection._ColumnWrapper.patch({
    //   Column: undefined,
    // });
    // this._ChannelListSection._ColumnWrapper.patch({
    //   Column: {
    //     ...defaultChannelColumnOptions,
    //     items: liveChannels,
    //   },
    // });
    let index = channels.findIndex(
      (channel) => channel?.title === this._updatedChannels[this._channelIndex]?.title
    );
    this.totalChannalNumber = channels.length;
    this._updatedChannels = channels;
    this._originalChannels = channels;
    this._setState('ChannelListSection');
    if (status) {
      this._ChannelListSection._initChannelss(liveChannels, index);
    } else {
      this._ChannelListSection._initChannels(liveChannels);
    }
    // this._ChannelListSection._selectChannelItem(0);
  }

  async $playNewChannel({ index, flag }) {
    // Retrieve existing channels array from local storage
    const existingChannelsArray = JSON.parse(localStorage.getItem('lastChannels')) || [];
    // Create a new channel object
    let newChannelInfo;
    if (flag) {
      newChannelInfo = this._updatedChannels[index];
    } else {
      newChannelInfo = this._updatedChannels[index];
    }

    // Clear existing timeout, if any
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    const startTime = Date.now();

    this._ChannelListSection._unSelectChannelItem(this._channelIndex);
    this.fireAncestors('$channelFired', newChannelInfo, index);

    this.timeoutId = setTimeout(() => {
      const elapsedTime = Date.now() - startTime;

      // Check if content_id already exists in localStorage
      const isDuplicate = existingChannelsArray.some(
        (item) => item.channelInfo.content_id === newChannelInfo.content_id
      );

      if (!isDuplicate && elapsedTime >= 60000) {
        existingChannelsArray.push({
          index,
          channelInfo: newChannelInfo,
          lngIndex: this._languageIndex,
          categoryIndex: this._categoryIndex,
        });

        localStorage.setItem('lastChannels', JSON.stringify(existingChannelsArray));
      } else {
      }
    }, 60000);
  }

  $setIndex(index) {
    this._channelIndex = index;
  }

  _updateUpdatedChannels() {
    return this._originalChannels;
  }

  async $sortChannels() {
    this._sortABC = !this._sortABC;
    // const liveChannels = [
    //   ...this._languagesDictionary[this._languageIndex].categories[this._categoryIndex].channels,
    // ];
    const liveChannels = [...this._originalChannels];
    this._updatedChannels = liveChannels;
    if (this._sortABC) liveChannels.sort((a, b) => a.title.localeCompare(b.title));
    if (this._sortABC)
      this._updatedChannels = liveChannels.sort((a, b) => a.title.localeCompare(b.title));
    const lockedChannels = await getLock();
    const favouriteList = await getMyList();

    const sortedChannels = liveChannels.map((item, index) => ({
      type: ChannelListButton,
      w: 500,
      h: 65,
      buttonInfo: item,
      index: index,
      flag: false,
      isFavourite: favouriteList.data.includes(item.content_id.toString()) ? true : false,
      isLocked: lockedChannels.data.includes(item.content_id) ? true : false,
    }));

    // Small hack for better rendering of elements, instead of updating existing Column
    this._ChannelListSection._ColumnWrapper.patch({
      Column: undefined,
    });
    this._ChannelListSection._ColumnWrapper.patch({
      Column: {
        ...defaultChannelColumnOptions,
        items: sortedChannels,
      },
    });
    // this._ChannelOptionsSection._Column.items[3].Icon.patch({
    //   texture: lng.Tools.getSvgTexture(
    //     Utils.asset(!this._sortABC ? '/icons/ic_sort_abc_d.svg' : '/icons/ic_sort_123_d.svg'),
    //     75,
    //     75
    //   ),
    //   color: Colors('#ffffff').get(),
    // });
    this.$playNewChannel({ index: 0, flag: false });
    this.fireAncestors('$checkFavourite');
    this._ChannelOptionsSection._setIcon(this._sortABC);
  }

  _setStatee() {
    this.fireAncestors('$adjustPlayerSize', ExpandedPlayer, 'MenuSectionState');
    this._setState('ChannelListSection');
    this._ChannelListSection._Column.selectedIndex = this._channelIndex;
  }

  $setState(state) {
    switch (state) {
      case 'CategoriesSection':
        this.fireAncestors('$adjustPlayerSize', SmallPlayer, 'MenuSectionState');
        this._setState(state);
        break;
      case 'LanguageSection':
        this.fireAncestors('$adjustPlayerSize', SmallPlayer, 'MenuSectionState');
        this._setState(state);
        break;
      case 'ChannelListSection':
        this.fireAncestors('$adjustPlayerSize', ExpandedPlayer, 'MenuSectionState');
        this._setState(state);
        break;
      case 'ChannelOptionsSection':
        this._setState(state);
        break;
    }
  }

  static _states() {
    return [
      class LanguageSection extends this {
        _getFocused() {
          return this._LanguageSection;
        }
        _handleRight() {
          this._CategoriesSection._Column.selectedIndex = this._categoryIndex;
          this._CategoriesSection._Column.scrollTo(this._categoryIndex, 0);
          this._setState('CategoriesSection');
        }
      },
      class CategoriesSection extends this {
        _getFocused() {
          return this._CategoriesSection;
        }
        _handleLeft() {
          this._LanguageSection._Column.selectedIndex = this._languageIndex;
          this._LanguageSection._Column.scrollTo(this._languageIndex, 0);
          this._setState('LanguageSection');
        }
        _handleRight() {
          this.fireAncestors('$adjustPlayerSize', ExpandedPlayer, 'MenuSectionState');
          this._setState('ChannelListSection');
        }
      },
      class ChannelListSection extends this {
        _getFocused() {
          return this._ChannelListSection;
        }
        _handleLeft() {
          this._CategoriesSection._Column.selectedIndex = this._categoryIndex;
          this._CategoriesSection._Column.scrollTo(this._categoryIndex, 0);
          this.fireAncestors('$adjustPlayerSize', SmallPlayer, 'MenuSectionState');
          this._setState('CategoriesSection');
        }
        _handleRight() {
          this._setState('ChannelOptionsSection');
        }
      },
      class ChannelOptionsSection extends this {
        _getFocused() {
          return this._ChannelOptionsSection;
        }
        _handleLeft() {
          this._setState('ChannelListSection');
        }

        _handleRight() {
          this.fireAncestors('$focusEPGOrSearch');
        }
      },
    ];
  }
}
