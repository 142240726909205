import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

export default class Button extends lng.Component {
  constructor(props) {
    super(props);
    this._selected = false;
  }
  static _template() {
    return {
      collision: true,
      Button: {
        y: 50,
        zIndex: 999,
        collision: true,
        // color: Colors('#42424200').get(),
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        Title: { y: 3 },
      },
    };
  }
  set option(val) {
    this._option = val;
  }

  set index(val) {
    this._index = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  _enable() {
    this._Button.patch({ w: this.w, h: this.h });

    this.tag('Title').patch({
      text: { text: this._option.stream_title },
    });
  }

  async _handleEnter() {
    this.fireAncestors('$setQualityTitle', this._option.stream_title);
    this.fireAncestors('$hideQuality', this._option);
  }

  _focus() {
    this._Button.patch({
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        5,
        3,
        Colors('#C40000').get(),
        true,
        Colors('#C40000').get()
      ),
    });
  }

  _unfocus() {
    if (this._selected) {
      this._Button.patch({
        texture: lng.Tools.getRoundRect(this.w, this.h, 5, 3, Colors('#C40000').get(), false),
      });
    } else {
      this._Button.patch({
        texture: null,
      });
    }
  }

  _handleHover() {
    this.fireAncestors('$setFocus', this._index);
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._handleEnter();
  }

  _selectButton() {
    this._selected = true;
    this._Button.patch({
      w: this.w,
      h: this.h,
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        10,
        3,
        Colors('#C40000').get(),
        true,
        Colors('#C40000').get()
      ),
    });
  }
}
