import lng from '@lightningjs/core';

export default class Information extends lng.Component {
  static _template() {
    return {
      flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
      InformationName: { w: 400 },
      InformationValue: { w: 500 },
    };
  }

  set information(val) {
    this._information = val;
  }

  get _InformationName() {
    return this.tag('InformationName');
  }

  get _InformationValue() {
    return this.tag('InformationValue');
  }

  _enable() {
    this._InformationName.text = this._information.title;
    this._InformationValue.text = this._information.value;
  }
}
