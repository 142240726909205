import lng from '@lightningjs/core';
import { Router, Utils, VideoPlayer } from '@lightningjs/sdk';
import PlayerSection, { FullScreenPlayer } from './components/PlayerSection/PlayerSection';
import MenuSection from './components/MenuSection/MenuSection';
import SearchInput from './components/Input/SearchInput';
import Keyboard from './components/Input/Keyboard';
import Quality from './components/MenuSection/QualityModal/QualityModal';
import InfoModal from './components/MenuSection/InfoModal/InfoModal';
import LockModal from './components/MenuSection/LockModal/LockModal';
import { delLock, setLock } from '../../api/settings/index';
import LastChannelsModal from './components/MenuSection/LastChannelsModal/LastChannelsModal';
import { deleteMyList, setMyList } from '../../api/myList';
import MessageBox from '../Settings/MessageBox';
let isEPGExist;
let streamTitle;
let status = true;
let timeoutId = null;

export default class LiveChannels extends lng.Component {
  constructor(props) {
    super(props);
    this._isSearch = false;
  }
  static _template() {
    return {
      w: 1795,
      h: 1080,
      x: 125,
      clipping: true,
      Wrapper: {
        flex: { direction: 'row', alignItems: 'center' },
        MenuSection: {
          collision: true,
          type: MenuSection,
        },
        PlayerSection: {
          type: PlayerSection,
        },
      },
      Search: {
        type: SearchInput,
        x: 1620,
        w: 300,
        h: 60,
        placeholder: 'Search',
        visible: false,
        collision: true,
      },
      Keyboard: {
        type: Keyboard,
        visible: false,
      },
      QualityModal: {
        type: Quality,
        collision: true,
        visible: false,
      },
      InfoModal: {
        type: InfoModal,
        visible: false,
      },
      LockModal: {
        collision: true,
        type: LockModal,
        visible: false,
      },
      LastChannelsModal: {
        type: LastChannelsModal,
        visible: false,
      },
    };
  }

  get _PlayerSection() {
    return this.tag('PlayerSection');
  }

  get _MenuSection() {
    return this.tag('MenuSection');
  }

  $preventExitStatus() {
    this._PlayerSection._exitStatus = false;
  }

  _enable() {
    // for some reason comming to this page after
    // visiting other page deletes x property from container
    // investigate why??
    // reproduce: get to liveTv page, then got to movies, and come back
    this.$preventExitStatus();
    // this._PlayerSection._exitStatus = false;
    this.patch({
      x: 0,
    });
  }

  _disable() {
    VideoPlayer.loader(null);
    VideoPlayer.unloader(null);
    VideoPlayer.clear();
    status = true;
  }

  _setMessage(msg) {
    this.patch({
      MessageBox: {
        type: MessageBox,
        x: 650,
        y: 950,
        w: 550,
        h: 60,
        zIndex: 250,
        message: msg,
      },
    });
    setTimeout(() => {
      this.patch({
        MessageBox: undefined,
      });
    }, 2000);
  }

  _initPage(languagesDictionary, languages, categories, channelList) {
    // init the menu
    this._MenuSection._initMenu(languagesDictionary, languages, categories, channelList);

    // start at channel one
    const data = languagesDictionary[0].categories[0].channels[0];
    this.$channelFired(data, 0);
  }

  $showMessage() {
    this._setMessage('Add Channels');
  }

  $nextChannel() {
    this._MenuSection._nextChannel();
  }

  $prevChannel() {
    this._MenuSection._prevChannel();
  }

  $channelFired(data, index) {
    isEPGExist = data.epg;
    this._PlayerSection._handleNewChannel(data, index);
    this.fireAncestors('$selectChannel', index);
  }

  $findChannelButton(index) {
    return this._MenuSection._ChannelListSection._Column.items[index]?.isLocked;
  }

  $findChannelIsFav(index) {
    const isFavourite = this._MenuSection._ChannelListSection._Column.items[index]?.isFavourite;
    this._PlayerSection._TvPlayer.tag('TVChannelInfo').tag('Actions')._addLikeOrNot(isFavourite);
  }

  $setIndex(index) {
    this._MenuSection.$setIndex(index);
  }

  $lastChannelFired(info) {
    this._MenuSection.$lastChannelFired(info);
  }

  $adjustPlayerSize(size, status) {
    this._PlayerSection._currentStatus = status;
    this._PlayerSection._handleChangePlayerSize(size);
  }

  $getEpg() {
    const epg = this._PlayerSection._EPG._getEPG();
    return epg;
  }

  $checkFavourite() {
    const index = this._PlayerSection._currentIndex;
    const isFavourite = this._MenuSection._ChannelListSection._Column.items[index].isFavourite;
    if (isFavourite) {
      this._MenuSection._ChannelOptionsSection._changeFavButton(true);
    } else {
      this._MenuSection._ChannelOptionsSection._changeFavButton(false);
    }
    return isFavourite;
  }

  async $addorremove() {
    const index = this._PlayerSection._currentIndex;
    const isFavourite = this._MenuSection._ChannelListSection._Column.items[index].isFavourite;
    if (isFavourite) {
      const id = this._MenuSection._ChannelListSection._Column.items[index]._buttonInfo.content_id;
      await deleteMyList(id);
      this._MenuSection._ChannelOptionsSection._changeFavButton(false);
      this._MenuSection._ChannelListSection._Column.items[index].isFavourite = false;
      this._MenuSection._ChannelListSection._Column.items[index]._delFavIcon();
      this._PlayerSection._TvPlayer.tag('TVChannelInfo').tag('Actions')._changeFavouriteIcon(false);
    } else {
      const id = this._MenuSection._ChannelListSection._Column.items[index]._buttonInfo.content_id;
      await setMyList(id);
      this._MenuSection._ChannelOptionsSection._changeFavButton(true);
      this._MenuSection._ChannelListSection._Column.items[index].isFavourite = true;
      this._MenuSection._ChannelListSection._Column.items[index]._setFavIcon();
      this._PlayerSection._TvPlayer.tag('TVChannelInfo').tag('Actions')._changeFavouriteIcon(true);
    }
  }

  async $unlike() {
    const index = this._PlayerSection._currentIndex;
    const id = this._MenuSection._ChannelListSection._Column.items[index]._buttonInfo.content_id;
    await deleteMyList(id);
    this._MenuSection._ChannelOptionsSection._changeFavButton(false);
    this._MenuSection._ChannelListSection._Column.items[index].isFavourite = false;
    this._MenuSection._ChannelListSection._Column.items[index]._delFavIcon();
    this._PlayerSection._TvPlayer.tag('TVChannelInfo').tag('Actions')._changeFavouriteIcon(false);
  }

  async $like() {
    const index = this._PlayerSection._currentIndex;
    const id = this._MenuSection._ChannelListSection._Column.items[index]._buttonInfo.content_id;
    await setMyList(id);
    this._MenuSection._ChannelOptionsSection._changeFavButton(true);
    this._MenuSection._ChannelListSection._Column.items[index].isFavourite = true;
    this._MenuSection._ChannelListSection._Column.items[index]._setFavIcon();
    this._PlayerSection._TvPlayer.tag('TVChannelInfo').tag('Actions')._changeFavouriteIcon(true);
  }

  $showInfo() {
    this._PlayerSection._timeoutWithdrawChannelInfo();
    const epg = this._PlayerSection._EPG._getEPG();

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.patch({
      InfoModal: undefined,
    });

    this.patch({
      InfoModal: { type: InfoModal, visible: true, epg: epg },
    });

    // this._setState('InfoModal');
    this.timeoutId = setTimeout(() => {
      this.patch({
        InfoModal: { visible: false },
      });
    }, 8000);

    return epg;
  }

  $getQualityTitle() {
    return streamTitle;
  }

  $setQualityTitle(title) {
    streamTitle = title;
  }

  $showQuality() {
    this.tag('QualityModal').patch({
      visible: true,
      data: this._PlayerSection._TvPlayer.data,
    });
    this.tag('QualityModal')._showModal();
    this._setState('QualityModal');
  }

  $hideQuality(option) {
    this._PlayerSection._TvPlayer
      .tag('TVChannelInfo')
      .tag('Actions')
      ._setStreamTitle(option.stream_title);
    const playerStatus = this._PlayerSection._getPlayerStatus();
    this.tag('QualityModal').patch({
      visible: false,
    });
    if (playerStatus == undefined) {
      this._setState('MenuSectionState');
    } else {
      this._setState(playerStatus);
    }
    this._PlayerSection._TvPlayer._playCertainStream(option);
  }

  $showLockModal({ flag, index, data }) {
    this.tag('LockModal').patch({
      visible: true,
      flag,
      index,
      data,
    });
    this._setState('LockModal');
  }

  async $lockChannelFired({ flag, index, data }) {
    const playerStatus = this._PlayerSection._getPlayerStatus();
    if (flag == 'check') {
      this._PlayerSection._playChannel(data, index);
      this.tag('LockModal').visible = false;

      if (playerStatus == undefined) {
        this._setState('MenuSectionState');
      } else {
        this._setState(playerStatus);
      }
      return;
    }
    if (flag == 'unlockChannel') {
      let currentChannel = this._PlayerSection._TvPlayer.tag('TVChannelInfo')._getData();
      await delLock(currentChannel.content_id);
      this.tag('LockModal').visible = false;
      this._setState('PlayerSectionState');
      const currentIndex = this._PlayerSection.$getChannelIndex();
      this._MenuSection._ChannelListSection._Column.items[currentIndex].isLocked = false;
      this._MenuSection._ChannelListSection._Column.items[currentIndex]._delLockIcon();
    } else {
      let currentChannel = this._PlayerSection._TvPlayer.tag('TVChannelInfo')._getData();
      await setLock(currentChannel.content_id);
      this.tag('LockModal').visible = false;
      this._setState('PlayerSectionState');
      const currentIndex = this._PlayerSection.$getChannelIndex();
      this._MenuSection._ChannelListSection._Column.items[currentIndex].isLocked = true;
      this._MenuSection._ChannelListSection._Column.items[currentIndex]._setLockIcon();
    }
  }

  _active() {
    if (status) {
      this._MenuSection._playChannelActive();
      this._setState('MenuSectionState');
    }
  }

  _firstActive() {
    setTimeout(() => {
      this.$fullScreen();
      this.$firstChannel();
    }, 0);
    status = false;
  }

  $selectChannel(index) {
    this._MenuSection._ChannelListSection._selectChannelItem(index);
  }

  $firstChannel() {
    this._MenuSection._firstChannel();
  }

  $showSearch() {
    this.tag('Search').patch({
      visible: true,
    });
    this.tag('Search').tag('Input').listening = true;
    this._setState('Search');
  }

  $showMenu() {
    this.tag('MenuSection').visible = true;
    this.patch({
      w: 1920,
      h: 1080,
      x: 0,
      PlayerSection: {
        h: 1040,
        x: 5,
      },
    });
    this.tag('MenuSection').patch({
      x: 125,
    });
    this.tag('PlayerSection').patch({
      x: 130,
    });
    this._setState('MenuSectionState');
  }

  $showLastChannelsModal() {
    this.tag('LastChannelsModal').visible = true;
    this._setState('LastChannelsModal');
  }

  $hideLastChannelsModal() {
    const playerStatus = this._PlayerSection._getPlayerStatus();
    if (this.tag('LastChannelsModal').visible == true) {
      this.tag('LastChannelsModal').visible = false;
      if (playerStatus == undefined) {
        this._setState('MenuSectionState');
      } else {
        this._setState(playerStatus);
      }
      return;
    }
  }

  _handleBack() {
    const playerStatus = this._PlayerSection._getPlayerStatus();

    this.$hideLastChannelsModal();

    if (this.tag('LockModal').visible == true) {
      this.tag('LockModal').visible = false;
      this.tag('LockModal').tag('Keyboard').visible = false;
      if (playerStatus == undefined) {
        this._setState('MenuSectionState');
      } else {
        this._setState(playerStatus);
      }
      return;
    }
    if (this.tag('InfoModal').visible == true) {
      this.tag('InfoModal').visible = false;
      this._setState('PlayerSectionState');
      return;
    }
    if (this.tag('QualityModal').visible == true) {
      this.tag('QualityModal').visible = false;
      if (playerStatus == undefined) {
        this._setState('MenuSectionState');
      } else {
        this._setState(playerStatus);
      }
      return;
    }
    if (this.tag('Keyboard').visible == true) {
      this.tag('Keyboard').visible = false;
      this._setState('Search');
      this._MenuSection._playFirstChannel();
      return;
    }
    if (this.tag('Search').visible == true) {
      this.tag('Search').visible = false;
      this._setState('MenuSectionState');
      return;
    }
    const status = this._PlayerSection._exitStatus;
    if (status) {
      Router.back();
      return;
    }
    this.tag('MenuSection').visible = false;
    this.patch({
      x: 0,
      w: 1920,
      h: 1080,
      PlayerSection: {
        h: 1080,
        x: 0,
      },
    });
    this.tag('PlayerSection').patch({
      x: 5,
    });
    this.$adjustPlayerSize(FullScreenPlayer, 'PlayerSectionState');
    this._setState('PlayerSectionState');
  }

  $hideInfoModal() {
    if (this.tag('InfoModal').visible == true) {
      this.tag('InfoModal').visible = false;
      return;
    }
  }

  $fullScreen() {
    this.tag('MenuSection').visible = false;
    this.patch({
      x: 0,
      w: 1920,
      h: 1080,
      PlayerSection: {
        h: 1080,
        x: 0,
      },
    });
    this.tag('PlayerSection').patch({
      x: 5,
    });
    this.$adjustPlayerSize(FullScreenPlayer, 'PlayerSectionState');
    this._setState('PlayerSectionState');
  }

  $focusEPGOrSearch() {
    if (this.tag('Search').visible == true) {
      return this._setState('Search');
    }
    if (isEPGExist.daily === '' || isEPGExist.weekly === '') {
    } else {
      this._setState('PlayerSectionState');
    }
  }

  $setState() {
    this._setState('MenuSectionState');
  }

  $setSearch() {
    this._setState('Search');
  }

  $setToChannelList() {
    this._MenuSection._setStatee();
  }

  // $updateSearch(key) {
  //   let inputTag = this.tag('Search').tag('Input');
  //   if (!inputTag.listening) {
  //     return;
  //   }
  //   switch (key) {
  //     case 'space':
  //       inputTag.insert(' ');
  //       break;
  //     case 'delete':
  //     case 'backspace':
  //       inputTag.backspace();
  //       break;
  //     case 'clear':
  //       inputTag.clear();

  //       break;
  //     case 'done':
  //       (this.tag('Keyboard').visible = false), this._setState('Search');
  //       this._MenuSection._playFirstChannel();

  //       break;
  //     default:
  //       inputTag.insert(key);
  //   }
  //   if (inputTag.value == '') {
  //     this._isSearch = false;
  //   } else {
  //     this._isSearch = true;
  //   }
  //   this.tag('Search')._changePlaceholder();
  //   this.$updateListOfChannels(inputTag.value);
  // }

  _debounce = (func, delay) => {
    return function () {
      if (!timeoutId) {
        const context = this;
        const args = arguments;

        timeoutId = setTimeout(() => func.apply(context, args), delay);
      }
    };
  };

  _debounce = (func, delay) => {
    return function () {
      const context = this;
      const args = arguments;

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  $updateSearch(key) {
    // Prilagodite delay prema vašim potrebama (npr. 700ms)
    const inputTag = this.tag('Search').tag('Input');
    if (!inputTag.listening) {
      return;
    }

    switch (key) {
      case 'Space':
        inputTag.insert(' ');
        break;
      case 'Delete':
      case 'backspace':
        inputTag.backspace();
        break;
      case 'Clear':
        inputTag.clear();
        break;
      case 'Done':
        this.tag('Keyboard').visible = false;
        setTimeout(() => {
          this._setState('MenuSectionState');
        }, 10);
        this._MenuSection._playFirstChannel();
        break;
      default:
        inputTag.insert(key);
    }

    if (inputTag.value == '') {
      this._isSearch = false;
    } else {
      this._isSearch = true;
    }

    this.tag('Search')._changePlaceholder();

    // Pozovite debounce funkciju samo za ovaj dio koda
    this._debounce(() => {
      this.$updateListOfChannels(inputTag.value);
    }, 500)();
  }

  $setIsSearch(status) {
    this._isSearch = status;
  }

  $updateListOfChannels(inputValue) {
    const channelsList = this._MenuSection._ChannelListSection.channelList;
    const filteredChannels = channelsList.filter((channel) => {
      return channel.title.toLowerCase().includes(inputValue.toLowerCase());
    });
    this._MenuSection._updateChannels(filteredChannels, false);
  }

  $showKeyboard() {
    this.tag('Keyboard').visible = true;
    this._setState('Keyboard');
  }

  $checkSearchVisible() {
    if (this.tag('Search').visible == true) {
      let inputTag = this.tag('Search').tag('Input');
      inputTag.clear();
      this.tag('Search')._changePlaceholder();
      this.tag('Search').visible = false;

      return true;
    } else {
      return false;
    }
  }

  $showOriginalChannels() {
    if (this._isSearch) {
      this._PlayerSection._exitStatus = true;
      let inputTag = this.tag('Search').tag('Input');
      inputTag.value = '';
      inputTag.clear();
      const channelsList = this._MenuSection._ChannelListSection.channelList;
      this._MenuSection._updateChannels(channelsList, true);
    }
    this._isSearch = false;
  }

  $setSectionState(state) {
    this._setState(state);
  }

  static _states() {
    return [
      class MenuSectionState extends this {
        _getFocused() {
          return this.tag('MenuSection');
        }
        _handleLeft() {
          Router.focusWidget('sidebar');
        }
      },

      class PlayerSectionState extends this {
        _getFocused() {
          return this.tag('PlayerSection');
        }
        _handleLeft() {
          this._setState('MenuSectionState');
        }
      },
      class Search extends this {
        _getFocused() {
          return this.tag('Search');
        }
        _handleLeft() {
          this._setState('MenuSectionState');
        }
      },

      class Keyboard extends this {
        _getFocused() {
          return this.tag('Keyboard.Keyboard');
        }
      },
      class QualityModal extends this {
        _getFocused() {
          return this.tag('QualityModal');
        }
      },
      class InfoModal extends this {
        _getFocused() {
          return this.tag('InfoModal');
        }
      },
      class LockModal extends this {
        _getFocused() {
          return this.tag('LockModal');
        }
      },
      class LastChannelsModal extends this {
        _getFocused() {
          return this.tag('LastChannelsModal');
        }
      },
    ];
  }
}
