import Lightning from '@lightningjs/sdk/src/Lightning';
import { Column } from '@lightningjs/ui-components';
import qrcode from 'qrcode';
import MobileButton from './MobileButton';
import { getTVInfo } from '../../../../utils/device';
import { tvInfo } from '../../../../api';

const buttons = [
  { id: 1, icon: '/images/google-play.svg', focusedIcon: '/images/google-play-focused.svg' },
  { id: 1, icon: '/images/apple.svg', focusedIcon: '/images/apple-focused.svg' },
];

export default class MobileSettings extends Lightning.Component {
  static _template() {
    return {
      flex: { direction: 'row' },
      collision: true,
      DownloadSection: {
        w: 700,
        flex: { direction: 'column' },
        Title: {
          text: { text: 'Download from:', fontSize: 40 },
        },
        Buttons: {
          y: 10,
          type: Column,
          collision: true,
          items: buttons.map((list, index) => ({
            type: MobileButton,
            w: 540,
            h: 170,
            collision: true,
            buttonInfo: list,
            index: index,
          })),
          style: {
            itemSpacing: 120,
          },
        },
      },
      QRCodeSection: {
        flex: { direction: 'column' },

        Title: {
          text: { text: 'Scan for Login Mobile Device', fontSize: 40 },
        },
        QRCode: {
          y: 80,
          w: 420,
          h: 420,
        },
      },
    };
  }
  $setFocus(index) {
    this.tag('Buttons').selectedIndex = index;
    this.fireAncestors('$changeState', 'SettingContainer');
  }
  _handleHover() {
    this.fireAncestors('$changeState', 'SettingContainer');
  }

  _generateQRCode() {
    const { mac, sn } = tvInfo;
    const qrCodeData = `${mac}/**/${sn}`;

    qrcode.toDataURL(qrCodeData).then((image) => {
      this.tag('QRCode').src = image;
    });
  }

  _active() {
    this._generateQRCode();
  }

  get _Buttons() {
    return this.tag('Buttons');
  }

  _getFocused() {
    return this._Buttons;
  }
}
