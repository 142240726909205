import axios from 'axios';
import { getTVInfo, getToken } from '../utils/device';

export let axiosInstance = null;

export let tvInfo = null;

export const setTvInfo = (data) => {
  tvInfo = data;
};

export const createAxiosInstance = () => {
  axiosInstance = axios.create({
    baseURL: 'https://api.xcloudtv.com',
  });

  axiosInstance.interceptors.request.use(async (config) => {
    // const userAgent = navigator.userAgent;

    config.headers['X-Client-Version'] = 'ZeenTV Hisense 1.0.0';
    config.headers['X-Auth'] = await getToken();

    return config;
  });
};

const request = async ({ ...options }) => {
  const { mac, sn } = tvInfo;

  const modifiedMAC = mac?.replace(/:/g, '');
  if (!options.url.includes('/settings')) {
    options.url = options.url + `/${sn}/${modifiedMAC}/2`;
  }
  const onSuccess = (response) => response;
  const onError = (error) => {
    // DISPLAY API ERROR MESSAGES ON DEV ENVIRONMENT
    if (process.env.NODE_ENV === 'development') {
      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.error('Error Message:', error.message);
      }

      return Promise.reject(error.response || error.message);
    }
  };

  return axiosInstance(options).then(onSuccess).catch(onError);
};

export default request;
getTVInfo();
