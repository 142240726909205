// Components
import ContentSettings from '../components/ContentSettings/ContentSettings';
import GeneralSettings from '../components/General Settings/GeneralSettings';
import MobileSettings from '../components/MobileSettings/MobileSettings';
import SettingsButton from '../components/SettingsButton';
import Transfer from '../components/TransferSettings/Transfer';
import UserSettings from '../components/UserSettings/UserSettings';

export default async function (page) {
  const listOfSettings = [
    { id: 1, title: 'User Settings', component: UserSettings },
    { id: 2, title: 'General Settings', component: GeneralSettings },
    { id: 3, title: 'Mobile', component: MobileSettings },
    { id: 4, title: 'Transfer', component: Transfer },
    { id: 4, title: 'Content Settings', component: ContentSettings },
  ];
  const items = listOfSettings.map((list, index) => ({
    type: SettingsButton,
    buttonInfo: list,
    index,
    w: 260,
    h: 60,
  }));
  if (page._SettingsOptions) {
    page._SettingsOptions.items = items;
  } else {
    page._SettingsOptions = { items: [] };
  }
}
