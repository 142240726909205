import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
import { Input } from '@lightningjs/ui-components';

export default class InputField extends lng.Component {
  static _template() {
    return {
      InputWrapper: {
        collision: true,
        w: 1000,
        h: 90,
        flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },

        Input: {
          type: Input,
          rect: true,
          // password: true,
          // mask: '*',
        },
      },
      Placeholder: {
        flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
        rect: true,
        Text: {
          y: 3,
          text: { text: 'Please Enter Code' },
        },
      },
    };
  }

  get _Input() {
    return this.tag('Input');
  }

  _updateTexture(color) {
    return lng.Tools.getRoundRect(this.w, this.h, 10, 3, color, true, Colors('#000000').get());
  }

  _updatePlaceholderTexture(color) {
    const texture = this._updateTexture(color);
    this.tag('Placeholder').patch({
      w: this.w,
      h: this.h,
      texture,
    });
  }

  _setInputProperties() {
    this._Input.patch({
      h: this.h - 15,
      texture: lng.Tools.getRoundRect(this.w / 2, this.h - 10, 10, 3),
    });
  }

  _enable() {
    this._setInputProperties();
    this._updatePlaceholderTexture(Colors('#C40000').get());
  }

  _focus() {
    this._updatePlaceholderTexture(Colors('#C40000').get());
  }

  _unfocus() {
    this._updatePlaceholderTexture(Colors('#000000').get());
  }

  _handleHover() {
    this.fireAncestors('$setFocus', 'InputField');
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _changePlaceholder() {
    const isInputEmpty = this._Input.value === '';
    this.tag('Placeholder.Text').patch({
      visible: isInputEmpty,
    });
  }
}
