import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
import { Input } from '@lightningjs/ui-components';

export default class UserCodeInput extends lng.Component {
  constructor(props) {
    super(props);
  }
  static _template() {
    return {
      Button: {
        rect: true,
        collision: true,
        rect: true,
        flex: {
          direction: 'row',
          alignItems: 'center',
        },
        Text: {
          x: 30,
          y: 10,
          w: 450,
          h: 70,
          flex: {
            direction: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          },
          text: { text: 'User Code' },
        },
        Input: {
          type: Input,
          w: 350,
          h: 70,
          style: {
            backgroundColor: Colors('blue').alpha(0).get(),
          },
          Placeholder: {
            w: 300,
            h: 70,
            Text: {
              y: 12,
              x: 30,
              text: {
                letterSpacing: 2,
                text: '************',
                textColor: Colors('#adadad').get(),
                fontSize: 40,
              },
            },
          },
        },
      },
    };
  }

  get _Button() {
    return this.tag('Button');
  }

  _focus() {
    this._Button.patch({
      texture: lng.Tools.getRoundRect(this.w, this.h, 13, 2, Colors('#c71617').get(), false),
    });
  }

  _changePlaceholder() {
    const isInputEmpty = this.tag('Input').value === '';
    this.tag('Input.Placeholder.Text').patch({
      visible: isInputEmpty,
    });
  }

  _unfocus() {
    this._Button.patch({
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        13,
        2,
        Colors('#232323').get(),
        true,
        Colors('#232323').get()
      ),
    });
  }

  _enable() {
    this._Button.patch({
      w: this.w,
      h: this.h,
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        10,
        3,
        Colors('#232323').get(),
        true,
        Colors('#232323').get()
      ),
    });
  }

  _handleHover() {
    this.fireAncestors('$setState', 'UserCodeInput');
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }
}
