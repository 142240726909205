import lng from '@lightningjs/core';
import { Colors, Router, Storage } from '@lightningjs/sdk';
import { Column } from '@lightningjs/ui-components';
import qrcode from 'qrcode';
import { getActivation } from '../../api/activation';

// Components
import InputField from './components/InputField';
import Button from './components/Button';
// import Keyboard from './components/Keyboard';
import TransferModal from './components/TransferModal/TransferModal';
import TransferButton from './components/TransferButton';
import Keyboard from '../../components/Keyboard/components/Keyboard';

export default class Register extends lng.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      rect: true,
      color: Colors('#1f2227').get(),
      flex: { direction: 'column', justifyContent: 'center', alignItems: 'center' },
      QRCodeSection: {
        QRCode: { y: 550, x: 405, w: 250, h: 250 },
      },
      Wrapper: {
        y: 400,
        flex: { direction: 'column', justifyContent: 'center', alignItems: 'flex-start' },
        InputWrapper: {
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          InputField: { type: InputField, w: 1000, h: 90, collision: true },
          Button: { type: Button, y: 3, x: 30, w: 250, h: 90, collision: true },
        },
        Column: {
          type: Column,
          y: 110,
          flex: { direction: 'row', justifyContent: 'center' },
          style: { itemSpacing: 30 },
        },
      },

      Keyboard: {
        type: Keyboard,
        // x: 150,
        visible: false,
        zIndex: 99,
        limit: 10,
      },

      MessageBox: {
        y: -60,
        w: 300,
        h: 60,
        rect: true,
        color: Colors('#f6f6f6').alpha(0.7).get(),
        flex: {
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        },
        shader: {
          type: lng.shaders.RoundedRectangle,
          radius: 10,
        },
        Title: {
          y: 3,
          w: 200,
          h: 60,
          color: Colors('#000000').get(),
          text: { text: `Can't log in` },
        },
      },
      Transfer: {
        y: -1090,
        x: -850,
        TransferModal: {
          type: TransferModal,
          zIndex: 100,
          visible: false,
          // collision: true,
        },
      },
      TransferButton: {
        type: TransferButton,
        collision: true,
        y: -1030,
        x: 640,
      },
    };
  }

  set tvInfo({ mac, sn }) {
    this._tvInfo = { mac, sn };
  }

  get _InputField() {
    return this.tag('InputField');
  }

  get _Button() {
    return this.tag('Button');
  }

  get _Keyboard() {
    return this.tag('Keyboard');
  }

  get _MessageBox() {
    return this.tag('MessageBox');
  }

  _active() {
    this._generateQRCode();
    this._MessageBox.patch({
      visible: false,
    });
  }

  _enable() {
    this._setState('InputField');
    this._Keyboard.visible = false;
  }

  _generateQRCode() {
    const { mac, sn } = this._tvInfo;
    const qrCodeData = `${mac}/**/${sn}`;

    qrcode.toDataURL(qrCodeData).then((image) => {
      this.tag('QRCode').src = image;
    });
  }

  _handleBack() {
    this.fireAncestors('$exitDialog');
  }

  $hideModal() {
    if (this.tag('Transfer.TransferModal').visible == true) {
      this.tag('Transfer.TransferModal').visible = false;
      this._setState('InputField');
      return;
    }
  }

  $pressedDone() {
    this._Keyboard.visible = false;
    this._setState('InputField');
    this._InputField.tag('Input').listening = false;
  }

  async $checkCode() {
    this._Keyboard.visible = false;
    this._InputField.tag('Input').listening = false;
    const accessCode = this._InputField.tag('Input').value;
    const modifiedMAC = this._tvInfo.mac.replace(/:/g, '');

    try {
      const activationData = await getActivation(accessCode, this._tvInfo.sn, modifiedMAC);

      if (activationData) {
        localStorage.removeItem('user');
        localStorage.setItem('user', activationData.data);
        Router.navigate('liveTv');
      } else {
        this._MessageBox.patch({
          visible: true,
        });
        setTimeout(() => {
          this._MessageBox.patch({
            visible: false,
          });
        }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
  }

  get _Column() {
    return this.tag('Column');
  }

  $setFocus(state) {
    this._setState(state);
  }

  $setFocusKeyboard() {
    this._setState('Keyboard');
  }

  static _states() {
    return [
      class InputField extends this {
        _getFocused() {
          return this._InputField;
        }

        _handleRight() {
          this._setState('Button');
        }

        _handleEnter() {
          this._MessageBox.patch({
            visible: false,
          });
          this._Keyboard.visible = true;
          this._setState('Keyboard');
          this._InputField.tag('Input').listening = true;
        }

        _handleClick() {
          this._handleEnter();
        }

        _handleDown() {
          if (this._Keyboard.visible) {
            this._setState('Keyboard');
          }
        }

        _handleBack() {
          if (this._Keyboard.visible) {
            this._Keyboard.visible = false;
            this._setState('InputField');
          } else {
            Router.back();
          }
        }
      },

      class Button extends this {
        _getFocused() {
          return this._Button;
        }

        _handleLeft() {
          this._setState('InputField');
        }

        _handleUp() {
          this._setState('TransferButton');
        }
        _handleDown() {
          if (this._Keyboard.visible) {
            this._setState('Keyboard');
          }
        }

        _handleBack() {
          if (this._Keyboard.visible) {
            this._Keyboard.visible = false;
            this._setState('Button');
          } else {
            Router.back();
          }
        }
      },

      class Keyboard extends this {
        _getFocused() {
          return this._Keyboard;
        }
        _handleBack() {
          this._Keyboard.visible = false;
          this._setState('InputField');
        }

        _handleHover() {
          return true;
        }
      },

      class TransferModal extends this {
        _getFocused() {
          return this.tag('Transfer.TransferModal');
        }
      },
      class TransferButton extends this {
        _getFocused() {
          return this.tag('TransferButton');
        }

        _handleDown() {
          this._setState('Button');
        }

        _handleEnter() {
          this.tag('Transfer.TransferModal').visible = true;
          this._setState('TransferModal');
        }

        _handleClick() {
          console.log('Fired');
          this.tag('Transfer.TransferModal').visible = true;
          this._setState('TransferModal');
        }
      },
    ];
  }
}
