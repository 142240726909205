import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

export default class PackageInfoButton extends lng.Component {
  constructor(props) {
    super(props);
  }
  static _template() {
    return {
      Button: {
        rect: true,
        collision: true,
        rect: true,
        flex: {
          direction: 'row',
          alignItems: 'center',
        },
        Package: {
          x: 30,
          w: 375,
          h: 70,
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          Info: {
            text: { fontSize: 30 },
          },
        },

        VerticalLine: {
          w: 2,
          h: 55,
          rect: true,
        },

        StartExpire: {
          w: 500,
          h: 70,
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          Info: {
            y: 3,
            text: { fontSize: 30 },
          },
        },
        VerticalLine2: {
          w: 2,
          h: 55,
          rect: true,
        },
        Status: {
          w: 400,
          h: 70,
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          Info: {
            y: 3,
            text: { fontSize: 30 },
          },
        },
      },
    };
  }

  set buttonInfo(val) {
    this._buttonInfo = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  get _Package() {
    return this.tag('Package.Info');
  }

  get _StartExpire() {
    return this.tag('StartExpire.Info');
  }

  get _Status() {
    return this.tag('Status.Info');
  }

  _enable() {
    this._Button.patch({
      w: this.w,
      h: this.h,
      color: Colors(this._buttonInfo.color).get(),
      texture: lng.Tools.getRoundRect(this.w, this.h, this._buttonInfo.radius, 3, false),
    });
    this._Package.patch({
      text: { text: this._buttonInfo.title },
    });
    this._StartExpire.patch({
      text: { text: this._buttonInfo.option },
    });
    this._Status.patch({
      text: { text: this._buttonInfo?.status },
    });
    if (!this._buttonInfo.vLine) {
      this.tag('VerticalLine2').visible = false;
      this.tag('VerticalLine').visible = false;
    }
  }
}
