import lng from '@lightningjs/core';
import { Colors, Router, Utils } from '@lightningjs/sdk';
import { Row } from '@lightningjs/ui-components';
import { deleteMyList, setMyList } from '../../api/myList/index';

// Components
import SeasonButton from './components/SeasonButton';

export default class SingleSerie extends lng.Component {
  static _template() {
    return {
      Wrapper: {
        w: 1920,
        h: 1080,
        rect: true,
        BackgroundColor: {
          w: 1920,
          h: 1080,
          rect: true,
          Overlay: {
            h: 1080,
            w: 1920,
            rect: true,
            color: Colors('#000000').alpha(0.8).get(),
          },
        },
        ImagePoster: {
          x: 100,
          y: 170,
          w: 400,
          h: 550,
          texture: {
            type: lng.textures.ImageTexture,
            resizeMode: { type: 'cover', w: this.w, h: this.h },
          },
          shader: {
            type: lng.shaders.RoundedRectangle,
            radius: 10,
          },
        },
        Title: {
          x: 550,
          y: 160,
          text: { fontSize: 65, letterSpacing: 2, fontStyle: 'normal bold' },
        },
        Genre: { x: 550, y: 250, text: { fontSize: 30, fontStyle: 'normal bold' } },
        LanguageWrapper: {
          x: 550,
          y: 280,
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          SerieLanguage: { text: { fontSize: 30, fontStyle: 'normal bold' } },
          Vote: {
            flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
            VoteImage: {
              x: 20,
              y: 30,
              w: 80,
              texture: lng.Tools.getSvgTexture(Utils.asset('/icons/vod_imdb_logo.SVG'), 100, 100),
            },
            VoteRating: {
              x: 20,
              w: 100,
              text: { fontSize: 30, fontStyle: 'normal bold' },
            },
          },
        },
        Description: {
          x: 550,
          y: 370,
          w: 877,
          text: { fontSize: 24, letterSpacing: 1, lineHeight: 30, maxLines: 9 },
        },
        Buttons: {
          type: Row,
          x: 550,
          y: 640,
          collision: true,
          style: {
            itemSpacing: 20,
          },
        },
        Seasons: {
          type: Row,
          x: 100,
          y: 800,
          w: 1750,
          h: 100,
          collision: true,
          style: {
            itemSpacing: 10,
          },
          clipping: true,
          scrollIndex: 2,
        },
        Episodes: {
          type: Row,
          x: 100,
          y: 900,
          w: 1750,
          h: 100,
          collision: true,
          style: {
            itemSpacing: 10,
          },
          clipping: true,
          scrollIndex: 2,
        },
      },
      MyListFlag: {
        x: 1680,
        y: 30,
        w: 350,
        h: 80,
        visible: false,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        rotation: Math.PI / 4,
        rect: true,
        color: Colors('red').get(),
        Image: {
          texture: lng.Tools.getSvgTexture(Utils.asset('/images/heart.svg'), 70, 60),
        },
      },
    };
  }

  set genres(val) {
    this._genres = val;
  }

  set language(val) {
    this._language = val;
  }

  set seasons(val) {
    this._seasons = val;
  }

  set myListStatus(val) {
    this._myListStatus = val;
  }

  set firstEpisode(val) {
    this._firstEpisode = val;
  }

  set serieDetail(val) {
    this._serieDetail = val;
  }

  get _Buttons() {
    return this.tag('Buttons');
  }

  get Description() {
    return this.tag('Description');
  }

  get Title() {
    return this.tag('Title');
  }

  get _Genre() {
    return this.tag('Genre');
  }

  get _Seasons() {
    return this.tag('Seasons');
  }

  get _Episodes() {
    return this.tag('Episodes');
  }

  get _MyListFlag() {
    return this.tag('MyListFlag');
  }

  _active() {
    this._setState('Buttons');
  }

  $addToMyList() {
    setMyList(this._serieDetail.content_id, 1, 1, 1);
    this._MyListFlag.visible = true;
    this._myListStatus = true;
  }

  $unselectCurrentIndex(status) {
    let currentIndex;
    switch (status) {
      case 'Buttons':
        currentIndex = this._Buttons.selectedIndex;
        this._Buttons.items[currentIndex]._unfocus();
        break;
      case 'seasons':
        currentIndex = this._Seasons.selectedIndex;
        this._Seasons.items[currentIndex]._unfocus();
        break;
      case 'episodes':
        currentIndex = this._Episodes.selectedIndex;
        this._Episodes.items[currentIndex]._unfocus();
        break;
    }
  }

  $setFocus({ index, flag }) {
    switch (flag) {
      case 'episodes':
        this._Episodes.scrollTo(index, 10);
        break;
      case 'seasons':
        this._Seasons.selectedIndex = index;
        break;
    }
  }

  $setState(status) {
    switch (status) {
      case 'Buttons':
        this._setState('Buttons');
        break;
      case 'seasons':
        this._setState('Seasons');
        break;
      case 'episodes':
        this._setState('Episodes');
        break;
    }
  }

  $removeFromMyList() {
    deleteMyList(this._serieDetail.content_id, 1, 1, 1);
    this._MyListFlag.visible = false;
    this._myListStatus = false;
  }

  $changedSeasons({ episodes }) {
    const items = episodes.map((episode, index) => ({
      type: SeasonButton,
      w: 180,
      h: 60,
      index,
      buttonInfo: episode,
      indicator: 'episodes',
      collision: true,
    }));
    this._Episodes.items = items;
  }

  $playVOD(params) {
    let newData;
    if (params.flag === 'movie') {
      newData = {
        genres: [...this._genres],
        ...this._serieDetail,
        ...this._firstEpisode,
        title: this._serieDetail.title,
        contentTypeID: 3,
        content_id: this._serieDetail.content_id,
      };
    } else if (params.flag === 'trailer') {
      newData = {
        ...this._serieDetail,
        title: this._serieDetail.title,
        contentTypeID: 3,
        content_id: this._serieDetail.content_id,
      };
    } else if (params.flag === 'episode') {
      newData = {
        genres: [...this._genres],
        ...this._serieDetail,
        ...params.data,
        title: this._serieDetail.title,
        content_id: this._serieDetail.content_id,
        contentTypeID: 3,
      };
    } else {
      newData = {
        ...this._serieDetail,
        contentTypeID: 3,
      };
    }

    Router.navigate('player', { data: newData, flag: params.flag });
  }

  _checkIfLeftToRight(text) {
    var arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(text);
  }

  _enable() {
    this.tag('BackgroundColor').patch({
      texture: {
        type: lng.textures.ImageTexture,
        src: this._serieDetail.backdrop,
        resizeMode: { type: 'cover', w: 1920, h: 1080 },
      },
    });
    this.Title.patch({
      text: { text: this._serieDetail.title },
    });
    const allGenres = this._genres?.join(' • ');
    this._Genre.patch({
      text: { text: allGenres },
    });

    let text = this._serieDetail.description;
    const isLeftToRight = this._checkIfLeftToRight(text);

    if (isLeftToRight) {
      this.Description.text = { text: this._serieDetail.description, textAlign: 'right' };
    } else {
      this.Description.text = { text: this._serieDetail.description, textAlign: 'left' };
    }

    this.tag('ImagePoster').patch({
      src: this._serieDetail.image_url,
    });
    this.tag('VoteRating').patch({
      text: { text: `${this._serieDetail.imdb_score}` },
    });
    this.tag('SerieLanguage').patch({
      text: {
        text: `${this._language} ${this._serieDetail.year}`,
      },
    });
    if (this._myListStatus) {
      this._MyListFlag.visible = true;
    } else {
      this._MyListFlag.visible = false;
    }

    //! A piece of code that can be used when creating options inside the player
    // this.tag('SourceModal').patch({
    //   content_id: this._serieDetail.content_id,
    //   stream_id: this._serieDetail.stream_id,
    //   content_type: 2,
    //   visible: false,
    // });
    // this._findLanguage(this._serieDetail.language_id);
  }

  static _states() {
    return [
      class Seasons extends this {
        _getFocused() {
          return this.tag('Seasons');
        }

        _handleUp() {
          this._setState('Buttons');
        }

        _handleDown() {
          this._setState('Episodes');
        }
      },

      class Episodes extends this {
        _getFocused() {
          return this.tag('Episodes');
        }

        _handleUp() {
          this._setState('Seasons');
        }

        _handleScroll(event, target) {
          let lorem = this._Episodes; // Ako koristite target, pretpostavka je da je _Episodes svojstvo ciljnog objekta
          let currentIndex = this._Episodes.selectedIndex;
          let nextIndex = currentIndex + 1;
          let previousIndex = currentIndex - 1;
          // Inkrementiranje ili dekrementiranje lorem.x za 400, ovisno o smjeru skroliranja
          if (event.deltaY > 0) {
            this._Episodes.scrollTo(nextIndex, 50);
            // lorem.x -= 400;
          } else if (event.deltaY < 0) {
            console.log('Rotacija prema gore');
            // lorem.x += 400;
            this._Episodes.scrollTo(previousIndex, 50);
          } else {
            console.log('Nema vertikalne rotacije');
          }

          // Ograniči lorem.x na raspon između -400 i 0
          // lorem.x = Math.max(Math.min(0, lorem.x), -400);

          // Ovdje možete dodati dodatne akcije ili ažuriranja sukladno promjenama u lorem.x
        }
      },

      class Buttons extends this {
        _getFocused() {
          return this.tag('Buttons');
        }

        _handleDown() {
          this._setState('Seasons');
        }
      },
    ];
  }
}
