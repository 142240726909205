import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
let selectedSetting = 0;

export default class SettingsButton extends lng.Component {
  static _template() {
    return {
      Button: {
        collision: true,
        rect: true,
        color: Colors('#424242').get(),
        flex: {
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        },
        Selector: {
          y: 3,
          text: { fontSize: 40 },
        },
        Text: {
          y: 13,
          w: 220,
          h: 60,
          text: {
            fontSize: 25,
            color: Colors('#fafafa').get(),
            maxLines: 1,
            textAlign: 'center',
          },
        },
      },
    };
  }

  set buttonInfo(val) {
    this._buttonInfo = val;
  }

  set indicator(val) {
    this._indicator = val;
  }

  set index(val) {
    this._index = val;
  }

  get _Button() {
    return this.tag('Button');
  }

  get _Text() {
    return this.tag('Text');
  }

  get _Selector() {
    return this.tag('Selector');
  }

  _firstEnable() {
    if (this._index === 0) {
      this._Selector.patch({
        flexItem: { marginRight: 10 },
        text: { text: '•' },
      });
    } else {
      this._Selector.patch({
        flexItem: { marginRight: 0 },
        text: { text: '' },
      });
    }
    this._Button.patch({
      w: this.w,
      h: this.h,
      texture: lng.Tools.getRoundRect(this.w, this.h, 10, 3, false),
    });

    this._Text.patch({
      text: { text: this._buttonInfo.title },
    });
  }

  _focus() {
    this._Button.patch({ color: Colors('#C40000').get() });
  }

  _unfocus() {
    this._Button.patch({ color: Colors('#424242').get() });
  }

  _handleEnter() {
    const handleCommonLogic = () => {
      this.parent.children.forEach((button) => {
        if (button instanceof SettingsButton) {
          button._Selector.patch({
            flexItem: { marginRight: 0 },
            text: { text: '' },
          });
        }
      });
      this._Selector.patch({
        flexItem: { marginRight: 10 },
        text: { text: '•' },
      });
    };
    if (selectedSetting === this._index) {
      return true;
    }
    handleCommonLogic();
    this.fireAncestors('$changeComponent', { data: this._buttonInfo });
    selectedSetting = this._index;
  }

  _handleClick() {
    this._handleEnter();
  }

  _handleHover() {
    this.fireAncestors('$changeState', 'SettingsOptions');
    this.fireAncestors('$unselectCurrentIndex');
    this.fireAncestors('$setFocus', this._index);
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }
}
