import { Row } from '@lightningjs/ui-components';
import KidCard from '../../Kids/components/KidCard';

export default async function (page, data) {
  try {
    const moviesData = data.data.movies || data.data.series || data.data.data;
    const isMovies = Boolean(data.data.movies);

    const items = moviesData.map((media, index) => ({
      type: KidCard,
      collision: true,
      w: 265,
      h: 510,
      index: index,
      data: { flag: isMovies ? 'movies' : 'series', ...media },
    }));

    const itemsPerPage = 6;
    const pageItems = Array.from({ length: Math.ceil(items.length / itemsPerPage) }).map((_, i) => {
      const start = i * itemsPerPage;
      const end = (i + 1) * itemsPerPage;
      const rowItems = items.slice(start, end).map((item, j) => {
        return { ...item, rowIndex: i, itemIndex: j };
      });

      return {
        type: Row,
        collision: true,
        h: 510,
        items: rowItems,
        style: {
          itemSpacing: 40,
        },
      };
    });
    page.language = isMovies ? data.text + ' ' + 'MOVIES' : data.text + ' ' + 'SERIES';
    // page._Column.items = pageItems;

    // Moj kod je ispod
    page.items = pageItems;
  } catch (error) {
    console.error('Error processing data:', error);
  }
}
