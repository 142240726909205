import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

export default class VODInfo extends lng.Component {
  static _template() {
    return {
      Info: {
        flex: { direction: 'column', justifyContent: 'center' },
        Title: {
          y: 70,
          color: Colors('#ffffff').get(),
          text: {
            fontStyle: 'normal bold',
            fontSize: 60,
            lineHeight: 65,
            maxLines: 2,
            wordWrapWidth: 700,
          },
          zIndex: 10,
        },
        Informations: {
          y: 55,
          Genre: {
            color: Colors('#adadad').get(),
            text: {
              fontSize: 26,
              maxLines: 1,
              wordWrapWidth: 600,
            },
          },
        },

        Description: {
          y: 100,
          zIndex: 10,
          color: Colors('#adadad').get(),
          text: {
            fontSize: 26,
            lineHeight: 40,
            wordWrapWidth: 850,
            maxLines: 7,
          },
        },
      },
      Overlay: {
        x: 550,
        y: 0,
        w: 1300,
        h: 650,
        colorLeft: Colors('#000000').alpha(1).get(),
        colorRight: Colors('#000000').alpha(0.5).get(),
        rect: true,
      },
      Cover: {
        x: 670,
        y: 0,
        w: 1100,
        h: 600,
        Overlay: {
          colorLeft: Colors('#000000').alpha(1).get(),
          colorRight: Colors('#000000').alpha(0.5).get(),
          w: 1100,
          h: 600,
          rect: true,
        },
        texture: {
          type: lng.textures.ImageTexture,
        },
      },
    };
  }

  get _Genre() {
    return this.tag('Genre');
  }

  get _Cover() {
    return this.tag('Cover');
  }

  get _Title() {
    return this.tag('Title');
  }
  get _Description() {
    return this.tag('Description');
  }
}
