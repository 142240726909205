import { getSubtitle } from '../../../api/subtitles';
import SubtitleButton from '../components/SubtitleModal/SubtitleButton';

export default async function (page, data) {
  let items = [];
  let showSubtitle;
  try {
    items.push({
      type: SubtitleButton,
      h: 50,
      w: 400,
      buttonInfo: { title: 'OFF' },
      collision: true,
      index: 0,
    });
    const response = await getSubtitle(
      data.data.contentTypeID,
      data.data.content_id,
      data.data.stream_id
    );

    if (response && response.data && response.data.subtitles) {
      showSubtitle = true;
      items.push(
        ...response.data.subtitles.map((subtitle, index) => ({
          type: SubtitleButton,
          h: 50,
          w: 400,
          collision: true,
          buttonInfo: subtitle,
          index: index + 1,
        }))
      );
    } else {
      showSubtitle = false;
    }
  } catch (error) {
    console.error('Error fetching subtitles:', error);
  }
  page._SubtitleModal._Column.items = items;
  page.showSubtitle = showSubtitle;
  page.VODProgressContainer.showSubtitle = showSubtitle;
}
