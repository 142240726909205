import Lightning from '@lightningjs/sdk/src/Lightning';
import { Column } from '@lightningjs/ui-components';
import { Colors, Router } from '@lightningjs/sdk';

// Components
import SubscriptionButton from './SubscriptionsButtons';
import PackageInfoButton from './PackageInfoButton';
import UserCodeInput from './UserCodeInput';
import MessageBox from '../../MessageBox';
import UserKeyboard from '../../../../components/UserKeyboard/UserKeyboard';
import Packages from './Packages';
import ConnectButton from './ConnectButton';
import { getActivation } from '../../../../api/activation/index';

import { tvInfo } from '../../../../api';
let user = JSON.parse(localStorage.getItem('user'));

// let listofDetails = [
//   { id: 1, title: 'MAC ID', option: mac },
//   { id: 2, title: 'Serial Number', option: sn },
//   { id: 3, title: 'Subscription ID', option: user?.subscription.id },
// ];

export default class UserSettings extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      UserRenewal: {
        Title: {
          text: { text: 'User Renewal', textColor: Colors('#adadad').get(), fontStyle: 'bold' },
        },
        UserCode: {
          y: 100,
          flex: { direction: 'row' },
          UserCodeInput: {
            w: 800,
            h: 70,
            type: UserCodeInput,
            collision: true,
          },
          UserCodeButton: {
            type: ConnectButton,
            collision: true,
            x: 20,
            w: 200,
            h: 70,
            buttonInfo: { title: 'Connect' },
          },
        },
      },

      SubscriptionDetails: {
        y: 220,
        Text: { text: { text: 'Subscription Details' } },
        DetailsButtons: {
          type: Column,
          y: 80,
          // items: listofDetails.map((list) => ({
          //   type: SubscriptionButton,
          //   buttonInfo: list,
          //   w: 1050,
          //   h: 70,
          // })),
        },
      },
      PackageSection: {
        y: 570,
        InfoContainer: {
          w: 1050,
          h: 70,
          type: PackageInfoButton,
          buttonInfo: {
            id: 1,
            title: 'Package',
            option: 'Start & Expire',
            status: 'Status',
            radius: 10,
            vLine: true,
            color: '#232323',
          },
        },
        PackageSection: {
          y: 60,
          w: 1050,
          h: 170,
          clipping: true,
          collision: true,
          type: Column,
          items: user?.packages.map((pckg, index) => ({
            type: Packages,
            index,
            w: 1050,
            h: 70,
            buttonInfo: {
              ...pckg,
              radius: 0,
              vLine: false,
              color: '#414042',
            },
          })),
        },
      },

      Keyboard: {
        w: 1920,
        h: 1080,
        x: -170,
        y: -170,
        type: UserKeyboard,
        flex: { direction: 'row', justifyContent: 'center' },
        visible: false,
        limit: 12,
        zIndex: 205,
      },
    };
  }

  get _InputField() {
    return this.tag('UserCodeInput');
  }

  get _UserCodeButton() {
    return this.tag('UserCodeButton');
  }

  _enable() {
    let { mac, sn } = tvInfo;
    let user = JSON.parse(localStorage.getItem('user'));
    let listofDetails = [
      { id: 1, title: 'MAC ID', option: mac },
      { id: 2, title: 'Serial Number', option: sn },
      { id: 3, title: 'Subscription ID', option: user?.subscription.id },
    ];
    this.tag('DetailsButtons').patch({
      items: listofDetails.map((list) => ({
        type: SubscriptionButton,
        buttonInfo: list,
        w: 1050,
        h: 70,
      })),
    });

    this.tag('PackageSection.PackageSection').patch({
      items: user?.packages.map((pckg, index) => ({
        type: Packages,
        w: 1050,
        h: 70,
        collision: true,
        index,
        buttonInfo: {
          ...pckg,
          radius: 0,
          vLine: false,
          color: '#414042',
        },
      })),
    });
  }

  $pressedDone() {
    this.tag('Keyboard').visible = false;
    this._setState('UserCodeInput');
    this._InputField.tag('Input').listening = false;
  }

  _setDate(time) {
    var stringSaVremenom = time;
    var datumIVreme = stringSaVremenom.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/)[0];

    var samoDatum = datumIVreme.slice(0, 10);
    return samoDatum;
  }

  _setMessage(msg) {
    this.patch({
      MessageBox: {
        type: MessageBox,
        y: 780,
        x: 550,
        w: 580,
        h: 90,
        message: msg,
      },
    });
    setTimeout(() => {
      this.patch({
        MessageBox: undefined,
      });
    }, 2000);
  }

  async _active() {
    this._setState('UserCodeInput');
  }

  async $checkCode() {
    let { mac, sn } = tvInfo;
    const accessCode = this._InputField.tag('Input').value;
    const modifiedMAC = mac.replace(/:/g, '');
    if (accessCode == '') {
      this._setMessage('Usercode can not be empty!');
    } else if (accessCode.length < 10) {
      this._setMessage('Usercode should be 12 digi!');
    } else {
      try {
        const activationData = await getActivation(accessCode, sn, modifiedMAC);

        if (activationData) {
          localStorage.removeItem('user');
          // localStorage.setItem('user', activationData.data);
          this._setMessage('Success');
          Router.navigate('home');
          location.reload();
        } else {
          this._setMessage('Can not login');
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  _handleBack() {
    if (this.tag('Keyboard').visible == true) {
      this.tag('Keyboard').visible = false;
      this._setState('UserCodeInput');
      this._InputField.tag('Input').listening = false;
    } else {
      Router.back();
    }
  }

  _handleHover() {
    this.fireAncestors('$changeState', 'SettingContainer');
  }

  $setState(state) {
    this._setState(state);
  }

  $setFocus(index) {
    this.tag('PackageSection.PackageSection').scrollTo(index, 200);
  }

  static _states() {
    return [
      class Button extends this {
        _getFocused() {
          return this._UserCodeButton;
        }
        _handleLeft() {
          this._setState('UserCodeInput');
        }
        _handleDown() {
          this._setState('Package');
        }
      },
      class UserCodeInput extends this {
        _handleRight() {
          this._setState('Button');
        }
        _getFocused() {
          return this.tag('UserCodeInput');
        }
        _handleEnter() {
          this.tag('Keyboard').patch({
            visible: true,
          });
          this._setState('Keyboard');
          this._InputField.tag('Input').listening = true;
        }

        _handleClick() {
          this._handleEnter();
        }

        _handleDown() {
          this._setState('Package');
        }
      },

      class Package extends this {
        _getFocused() {
          return this.tag('PackageSection.PackageSection');
        }

        _handleUp() {
          this._setState('UserCodeInput');
        }

        _handleHover() {
          this.fireAncestors('$changeState', 'SettingContainer');
          this._setState('Package');
        }

        _handleScroll(event) {
          if (event.deltaY > 0) {
            let currentIndex = this.tag('PackageSection.PackageSection').selectedIndex;
            this.tag('PackageSection.PackageSection').scrollTo(currentIndex + 1, 100);
          } else if (event.deltaY < 0) {
            let currentIndex = this.tag('PackageSection.PackageSection').selectedIndex;
            this.tag('PackageSection.PackageSection').scrollTo(currentIndex - 1, 100);
          }
        }
      },

      class Keyboard extends this {
        _getFocused() {
          return this.tag('Keyboard');
        }

        _handleLeft() {
          return true;
        }

        _handleUp() {
          return true;
        }
      },
    ];
  }
}
