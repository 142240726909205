import lng from '@lightningjs/core';
import { Colors, Utils } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';
import { Marquee } from '@lightningjs/ui-components';
let focusedItem = undefined;
export default class ChannelListButton extends lng.Component {
  constructor(props) {
    super(props);
    // Timeout that will fire the marquee animation if the user is on one channel longer than a second
    this._marqueeTimeout = undefined;
    this._selected = false;
  }

  static _template() {
    return {
      collision: true,
      flex: { direction: 'row', justifyContent: 'center' },
      Button: {
        collision: true,
        clipping: true,
        flex: { direction: 'row', alignItems: 'center' },
        ChannelNumber: {
          x: 10,
          w: 90,
        },
        ImageWrapper: {
          flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },

          w: 100,
          h: 80,
          ChannelImage: { flexItem: { marginLeft: 0, marginRight: 20 } },
        },
        ChannelName: {
          type: Marquee,
          w: 170,
          y: -20,
        },
        ChannelLock: {
          w: 40,
          h: 40,
        },
        ChannelFavourite: {
          w: 20,
          h: 20,
          x: -3,
        },
      },
    };
  }

  get isLocked() {
    return this._isLocked;
  }

  set isLocked(val) {
    this._isLocked = val;
  }

  get isFavourite() {
    return this._isFavourite;
  }

  set isFavourite(val) {
    this._isFavourite = val;
  }

  set buttonInfo(val) {
    return (this._buttonInfo = val);
  }

  set index(val) {
    return (this._index = val);
  }

  get _Button() {
    return this.tag('Button');
  }

  get _ChannelNumber() {
    return this.tag('ChannelNumber');
  }

  get _ChannelImage() {
    return this.tag('ChannelImage');
  }

  get _ChannelName() {
    return this.tag('ChannelName');
  }

  _selectButton() {
    this._selected = true;
    this._Button.patch({
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        5,
        3,
        Colors('#C40000').get(),
        this.parentFocus,
        Colors('#C40000').get()
      ),
    });
  }

  _unselectButton() {
    this._selected = false;
    this._Button.patch({
      texture: null,
    });
  }

  _changeNumber() {
    const paddedIndex = String(this._index + 1).padStart(4, '0');
    this._ChannelNumber.patch({
      text: { text: paddedIndex, fontSize: 25 },
    });
  }

  _setLockIcon() {
    this.tag('ChannelLock').patch({
      texture: lng.Tools.getSvgTexture(Utils.asset('/icons/lockedChannel.SVG'), 40, 40),
    });
  }

  _delLockIcon() {
    this.tag('ChannelLock').patch({
      texture: undefined,
    });
  }

  _setFavIcon() {
    this.tag('ChannelFavourite').patch({
      texture: lng.Tools.getSvgTexture(Utils.asset('/icons/favorite.SVG'), 30, 30),
    });
  }

  _delFavIcon() {
    this.tag('ChannelFavourite').patch({
      texture: undefined,
    });
  }

  _enable() {
    this.patch({ w: this.w, h: this.h });
    this._Button.patch({ w: this.w - 50, h: this.h });

    this._ChannelNumber.patch({
      text: { text: this._index, fontSize: 30 },
    });

    if (this._isLocked) {
      this._setLockIcon();
    }

    if (this._isFavourite) {
      this._setFavIcon();
    }

    /* this._ChannelImage.patch({
      texture: {
        type: lng.textures.ImageTexture,
        src: this._buttonInfo.image_url,
        resizeMode: { type: 'contain', w: 100, h: 80 },
      },
    }); */

    if (this._buttonInfo.image_url !== 'test') {
      this._ChannelImage.patch({
        texture: {
          type: lng.textures.ImageTexture,
          src: this._buttonInfo.image_url,
          resizeMode: { type: 'contain', w: 100, h: 80 },
        },
      });
    } else {
      this._ChannelImage.patch({
        texture: Lightning.Tools.getSvgTexture(Utils.asset(`icons/tv_default_icon.SVG`), 60, 60),
      });
      /* this._ChannelImage.patch({
        texture: {
          type: lng.textures.ImageTexture,
          src: Utils.asset('icons/tv_default_icon.png'),
          resizeMode: { type: 'contain', w: 100, h: 80 },
        },
      }); */
    }

    this._ChannelName.patch({
      title: { text: this._buttonInfo?.title, fontSize: 30, maxLines: 1 },
    });

    this._changeNumber();
  }

  _handleEnter() {
    this.fireAncestors('$preventExitStatus');
    if (this._selected) {
      this.fireAncestors('$fullScreen');
      this.fireAncestors('$checkSearchVisible');
    } else {
      this._play();
      this.fireAncestors('$checkFavourite');
    }
  }

  _play() {
    this.fireAncestors('$playNewChannel', { index: this._index, flag: this._buttonInfo });
  }

  _focus() {
    this.fireAncestors('$unselectFocusChannel', focusedItem);
    this._Button.patch({
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        5,
        3,
        Colors('#C40000').get(),
        true,
        Colors('#C40000').get()
      ),
    });
    // Setting a timeout after one second so the scroll animation doesn't fire if the user is just scrolling past channels
    this._marqueeTimeout = setTimeout(() => {
      this._ChannelName.patch({
        autoStart: true,
        repeat: -1,
      });
    }, 1200);
  }

  _handleHover() {
    // console.log('ChannelButtonHovered');
    this.fireAncestors('$setFocus', this._index);
    focusedItem = this._index;
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._handleEnter();
  }

  _unfocus() {
    if (this._selected) {
      this._Button.patch({
        texture: lng.Tools.getRoundRect(this.w, this.h, 5, 3, Colors('#C40000').get(), false),
      });
    } else {
      this._Button.patch({
        texture: null,
      });
    }
    clearTimeout(this._marqueeTimeout);
    this._ChannelName.patch({
      autoStart: false,
      repeat: 0,
    });
  }
}
