import { Colors, Router, Utils } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';
import { SmallPlayer } from '../PlayerSection';

export default class TVChannelInfoButton extends Lightning.Component {
  static _template() {
    return {
      collision: true,
      flex: { direction: 'row', alignItems: 'center' },
      Icon: {},
      Label: {
        text: {
          fontSize: 25,
        },
      },
    };
  }

  _active() {
    this.patch({
      w: this.getWidth,
      Icon: {
        flexItem: { marginRight: 10, marginLeft: 10 },
        texture: Lightning.Tools.getSvgTexture(Utils.asset(`icons/${this.icon}`), 35, 35),
      },
      Label: {
        text: {
          text: this.label,
        },
      },
    });
  }

  _changeIcon(flag) {
    if (!flag) {
      this.icon = 'redflix_infobar_favorite.svg';
      this.name = 'favourite';
      this.label = 'Favourite';
      this.tag('Icon').patch({
        texture: Lightning.Tools.getSvgTexture(Utils.asset(`icons/${this.icon}`), 35, 35),
      });
    } else {
      this.icon = 'favorite.SVG';
      this.name = 'liked';
      this.label = 'Favourite';
      this.tag('Icon').patch({
        texture: Lightning.Tools.getSvgTexture(Utils.asset(`icons/${this.icon}`), 35, 35),
      });
    }
  }
  _focus() {
    this.patch({
      // texture: lng.Tools.getRoundRect(this.finalW, this.finalH, 5, 5, Colors('#C40000').get(), false),
      texture: Lightning.Tools.getRoundRect(
        240,
        70,
        12,
        3,
        Colors('#C40000').get(),
        true,
        Colors('#C40000').get()
      ),
    });
  }

  _unfocus() {
    this.patch({
      texture: undefined,
    });
  }

  _handleEnter() {
    switch (this.name) {
      case 'channelList':
        this.fireAncestors('$hideInfoModal');
        this.fireAncestors('$showChannels', SmallPlayer);
        this.fireAncestors('$showMenu');
        this.fireAncestors('$setToChannelList');
        this.fireAncestors('$checkFavourite');
        this.fireAncestors('$showOriginalChannels');

        // Router.focusWidget('sidebar');
        break;
      case 'favourite':
        this.fireAncestors('$like');
        break;
      case 'liked':
        this.fireAncestors('$unlike');
        break;
      case 'channelQuality':
        this.fireAncestors('$showQuality');
        this.fireAncestors('$hideInfoModal');

        break;
      case 'info':
        this.fireAncestors('$showInfo');
        break;
      case 'lock':
        const data = this.fireAncestors('$getData');
        this.fireAncestors('$hideInfoModal');
        this.fireAncestors('$showLockModal', { index: data, flag: 'lockChannel' });
        break;
      case 'locked':
        this.fireAncestors('$hideInfoModal');

        const dataa = this.fireAncestors('$getData');
        this.fireAncestors('$showLockModal', { index: dataa, flag: 'unlockChannel' });
        break;
      case 'lastChannels':
        this.fireAncestors('$hideInfoModal');

        if (localStorage.getItem('lastChannels')) this.fireAncestors('$showLastChannelsModal');
    }

    // go back to default view & focus widget
  }

  _handleHover() {
    this.fireAncestors('$setFocus', this.index);
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._handleEnter();
  }
}
