import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';

export default class Button extends lng.Component {
  static _template() {
    return {
      Button: {
        collision: true,
        flex: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
        color: Colors('#424242').get(),
        Text: {
          y: 3,
          text: { text: 'Connect' },
        },
      },
    };
  }

  get _Button() {
    return this.tag('Button');
  }

  _enable() {
    this._Button.patch({
      w: this.w,
      h: this.h,
      texture: lng.Tools.getRoundRect(this.w, this.h, 10, 3, false),
    });
  }

  _handleEnter() {
    this.fireAncestors('$checkCode');
  }

  _focus() {
    this._Button.patch({
      color: Colors('#C40000').get(),
    });
  }

  _unfocus() {
    this._Button.patch({
      color: Colors('#424242').get(),
    });
  }

  _handleHover() {
    this._focus();
    this.fireAncestors('$setFocus', 'Button');
  }

  _handleClick() {
    this._handleEnter();
  }

  _handleUnhover() {
    this._unfocus();
  }
}
