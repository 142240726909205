import lng from '@lightningjs/core';
import { Colors, Utils } from '@lightningjs/sdk';

export default class Button extends lng.Component {
  static _template() {
    return {
      h: 100,
      collision: true,
      flex: { direction: 'column', alignItems: 'center', justifyContent: 'center' },
      Button: {
        flex: { direction: 'row', alignItems: 'center', justifyContent: 'center' },
        texture: lng.Tools.getRoundRect(
          75,
          75,
          37,
          3,
          Colors('#272729').get(),
          true,
          Colors('#272729').get()
        ),
        Icon: {},
      },
    };
  }

  set buttonProps(val) {
    return (this._buttonProps = val);
  }

  get Button() {
    return this.tag('Button');
  }

  get Icon() {
    return this.tag('Button.Icon');
  }

  _enable() {
    this.Icon.patch({
      texture: lng.Tools.getSvgTexture(
        Utils.asset(this._buttonProps?.icon),
        this._buttonProps.width ? this._buttonProps.width : 70,
        this._buttonProps.height ? this._buttonProps.height : 70
      ),
      color: Colors('#ffffff').get(),
    });
  }

  _focus() {
    this.Button.patch({
      flex: { alignItems: 'center' },
      texture: lng.Tools.getRoundRect(
        70,
        70,
        35,
        3,
        Colors('#C40000').get(),
        true,
        Colors('#C40000').get()
      ),
    });
  }

  _unfocus() {
    this.Button.patch({
      texture: lng.Tools.getRoundRect(
        70,
        70,
        35,
        3,
        Colors('#272729').get(),
        true,
        Colors('#272729').get()
      ),
    });
  }

  _handleHover() {
    this.fireAncestors('$setState', 'DeleteButton');
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._handleEnter();
  }

  _handleEnter() {
    this.fireAncestors('$deleteLastChannels');
  }
}
