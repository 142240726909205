import request from '../index';

export const getActivation = async (code, sn, mac) =>
  request({
    url: `/start/activation/${code}`,
    method: 'GET',
  });

export const getAccess = (sn, mac) =>
  request({
    url: `/start/access`,
  });

export const getTime = async () =>
  await fetch('https://api.xcloudtv.com/time').then(async (res) => await res.json());
