import lng from '@lightningjs/core';
import { Colors, Utils } from '@lightningjs/sdk';

export default class IconButton extends lng.Component {
  static _template() {
    return {
      h: 110,
      flex: { direction: 'column', alignItems: 'center', justifyContent: 'center' },
      Button: {
        collision: true,
        flex: { direction: 'row', alignItems: 'center', justifyContent: 'center' },
        texture: lng.Tools.getRoundRect(
          75,
          75,
          37,
          3,
          Colors('#424242').get(),
          true,
          Colors('#424242').get()
        ),
        Icon: {},
      },
      Text: { y: 10 },
    };
  }

  set buttonProps(val) {
    return (this._buttonProps = val);
  }

  set index(val) {
    this._index = val;
  }

  get Button() {
    return this.tag('Button');
  }

  get Icon() {
    return this.tag('Button.Icon');
  }

  get Text() {
    return this.tag('Text');
  }

  _enable() {
    this.Icon.patch({
      texture: lng.Tools.getSvgTexture(
        Utils.asset(this._buttonProps?.icon),
        this._buttonProps.width ? this._buttonProps.width : 70,
        this._buttonProps.height ? this._buttonProps.height : 70
      ),
      color: Colors('#ffffff').get(),
    });
    this.Text.patch({
      text: { text: this._buttonProps?.name, fontSize: 20, clipping: true },
    });
  }

  _focus() {
    this.Button.patch({
      flex: { alignItems: 'center' },
      texture: lng.Tools.getRoundRect(
        75,
        75,
        37,
        3,
        Colors('#C40000').get(),
        true,
        Colors('#C40000').get()
      ),
    });
  }

  _unfocus() {
    this.Button.patch({
      texture: lng.Tools.getRoundRect(
        75,
        75,
        37,
        3,
        Colors('#424242').get(),
        true,
        Colors('#424242').get()
      ),
    });
  }

  _handleHover() {
    this.fireAncestors('$setFocus', this._index);
    this._focus();
  }

  _handleUnhover() {
    this._unfocus();
  }

  _handleClick() {
    this._handleEnter();
  }

  _handleEnter() {
    const { onEnter, onEnterProps } = this._buttonProps;
    if (onEnter) this.fireAncestors(onEnter, onEnterProps);
  }
}
