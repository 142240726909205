import lng from '@lightningjs/core';
import { Utils } from '@lightningjs/sdk';

export default class CurrentTvInfo extends lng.Component {
  static _template() {
    return {
      y: 10,
      h: 120,
      flex: { direction: 'row', alignItems: 'center', justifyContent: 'space-between' },
      ChannelInfo: {
        flex: { direction: 'row', alignItems: 'center' },
        h: 100,
        ChannelNumber: { w: 100, y: 5 },
        ChannelImage: { flexItem: { marginLeft: 0, marginRight: 20 } },
        ChannelName: { y: 5 },
      },
      /* FavouriteButton: {
        type: FavouriteButton,
      }, */
    };
  }

  _enable() {
    // this._FavouriteButton.patch({});
  }

  set data(val) {
    return (this._data = val);
  }

  set index(val) {
    return (this._index = val);
  }

  _getIndex() {
    return this._index;
  }

  get _ChannelNumber() {
    return this.tag('ChannelNumber');
  }

  get _ChannelImage() {
    return this.tag('ChannelImage');
  }

  get _ChannelName() {
    return this.tag('ChannelName');
  }

  /* get _FavouriteButton() {
    return this.tag('FavouriteButton');
  } */

  _getInfo() {
    const paddedIndex = String(this._index + 1).padStart(4, '0');
    this._ChannelName.patch({
      text: { text: this._data.title },
    });

    this._ChannelNumber.patch({
      text: { text: paddedIndex },
    });

    if (this._data.image_url !== 'test') {
      this._ChannelImage.patch({
        w: 100,
        // zIndex: 100,
        texture: {
          type: lng.textures.ImageTexture,
          src: this._data.image_url,
          resizeMode: { type: 'cover', w: 100, h: 60 },
        },
      });
    } else {
      this._ChannelImage.patch({
        w: 60,
        texture: lng.Tools.getSvgTexture(Utils.asset(`icons/tv_default_icon.SVG`), 60, 60),
        resizeMode: { type: 'cover', w: 60, h: 60 },
      });
    }
  }

  /* _getFocused() {
    return this._FavouriteButton;
  } */
}
