import lng from '@lightningjs/core';
import { Colors } from '@lightningjs/sdk';
import EPGInfo from './EPGInfo';

export default class EPG extends lng.Component {
  constructor(props) {
    super(props);
    this._programs;
  }
  static _template() {
    return {
      w: this.w,
      h: this.h,
      // rect: true,
      // color: Colors('#282b30').get(),
      EPGInfo: {
        collision: true,
        type: EPGInfo,
      },
    };
  }

  set data(val) {
    return (this._data = val);
  }

  get _EPGInfo() {
    return this.tag('EPGInfo');
  }

  _enable() {
    this.patch({
      clipping: true,
      texture: lng.Tools.getRoundRect(
        this.w,
        this.h,
        10,
        3,
        Colors('#22252a').alpha(0).get(),
        true,
        Colors('#282b30').alpha(0).get()
      ),
    });
  }

  _resize(x, y, w, h) {
    this._EPGInfo.patch({
      w: w,
      h: 300,
    });
    this._EPGInfo._Info.patch({
      w: 100,
    });
    this._EPGInfo._VerticalLine.patch({});
  }

  /* _getInfo() {
    if (this._data.epg.weekly && this._data.epg.weekly.trim() !== '') {
      const weeklyEPGFilePath = this._data.epg.weekly;

      fetch(weeklyEPGFilePath)
        .then((response) => response.text())
        .then((xmlData) => {
          const parsedXmlDocument = this._parseXML(xmlData);
          const programElements = parsedXmlDocument.querySelectorAll('programme');
          const programs = this._processProgramElements(programElements);

          console.log('programs ', parsedXmlDocument);
          this._EPGInfo.patch({
            data: programs,
            w: this.w,
            h: this.h,
          });
          this._EPGInfo._showEPG();
        })
        .catch((error) => {
          console.error('Error fetching XML:', error);
        });
    } else {
      this._EPGInfo._Date.items = [];
      this._EPGInfo._Info.items = [];
    }
  } */

  /* _getInfo() {
    console.log('_getInfo ', this._data);
    if (this._data.epg.daily && this._data.epg.daily.trim() !== '') {
      console.log('UPAO');
      const dailyEPGFilePath = this._data.epg.weekly;
    }
    if (this._data.epg.weekly && this._data.epg.weekly.trim() !== '') {
      const weeklyEPGFilePath = this._data.epg.weekly;

      fetch(weeklyEPGFilePath)
        .then((response) => response.text())
        .then((xmlData) => {
          const parsedXmlDocument = this._parseXML(xmlData);
          const programElements = parsedXmlDocument.querySelectorAll('programme');
          const programs = this._processProgramElements(programElements);

          console.log('programs ', parsedXmlDocument);
          this._EPGInfo.patch({
            data: programs,
            w: this.w,
            h: this.h,
          });
          this._EPGInfo._showEPG();
        })
        .catch((error) => {
          console.error('Error fetching XML:', error);
        });
    } else {
      this._EPGInfo._Date.items = [];
      this._EPGInfo._Info.items = [];
    }
  } */

  /*  _getInfo() {
    console.log('_getInfo ', this._data);
    if (this._data.epg.daily && this._data.epg.daily.trim() !== '') {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}${currentDate.getDate().toString().padStart(2, '0')}`;

      const dailyEPGFilePath = this._data.epg.daily.replace('{Ymd}', formattedDate);

      fetch(dailyEPGFilePath)
        .then((response) => response.text())
        .then((xmlData) => {
          const parsedXmlDocument = this._parseXML(xmlData);
          const programElements = parsedXmlDocument.querySelectorAll('programme');
          const programs = this._processProgramElements(programElements);

          console.log('programs ', parsedXmlDocument);
          this._EPGInfo.patch({
            data: programs,
            w: this.w,
            h: this.h,
          });
          this._EPGInfo._showEPG();
        })
        .catch((error) => {
          console.error('Error fetching XML:', error);
        });
    } else {
      this._EPGInfo._Date.items = [];
      this._EPGInfo._Info.items = [];
    }
  } */

  //!

  _getEPG() {
    return this._programs;
  }

  _getInfo(data) {
    this.patch({ data });
    this._data = data;
    if (data.epg.daily && data.epg.daily.trim() !== '') {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}${currentDate.getDate().toString().padStart(2, '0')}`;

      const dailyEPGFilePath = data.epg.daily.replace('{Ymd}', formattedDate);

      fetch(dailyEPGFilePath)
        .then((response) => response.text())
        .then((xmlData) => {
          const parsedXmlDocument = this._parseXML(xmlData);
          const programElements = parsedXmlDocument.querySelectorAll('programme');
          const programs = this._processProgramElements(programElements);

          this._EPGInfo.patch({
            // data: programs[0].epg[0].description,
            data: programs[0].epg,
            w: this.w,
            h: this.h,
          });
          this._EPGInfo._showEPG();
          this.fireAncestors('$checkEPG', programs);
        })
        .catch((error) => {
          this._programs = [];
          this.fireAncestors('$checkEPG', []);
          console.error('Error fetching XML:', error);
        });
    } else {
      this.fireAncestors('$checkEPG', []);
      this._programs = [];
      this._EPGInfo._Date.items = [];
      this._EPGInfo._Info.items = [];
    }
  }

  // _processProgramElements(programElements) {
  //   const programs = [];
  //   programElements.forEach((programElement) => {
  //     const startTime = programElement.getAttribute('start');
  //     const endTime = programElement.getAttribute('end');
  //     const title = programElement.querySelector('title').textContent;
  //     const description = programElement.querySelector('desc').textContent;

  //     // Additional data extraction as needed...

  //     const program = {
  //       startTime,
  //       endTime,
  //       title,
  //       description,
  //       // Add additional properties as needed...
  //     };

  //     programs.push(program);
  //   });
  //   return programs;
  // }

  _isSameDay(dateTimeString, targetDate) {
    const programDate = new Date(dateTimeString);
    const formattedProgramDate = `${programDate.getFullYear()}${(programDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${programDate.getDate().toString().padStart(2, '0')}`;
    return formattedProgramDate === targetDate;
  }
  //!

  _parseXML(xmlData) {
    const parser = new DOMParser();
    return parser.parseFromString(xmlData, 'text/xml');
  }

  _processProgramElements(programElements) {
    const programs = [];
    this._EPGInfo._Date.items = [];
    this._EPGInfo._Info.items = [];
    programElements.forEach((programElement) => {
      // const titlee = this._getAttribute(programElement, 'title');
      const startAttr = this._getAttribute(programElement, 'start');
      const stopAttr = this._getAttribute(programElement, 'stop');
      const channelAttr = this._getAttribute(programElement, 'channel');

      const titleElement = programElement.querySelector('title');

      const descElement = programElement.querySelector('desc');
      const iconElement = programElement.querySelector('icon');
      let iconURL = ''; // Default empty string if no icon is found

      if (iconElement) {
        const iconHTML = iconElement.outerHTML;
        const srcMatch = iconHTML.match(/src="([^"]+)"/);
        if (srcMatch && srcMatch[1]) {
          iconURL = srcMatch[1]; // This will contain the extracted URL
        }
      }

      const startTime = startAttr || '';
      const stopTime = stopAttr || '';
      const channel = channelAttr || '';
      const title = titleElement ? titleElement.textContent : '';
      const description = descElement ? descElement.textContent : '';

      this._channel = channel;
      this._title = title;
      this._description = description;
      this._icon = iconURL;

      this._startHours = startTime.substring(8, 10);
      this._startMinutes = startTime.substring(10, 12);
      this._startTime = `${this._startHours}:${this._startMinutes}`;

      this._endHours = stopTime.substring(8, 10);
      this._endMinutes = stopTime.substring(10, 12);
      this._stopTime = `${this._endHours}:${this._endMinutes}`;

      const dateString = startTime.substring(0, 8);
      let programDate = programs.find((program) => program.id === dateString);

      var dateObject = new Date(
        dateString.substring(0, 4),
        parseInt(dateString.substring(4, 6)) - 1,
        dateString.substring(6, 8)
      );

      var months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      var formattedDate = dateObject.getDate() + ' ' + months[dateObject.getMonth()];

      if (!programDate) {
        programs.push({
          id: dateString,
          title: formattedDate,
          epg: [
            {
              description: this._description,
              startTime: this._startTime,
              stopTime: this._stopTime,
              title: this._title,
              icon: this._icon,
            },
          ],
        });
      } else {
        programDate.epg.push({
          description: this._description,
          startTime: this._startTime,
          stopTime: this._stopTime,
          title: this._title,
          icon: this._icon,
        });
      }
    });
    this._programs = programs;
    return programs;
  }

  _getAttribute(element, attributeName) {
    return element.getAttribute(attributeName) || '';
  }

  _handleHover() {
    console.log('Hovered');
    this.fireAncestors('$setSectionState', 'PlayerSectionState');
    this._getFocused();
    return false;
  }

  _handleScroll() {
    console.log(this._EPGInfo._Date.selectedIndex);
    let currentIndex = this._EPGInfo._Date.selectedIndex;
    let nextIndex = currentIndex + 1;
    let previousIndex = currentIndex - 1;

    if (event.deltaY > 0) {
      this._EPGInfo._Date.scrollTo(nextIndex, 50);
    } else if (event.deltaY < 0) {
      console.log('Rotacija prema gore');
      this._EPGInfo._Date.scrollTo(previousIndex, 50);
    } else {
      console.log('Nema vertikalne rotacije');
    }
  }

  _getFocused() {
    return this._EPGInfo;
  }
}
